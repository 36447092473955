import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 1",
    id: "FGF11-U1-P9-E1",
    // audio: 'Audios/1.02.mp3',
    video: "",
    component: T6,
    inputSize: 150,
    isHiddenCheck: true,
    maxLength: 13,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page9/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				<span style='font-size: 24px'>Complete the table with the <i>-ing form,</i> past simple and past 
				participle form of the verbs.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
				
        <div style=" position: relative; padding-top:10px; font-size: 22px; width: max-content">
          <div><img style="width: 20cm" src='img/FriendsPlus/Page9/E1/1.jpg';/></div>

					<div style=" position: absolute; top: 116px; left: 69px">1&ensp; marry</div>
          <div style=" position: absolute; top: 116px; left: 186px">#</div>
					<div style=" position: absolute; top: 116px; left: 351px">#</div>
					<div style=" position: absolute; top: 116px; left: 515px">#</div>
          
					<div style=" position: absolute; top: 171px; left: 69px">2&ensp; fight</div>
          <div style=" position: absolute; top: 171px; left: 186px">#</div>
					<div style=" position: absolute; top: 171px; left: 351px">#</div>
					<div style=" position: absolute; top: 171px; left: 515px">#</div>

					<div style=" position: absolute; top: 226px; left: 69px">3&ensp; die</div>
          <div style=" position: absolute; top: 226px; left: 186px">#</div>
					<div style=" position: absolute; top: 226px; left: 351px">#</div>
					<div style=" position: absolute; top: 226px; left: 515px">#</div>

					<div style=" position: absolute; top: 280px; left: 69px">4&ensp; meet</div>
          <div style=" position: absolute; top: 280px; left: 186px">#</div>
					<div style=" position: absolute; top: 280px; left: 351px">#</div>
					<div style=" position: absolute; top: 280px; left: 515px">#</div>

					<div style=" position: absolute; top: 333px; left: 69px">5&ensp; retire</div>
          <div style=" position: absolute; top: 333px; left: 186px">#</div>
					<div style=" position: absolute; top: 333px; left: 351px">#</div>
					<div style=" position: absolute; top: 333px; left: 515px">#</div>

					<div style=" position: absolute; top: 388px; left: 69px">6&ensp; think</div>
          <div style=" position: absolute; top: 388px; left: 186px">#</div>
					<div style=" position: absolute; top: 388px; left: 351px">#</div>
					<div style=" position: absolute; top: 388px; left: 515px">#</div>

					<div style=" position: absolute; top: 442px; left: 69px">7&ensp; stop</div>
          <div style=" position: absolute; top: 442px; left: 186px">#</div>
					<div style=" position: absolute; top: 442px; left: 351px">#</div>
					<div style=" position: absolute; top: 442px; left: 515px">#</div>

        </div>

				`,
        answer: [
          `marrying`,
          `married`,
          `married`,
          `fighting`,
          `fought`,
          `fought`,
          `dying`,
          `died`,
          `died`,
          `meeting`,
          `met`,
          `met`,
          `retiring`,
          `retired`,
          `retired`,
          `thinking`,
          `thought`,
          `thought`,
          `stopping`,
          `stopped`,
          `stopped`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "FGF11-U1-P9-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page9/Key/E2answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `
        Circle the correct answers.
				`,
        color: "black",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          // borderColor: "transparent",
          fontWeight: 700,
          color: "black",
        },
        // initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: {
          padding: 5,
          border: "2px solid black",
          borderRadius: "50%",
          // borderColor: "",
        },
        limitSelect: 1,
        listWords: [
          `bought / were_buying`, //0
          `had_got_engaged / was_getting_engaged`, // 1
          `was_emigrating / emigrated`, // 2
          `Did_Pam_phone / Had_Pam_phoned`, // 3
          `watched / were_watching `, // 4
          `stepped / had_stepped`, // 5
          `rained / was_raining`, //6
          `weren’t_phoning / didn’t_phone`, //7
          `had / ’d_had`, //8
        ],
        answers: [
          "0-0",
          "1-0",
          "2-4",
          "3-0",
          "4-4",
          "5-0",
          "6-4",
          "7-4",
          "8-4",
        ],

        initialValue: [],
      },
      Layout: `
      
					<div style="  padding-top: 10px">
						
            <div style='margin-bottom:20px'><b>1&ensp;</b>My parents <input id='0' type='Circle' /> a house just after they got married.</div>
            <div style='margin-bottom:20px'><b>2&ensp;</b>John <input id='1' type='Circle' /> before he left university.</div>
            <div style='margin-bottom:20px'><b>3&ensp;</b>Liam inherited a lot of money and <input id='2' type='Circle' /> to Australia.</div>
            <div style='margin-bottom:20px'><b>4&ensp;</b><input id='3' type='Circle' /> while you <input id='4' type='Circle' /> television?</div>
            <div style='margin-bottom:20px'><b>5&ensp;</b>I opened the door and <input id='5' type='Circle' /> outside.<br> 
						It <input id='6' type='Circle' />.</div>
						<div style='margin-bottom:20px'><b>6&ensp;</b>I didn’t know where you were because you 
						<input id='7' type='Circle' />.</div>
						<div style='margin-bottom:20px'><b>7&ensp;</b>I <input id='8' type='Circle' /> this watch for two years when the battery 
						ran out.</div>
					</div>
          
        `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "FGF11-U1-P9-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page9/Key/E3answerKey.png",
   
    
    component: Circle_Write,
    textAlign: "left",
    // totalInput: 2,
    titleQuestion: [
      {
        num: "3",
        title:
          '<span style="font-size: 23px"> Some of the verb forms and tenses in the sentences are incorrect. Rewrite them correctly. Tick the correct sentences.</span>',
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 26,
      // textTransform: 'uppercase',
    },

    selectStyle: {
      height: 35,
      width: 35,
      textAlign: "center",
    },
    // selectOptionRandom: true,
    selectOptionValues: ["✓", " "],
    questionImage: [],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: {
          width: 700,
          fontSize: 25,
          borderBottom: "none",
          textAlign: "center",
        },
        answers: ["Why did you get angry with Mary?"," ","I couldn't go out until I had done my homework.","It wasn't raining when we left the house."," ",],
        initialValue: [""," ","",""," ",],
      },
      Circle: {
        initialWordStyle: { },
        selectWordStyle: {
          // padding: 5,
          color: "black",
        },
        limitSelect: 3,
        listWords: [`✓ <br><br> ✓ <br><br> ✓ <br><br> ✓ <br><br> ✓`],
        answers: ['0-4', '0-16'],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative '>
                  <div style='margin-left: 54px'>
                    <b>1</b>&ensp; Why did you be angry with Mary? <br> <input id='0' /><br>

                    <b>2</b>&ensp; Had you eaten before you went out? <br> <input id='1' /><br>

                    <b>3</b>&ensp; I couldn’t go out until I had did my homework. <br> <input id='2' /><br>

                    <b>4</b>&ensp; It wasn’t rain when we left the house. <br> <input id='3' /><br>

                    <b>5</b>&ensp; I wasn’t feeling well this morning. <br> <input id='4' /><br>
                  </div>
                  <div style='position: absolute;top:0px;left:0px;'>
                    <span style='padding:4px 20px;border: 2px solid black; border-radius:10px;'></span><br><br>
                    <span style='padding:4px 20px;border: 2px solid black; border-radius:10px;'></span><br><br>
                    <span style='padding:4px 20px;border: 2px solid black; border-radius:10px;'></span><br><br>
                    <span style='padding:4px 20px;border: 2px solid black; border-radius:10px;'></span><br><br>
                    <span style='padding:4px 20px;border: 2px solid black; border-radius:10px;'></span>
                  </div>
                <div style='position:absolute; top:0px;left:15px;color: white '>
                   <input id='0' type='Circle'>
          
                </div>
        </div>
       
             
       
        
       
           
        
      `,
    },
  },
  4: {
    unit: "Unit 1",
    id: "FGF11-U1-P9-E4",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 60,
    textAlign: "left",
    exerciseKey: "img/FriendsPlus/Page9/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `<span style=''>Write the negative and interrogative form of the sentences.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      width: 650,
      // textTransform: 'uppercase',
    },
    questions: [
      {
        title: `
        <div style="display: flex; line-height: 40px">
          <div style="margin-right: 20px">
            <b>1&ensp;</b><br><br><br>
            <b>2&ensp;</b><br><br><br>
            <b>3&ensp;</b><br><br><br>
						<b>4&ensp;</b><br><br>
          </div>
          <div>
            <div>Jason had been to Italy.</div>
							<div style='color: rgb(152,198,204)'>Jason hadn’t been to Italy.</div>
							<div style='color: rgb(152,198,204)'>Had Jason been to Italy?</div>
						<div>Tom grew up in London.</div>
            	<div>#</div>
							<div>#</div>
						<div>Her parents split up last year.</div>
            	<div>#</div>
							<div>#</div>
						<div>Harry was living in Scotland.</div>
            	<div>#</div>
							<div>#</div>
            </div>
        </div>

				`,
        answer: [
          `Tom didn't grow up in London.`,
          `Did Tom grow up in London?`,
          `Her parents didn't split up last year.`,
          `Did her parents split up last year?`,
          `Harry wasn't living in Scotland.`,
          `Was Harry living in Scotland?`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "FGF11-U1-P9-E5",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    maxLength: 20,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page9/Key/E5answerKey.png",
    titleQuestion: [
      {
        num: "5",
        title: `<span style='font-size: 24px'>Complete the sentences with the verbs below. Use the past 
				simple, past continuous or past perfect.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      width: 230,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: ["go out", "leave", "not listen", "snow"],
        borderColor: "none",
        width: 500,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <b><hintbox id=0></hintbox></b>
        <div style="display: flex; margin-top: 30px; line-height: 50px">
          <div style="margin-right: 20px">
            <b>1&ensp;</b><br>
            <b>2&ensp;</b><br>
            <b>3&ensp;</b><br>
						<b>4&ensp;</b><br>
          </div>
          <div>
            <div>I couldn’t pay for the pizzas because I # my money at home.</div>
						<div>I put on my coat and  #.</div>
						<div>You didn’t understand the question because you #.</div>
						<div>When we woke up, everything was white because it # during the night.</div>
          </div>
        </div>

				`,
        answer: [`had left`, `went out`, `weren't listening`, `had snowed`],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "FGF11-U1-P9-E6",
    audio: "",
    video: "",
    component: T6,
    inputSize: 130,
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 13,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page9/Key/E6answerKey.png",
    titleQuestion: [
      {
        num: "6",
        title: `
				<span style='font-size: 24px'>Complete the text with the verbs in brackets. Use the past 
				simple, past continuous or past perfect.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 20,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <div style="position: relative">
				<img style="width:17cm; height:13.5cm" src='img/FriendsPlus/Page9/E6/1.jpg' />
				
				<div style='position: absolute; top:72px; left: 82px; width: 450px; font-size:20px'>
					When Sarah Knauss <sup>1 </sup># (die) on 
					30 December 1999, she <sup>2 </sup># (live) in 
					Pennsylvania, USA, where she <sup>3 </sup>#
					(spend) all her life. 
					At the time of her death, only one person before 
					her<br> <sup>4 </sup># (live)<br>
					longer. Sarah<br> 
					<sup>5 </sup># (have) <br>
					one daughter, who<br> 
					<sup>6 </sup># still<br> 
					# (live) <br>
					when Sarah died. 

				</div>
        </div>
				`,
        answer: [
          `died`,
          `was living`,
          `had spent`,
          `had lived`,
          `had`,
          `was`,
          `living`,
        ],
      },
    ],
  },
};

export default json;
