import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 1",
    id: "FGF11-U1-P14-E1",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    maxLength: 30,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page14/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `<span style='font-size: 25px'>Complete the second sentence so that it has a similar 
        meaning to the first. Use the words in brackets and no more 
        than three other words.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      width: 300,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <div style="display: flex; margin-top: 30px; line-height: 40px">
          <div style="margin-right: 20px">
            <b>1&ensp;</b><br><br>
            <b>2&ensp;</b><br><br>
            <b>3&ensp;</b><br><br>
						<b>4&ensp;</b><br><br>
          </div>
          <div>
            <div>It would be a good idea to phone home when you arrive. (should)</div>
						<div>You # home when you arrive.</div>
						<div>I recommend that you take a gift for the family. (ought)</div>
						<div>You # a gift for the family.</div>
						<div>It wouldn't be a good idea to take too much money with you. (should)</div>
						<div>I don’t think # too much money with you.</div>
						<div>In my opinion, phoning your parents every day would be a bad idea. (ought)</div>
						<div>I don’t think # phone your parents every day.</div>
						
					</div>
        </div>

				`,
        answer: [
          `should phone`,
          `ought to take`,
          `you should take`,
          `you ought to `,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "FGF11-U1-P14-E2",
    audio: "Audios/1.06 audio.mp3",
    video: "",
    component: T6,
    inputSize: 40,
    isHiddenCheck: true,
    maxLength: 1,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page14/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
				<span style='font-size: 25px'><headphone name='1.06' src='Audios/1.06 tieu de.mp3'></headphone> Listen to a student and an examiner doing the task 
        below. Which of the four topics do they spend most time on? 
        Which do they not discuss?</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
				
        <div style=" position: relative; padding-top:10px; font-size: 22px; margin-left: 150px">
          <div><img style="width: 17cm" src='img/FriendsPlus/Page14/E2/1.jpg';/></div>

					<div style=" position: absolute; top: 308px; left: 196px">#</div>
          <div style=" position: absolute; top: 308px; left: 465px">#</div>
					
					

        </div>

				`,
        answer: [`4`, `3`],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "FGF11-U1-P14-E3",
    // audio: 'Audios/1.06.mp3'
    video: "",
    component: T6,
    inputSize: 40,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 200,
    textAlign: "center",
    // exerciseKey: 'img/FriendsPlus/Page14/Key/E2answerKey.png',
    titleQuestion: [
      {
        num: "3",
        title: `
				<span style='font-size: 25px'>Think of one or two ideas for the topic not discussed in 
        exercise 2.</span>
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 23,
      height: 180,
      padding:5,
    },
    questions: [
      {
        title: `
				
        <div style=" padding-top:10px; font-size: 23px; margin-left: 0px">
          <div><img style="width: 17cm" src='img/FriendsPlus/Page14/E3/1.jpg';/></div>
          <textarea id='0' rows='3'>
        </div>

				`,
        answer: [``],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "FGF11-U1-P14-E4",
    audio: "Audios/1.06 audio.mp3",
    video: "",
    component: T6,
    isHiddenCheck: true,
    maxLength: 5,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page14/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `<span style='font-size: 25px'><headphone name='1.06' src='Audios/1.06 tieu de.mp3'></headphone> Complete the sentences with the correct form of the 
        verbs below. Then listen again and check.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      width: 120,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: ["do", "do", "make", "miss", "share", "take"],
        borderColor: "none",
        width: 500,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style="display: flex; margin-top: 30px; line-height: 50px">
          <div style="margin-right: 20px">
            <b>1&ensp;</b><br>
            <b>2&ensp;</b><br>
            <b>3&ensp;</b><br>
						<b>4&ensp;</b><br>
						<b>5&ensp;</b><br>
						<b>6&ensp;</b><br>
          </div>
          <div>
            <div>Did he # your room?</div>
						<div>We need to # the room nice for her.</div>
						<div>It # him a few days to feel comfortable there, though.</div>
						<div>Did he have to # the homework?</div>
						<div>He actually # well in some subjects.</div>
						<div>Will she # her friends and family?</div>
					
					</div>
        </div>

				`,
        answer: [`share`, `make`, `took`, `do`, `did`, `miss`],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "FGF11-U1-P14-E5",
    // audio: 'Audios/1.06.mp3',
    video: "",
    component: T6,
    inputSize: 40,
    isHiddenCheck: true,
    maxLength: 1,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page14/Key/E5answerKey.png",
    titleQuestion: [
      {
        num: "5",
        title: `
				<span style='font-size: 25px'>Read the <span style='color: rgb(7,142,65)'>Speaking Strategy</span>. Read the task and answer the 
        questions below. </span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 25,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
				
        <div style=" position: relative; padding-top:10px; font-size: 22px; ">
          <div><img style="width: 27cm" src='img/FriendsPlus/Page14/E5/1.jpg';/></div>

					<div style=" position: absolute; top: 266px; left: 848px">#</div>
          <div style=" position: absolute; top: 300px; left: 848px">#</div>
					<div style=" position: absolute; top: 333px; left: 848px">#</div>
          <div style=" position: absolute; top: 367px; left: 848px">#</div>
        </div>

				`,
        answer: [`3`, `4`, `2`, `1`],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "FGF11-U1-P14-E6",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 100,
    textAlign: "left",
    // exerciseKey: 'img/FriendsPlus/Page14/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: "6",
        title: `<span style=''>Now try to think of at least one more idea for each topic in 
        the task.</span>
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 80,
      padding:5,
    },
    questions: [
      {
        title: `
        <div style="display: flex; font-size: 22px">
          <div style='font-size: 22px'>
            <div><b>1 </b>How should you prepare for the visit?</div>
              <div><textarea id='0' rows='3'></div>
              
						<div><b>2 </b>What communication problems might you have and how could you solve them?</div>
              <div><textarea id='1' rows='3'></div>
						<div><b>3 </b>What kinds of entertainment could you offer at home? 
            Where could you go out?</div>
              <div><textarea id='2' rows='3'></div>
						<div><b>4 </b>What are the best ways to keep in touch with somebody in 
            another country? </div>
              <div><textarea id='3' rows='3'></div>
            </div>
        </div>

				`,
        answer: [``],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "FGF11-U1-P14-E7",
    audio: "",
    video: "",
    component: T6,
    recorder: true,
    isHiddenCheck: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "7",
        title: `<span style=''>Now do the speaking task from exercise 5. Use your notes 
        from exercise 6.</span>
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 80,
    },
    questions: [
      {
        title: `
        <img style="width:14cm; height:11.5cm; margin-left: 150px" src='img/FriendsPlus/Page14/E7/1.jpg' />

				`,
        answer: [``],
      },
    ],
  },
};

export default json;
