import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  // 5: {
  //   unit: 'Cumulative Review 4',
  //   id: 'FGF11-WB-demo-2023-CR-P79-E5',
  //   audio: '',
  //   video: '',
  //   // recorder: true,
  //   exerciseKey: 'img/FriendsPlus/Page79/E5/key/E5answerKey.png',
  //   component: T6v2,
  //   // maxLength: 1,
  //   fontSize: "22px",
  //   inputSize: 130,
  //   stylesTextInput: {
  //     background: 'transparent',
  //     // border: 'none',
  //     fontSize: '22px',
  //     fontWeight: '700',
  //     textAlign: 'center',
  //     width: '80px'
  //   },
  //   titleQuestion: [
  //     {
  //       num: '5',
  //       title: `
  //       Choose the correct answers.
  // 			`,
  //       color: '#262324',
  //     },
  //   ],
  //   questionImage: [],
  //   isHiddenCheck: true,
  //   questions: [
  //     {
  //       title: `
  // 	<div style="margin-top: 30px; width: 800px; background-color: rgb(202,201,202)">
  //    <div style="padding: 40px">
  //    <h1 style="font-size: 40px; margin-left: 105px">Bibliotherapy: a novel idea</h1>
  //    <span>
  //    We often think of novels as places in
  //    </span>
  //    <sup>1</sup>
  //    <input id="0">
  //    <span>
  //    to lose
  //    ourselves, but the truth is that reading can have a
  //    much more far-reaching effect. If you find
  //    </span>
  //    <sup>2</sup>
  //    <input id="1">
  //    <span>
  //    in trouble, there's always a book to remind
  //    you that others have been there before. It's just a
  //    question of finding the right book. And that is where
  //    bibliotherapy comes in: the use of books to help
  //    people deal with their problems, whatever they
  //    </span>
  //    <sup>3</sup>
  //    <input id="2">
  //    <span>
  //    be. The treatment has become more
  //    </span>
  //    <sup>4</sup>
  //    <input id="3">
  //    <span>
  //    more common in recent years, and today it is practised
  //    </span>
  //    <sup>5</sup>
  //    <input id="4">
  //    <span>
  //    psychologists - and librarians - all over the
  //    world. There is also scientific evidence in favour of
  //    the technique. Researchers at Sussex University in the
  //    UK have found that reading is a more effective way
  //    of relieving stress than listening to music, going for
  //    a walk or sitting down
  //    </span>
  //    <sup>6</sup>
  //    <input id="5">
  //    <span>
  //    with a cup of tea. After
  //    just six minutes with a book - any book - the people
  //    in the study found their stress levels
  //    </span></br>
  //    <sup>7</sup>
  //    <input id="6">
  //    <span>
  //    reduced by
  //    up to 68%. This suggests that the therapy could help
  //    people
  //    </span>
  //    <sup>8</sup>
  //    <input id="7">
  //    <span>
  //    lives have become meaningless leading
  //    them to fall into depression. If they have the right
  //    book recommended to
  //    </span>
  //    <sup>9</sup>
  //    <input id="8">
  //    <span>
  //    lives have become meaningless leading
  //    them to fall into depression. If they have the right
  //    book recommended to
  //    </span>
  //    <sup>10</sup>
  //    <input id="9">
  //    <span>
  //    no harm. At the very least, a patient will discover some
  //    great new titles to read.
  //    </span>
  //    </div>
  // 	</div>
  //   <div style="width: 800px; display: flex">
  //   <div style="width: 30px; text-align: right">
  //        <div> <span style="font-weight: bold">1</span></div>
  //        <div> <span style="font-weight: bold">2</span> </div>
  //        <div> <span style="font-weight: bold">3</span></div>
  //        <div> <span style="font-weight: bold">4</span></div>
  //        <div> <span style="font-weight: bold">5</span></div>
  //        <div> <span style="font-weight: bold">6</span></div>
  //        <div> <span style="font-weight: bold">7</span></div>
  //        <div> <span style="font-weight: bold">8</span></div>
  //        <div> <span style="font-weight: bold">9</span></div>
  //        <div> <span style="font-weight: bold">10</span></div>
  //      </div>
  //      <div style="width: 250px; margin-left: 15px">
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">where</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">you</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">can</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">and</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">by</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">anywhere</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">are</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">which</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">themselves</span> </div>
  //        <div> <span style="font-weight: bold">a</span> <span style="margin-left: 10px">fdoor</span> </div>
  //      </div>
  //      <div style="width: 250px">
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">which</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">your</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">may</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">in</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">for</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">everywhere</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">been</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">whose</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">they</span> </div>
  //        <div> <span style="font-weight: bold">b</span> <span style="margin-left: 10px">make</span> </div>
  //      </div>
  //      <div style="width: 250px">
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">who</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">yourself</span></span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">will</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">than</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">of</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">somewhere</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">were</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">who</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">them</span> </div>
  //        <div> <span style="font-weight: bold">c</span> <span style="margin-left: 10px">take</span> </div>
  //      </div>
  //    </div>
  // 			`,
  //       answer: [
  //        'b',
  //        'c',
  //        'b',
  //        'a',
  //        'a',
  //        'c',
  //        'c',
  //        'b',
  //        'c',
  //        'a',
  //       ],
  //     },
  //   ],
  // },
  5: {
    unit: "Cumulative Review 4",
    id: "FGF11-WB-demo-2023-CR-P79-E5",
    exerciseKey: "img/FriendsPlus/Page79/E5/key/E5answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "5",
        title: `
        Choose the correct answers.
				`,
        color: "#262324",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70, padding: 20 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
          fontSize: 22,
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 1,
        listWords: [
          `a b c`, //0
          `a b c`,
          `a b c`,
          `a b c`,
          `a b c`, //4

          `a b c`, //5
          `a b c`,
          `a b c`,
          `a b c`,
          `a b c`, //9
        ],
        answers: [
          "0-2",
          "1-4",
          "2-2",
          "3-0",
          "4-0",
          "5-4",
          "6-4",
          "7-2",
          "8-4",
          "9-0",
        ],

        initialValue: [],
      },
      Layout: `

			<div style='position:relative; font-size: 25px; margin-right: 50px;'>
      <img style="width:17cm; height:19cm" src='img/FriendsPlus/Page79/E5/1.jpg' />
					<div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>1&ensp;</b><input id='0' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>2&ensp;</b><input id='1' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>3&ensp;</b><input id='2' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>4&ensp;</b><input id='3' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>5&ensp;</b><input id='4' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm; margin-right: 174px;'>
          <b>6&ensp;</b><input id='5' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>7&ensp;</b><input id='6' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>8&ensp;</b><input id='7' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>9&ensp;</b><input id='8' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>10</b><input id='9' type='Circle'>
					</div>
						
					
						
<div style='position:absolute;top:719px;left:202px; font-size: 25px'>
            where<br>
						you<br>
            can<br>
            and<br>
            by<br>
            anywhere<br>
            are<br>
            which<br>
            themselves<br>
            do<br>
					</div>
					<div style='position:absolute;top:718px;left:415px; font-size: 25px'>
					  which<br>
            your<br>
            may<br>
            in<br>
            for<br>
            everywhere<br>
            been<br>
            whose<br>
            they<br>
            make<br>
				</div>
					<div style='position:absolute;top:719px; left: 618px; font-size: 25px'>
            who<br>
            yourself<br>
            will<br>
            than<br>
            of<br>
            somewhere<br>
            were<br>
            who<br>
            them<br>
            take<br>

					</div>
					
			</div>
         `,
    },
  },
  6: {
    unit: "Cumulative Review 4",
    id: "FGF11-WB-demo-2023-CR-P79-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page79/E6/key/E6answerKey.png",
    component: T6v2,
    // maxLength: 1,
    fontSize: "22px",
    inputSize: 130,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      fontWeight: "700",
      textAlign: "center",
      width: "80px",
    },
    textareaStyle: {
      // background: 'none',
      width: 1200,
      fontSize: 22,
      height: 270,
      padding: 0,
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        Your teacher has asked you to write a film review for the
        school magazine. Write your review describing the film and
        say what you liked and did not like about it.
				`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style="margin-top: 0px; width: 1000px">
         <textarea id='0' row='3'>
		    </div>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
