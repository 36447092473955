import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P32-E1",
    // audio: "Audios/1.24.mp3",
    exerciseKey: "img/FriendsPlus/Page32/key/E1answerkey.png",
    component: T6v2,
    // hideBtnFooter: true,
    inputSize: 33,
    stylesTextInput: {
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        Complete the types of home with <i>a, e, i, o</i> and <i>u</i>. 
				`,
        // color: "rgb(46,180,106)",
        color: "rgb(0,0,0)",
      },
    ],
    isHiddenCheck: true,
    maxLength: 1,
    questions: [
      {
        title: `
          <div style='line-height:2'>
          <b>1</b>&ensp;m#ns##n <br>
          <b>2</b>&ensp;f#rmh##s#  <br>
          <b>3</b>&ensp;v#ll# <br>
          <b>4</b>&ensp;m#b#l#  &ensp;&ensp;h#m# <br>
          <b>5</b>&ensp;fl#t <br>
          <b>6</b>&ensp;b#ng#l#w<br>
          <b>7</b>&ensp;h##s#  &ensp;&ensp;b##t<br>
          <b>8</b>&ensp;t#rr#c#d  &ensp;&ensp;h##s#<br>
          <b>9</b>&ensp;s#m#-d#t#ch#d  &ensp;&ensp;h##s# <br>
          <b>10</b>&ensp;th#tch#d  &ensp;&ensp;c#tt#g#  <br>
          <b>11</b>&ensp;d#t#ch#d &ensp;&ensp;h##s#
          <br>
          
          </div>

				`,
        answer: [
          `a`,
          `i`,
          `o`,
          `a`,
          `o`,
          `u`,
          `e`,
          `i`,
          `a`,
          `o`,
          `i`,
          `e`,
          `o`,
          `e`,
          `a`,
          `u`,
          `a`,
          `o`,
          `o`,
          `u`,
          `e`,
          `o`,
          `a`,
          `e`,
          `a`,
          `e`,
          `o`,
          `u`,
          `e`,
          `e`,
          `i`,
          `e`,
          `a`,
          `e`,
          `o`,
          `u`,
          `e`,
          `a`,
          `e`,
          `o`,
          `a`,
          `e`,
          `e`,
          `a`,
          `e`,
          `o`,
          `u`,
          `e`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P32-E2",
    // audio: "Audios/1.24.mp3",
    exerciseKey: "img/FriendsPlus/Page32/key/E2answerkey.png",
    component: T6v2,
    // hideBtnFooter: true,
    inputSize: 150,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Look at the picture and complete the labels.  
				`,
        // color: "rgb(46,180,106)",
        color: "rgb(0,0,0)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
        <div style='flex:1'>
        <b>1</b>&ensp;f<input/><br>
        <b>2</b>&ensp;e<input/><br>
        <b>3</b>&ensp;b<input/><br>
        <b>4</b>&ensp;s<input/><br>
        <b>5</b>&ensp;g<input/><br>
        <b>6</b>&ensp;b<input/><br>
        <b>7</b>&ensp;f<input/>&ensp;b<input/><br>
        <b>8</b>&ensp;p<input/><br>
        <b>9</b>&ensp;c<input/><br>

        </div>
        <div style='flex:1'>
        <b>10</b>&ensp;s<input/>&ensp;d<input/><br>
        <b>11</b>&ensp;p<input/><br>
        <b>12</b>&ensp;l<input/><br>
        <b>13</b>&ensp;p<input/><br>
        <b>14</b>&ensp;p<input/><br>
        <b>15</b>&ensp;d<input/><br>
        <b>16</b>&ensp;g<input/><br>
        <b>17</b>&ensp;h<input/><br>


        </div>
        </div>
        <div style='margin-top:-10px'>
        <img src='img/FriendsPlus/Page32/E2/1.jpg' width='80%'/>

        </div>
				`,
        answer: [
          `ence`,
          `xtension`,
          `alcony`,
          `hutters`,
          `ate`,
          `asement`,
          `lower`,
          `ed`,
          `orch`,
          `onservatory`,
          `liding`,
          `oors`,
          `atio`,
          `awn`,
          `ath`,
          `ond`,
          `rive`,
          `arage`,
          `edge`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P32-E3",
    audio: "Audios/1.16 audio.mp3",
    exerciseKey: "img/FriendsPlus/Page32/key/E3answerkey.png",
    component: T6,
    // hideBtnFooter: true,
    inputSize: 270,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        
        <headphone name='1.16' src='Audios/1.16 tieu de.mp3'></headphone> Listen to someone describing their ideal home. Complete the description with the missing words. 
				`,
        // color: "rgb(46,180,106)",
        color: "rgb(0,0,0)",
      },
    ],
    // hideBtnFooter: true,
    // textareaStyle: { width: 800 },
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:2'>
        My ideal home is in the city. It’s a <sup>1</sup> <input/> flat, in an <sup>2</sup> <input/>, <br>
        modern  building in a <sup>3</sup> <input/>. <br>
        It’s <sup>4</sup> <input/> for shops, restaurants and cinemas. <br> Inside, it isn’t at all <sup>5</sup> <input/> . <br>
         In fact, it’s very  <sup>6</sup> <input/> .
        </div>
      				`,
        answer: [
          "contemporary",
          "impressive",
          "lively area",
          "conveniently located",
          "cramped",
          "spacious",
        ],
      },
    ],
  },
};

export default json;
