import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  4: {
    unit: "Cumulative Review 2",
    id: "FGF11-WB-demo-2023-CR-P75-E4",
    exerciseKey: "img/FriendsPlus/Page75/E4/key/E4answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "4",
        title: `
        Choose the correct answers.
				`,
        color: "#262324",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70, padding: 20 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
          fontSize: 22,
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 1,
        listWords: [
          `a b c`, //0
          `a b c`,
          `a b c`,
          `a b c`,
          `a b c`, //4

          `a b c`, //5
          `a b c`,
          `a b c`,
          `a b c`,
          `a b c`, //9
        ],
        answers: [
          "0-0",
          "1-2",
          "2-0",
          "3-2",
          "4-4",
          "5-4",
          "6-0",
          "7-4",
          "8-2",
          "9-4",
        ],

        initialValue: [],
      },
      Layout: `

			<div style='position:relative; font-size: 25px; margin-right: 50px;'>
      <img style="width:17cm; height:18cm" src='img/FriendsPlus/Page75/E4/1.jpg' />
					<div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>1&ensp;</b><input id='0' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>2&ensp;</b><input id='1' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>3&ensp;</b><input id='2' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>4&ensp;</b><input id='3' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>5&ensp;</b><input id='4' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm; margin-right: 200px;'>
          <b>6&ensp;</b><input id='5' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>7&ensp;</b><input id='6' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>8&ensp;</b><input id='7' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>9&ensp;</b><input id='8' type='Circle'>
					</div>
          <div style='display:flex; justify-content:space-around;height:10.5mm;width: 17cm'>
          <b>10</b><input id='9' type='Circle'>
					</div>
						
					
						
<div style='position:absolute;top:681px;left:202px; font-size: 25px'>
            has started<br>
						got used to<br>
            didn’t think<br>
            cut<br>
            a<br>
            fitted in with<br>
            hopeful<br>
            treat<br>
            share<br>
            find<br>
					</div>
					<div style='position:absolute;top:681px;left:399px; font-size: 25px'>
            have started<br>
            used to<br>
            hadn’t thought<br>
            had cut<br>
            an<br>
            got away with<br>
            hopefully<br>
            treated<br>
            will be sharing<br>
            will be finding<br>
				</div>
					<div style='position:absolute;top:681px; left: 618px; font-size: 25px'>
            had started<br>
            was used to<br>
            wasn’t thinking<br>
            was cutting<br>
            the<br>
            went through with<br>
            hopeless<br>
            will treat<br>
            will have shared<br>
            will have found<br>

					</div>
					
			</div>
         `,
    },
  },
  5: {
    unit: "Cumulative Review 2",
    id: "FGF11-WB-demo-2023-CR-P75-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page75/E5/key/E5answerKey.png",
    component: T6v2,
    hideBtnFooter: true,
    fontSize: "22px",
    inputSize: 130,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      fontWeight: "700",
      textAlign: "center",
      width: "80px",
    },
    textareaStyle: {
      // background: 'none',
      width: 1200,
      fontSize: 22,
      height: 270,
      padding: 0,
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Some people believe that learning how to lead a healthy
        lifestyle is a skill that students should learn at school. Write an
        essay in which you give your own opinion about this issue and
        propose how students should learn this skill.
				`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <textarea id='1' row='3'>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
