import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import DrawColor from '../../components/ExcerciseTypes/DrawColor'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		unit: 'Introduction',
		id: 'FGF11-I-P7-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page7/Key/E1answerKey.png',
		component: DesignUnderLine,
		totalInput: 8,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '1',
				title: `
        Read the dialogue. Tick the correct answers.
			  `,
				color: 'black',
			},
		],
		questionImage: [
			// IMG
			[{ url: 'img/FriendsPlus/Page7/E1/1.jpg' }],
			//Q1
			[
				{ url: 'img/FriendsPlus/Page7/E1/2.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/3.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page7/E1/4.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/5.jpg', input: 1, isCorrect: true },
				{ url: 'img/FriendsPlus/Page7/E1/6.jpg' },
			],
			//Q2
			[
				{ url: 'img/FriendsPlus/Page7/E1/7.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/8.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page7/E1/9.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/10.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page7/E1/11.jpg' },
			],
			//Q3
			[
				{ url: 'img/FriendsPlus/Page7/E1/12.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/13.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page7/E1/14.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/15.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page7/E1/16.jpg' },
			],
			//Q4
			[
				{ url: 'img/FriendsPlus/Page7/E1/17.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/18.jpg', input: 4, isCorrect: true },
				{ url: 'img/FriendsPlus/Page7/E1/19.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/20.jpg', input: 4 },
				{ url: 'img/FriendsPlus/Page7/E1/21.jpg' },
			],
			//Q5
			[
				{ url: 'img/FriendsPlus/Page7/E1/22.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/23.jpg', input: 5 },
				{ url: 'img/FriendsPlus/Page7/E1/24.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/25.jpg', input: 5, isCorrect: true },
				{ url: 'img/FriendsPlus/Page7/E1/26.jpg' },
			],
			//Q6
			[
				{ url: 'img/FriendsPlus/Page7/E1/27.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/28.jpg', input: 6 },
				{ url: 'img/FriendsPlus/Page7/E1/29.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/30.jpg', input: 6, isCorrect: true },
				{ url: 'img/FriendsPlus/Page7/E1/31.jpg' },
			],
			//Q7
			[
				{ url: 'img/FriendsPlus/Page7/E1/32.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/33.jpg', input: 7, isCorrect: true },
				{ url: 'img/FriendsPlus/Page7/E1/34.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/35.jpg', input: 7 },
				{ url: 'img/FriendsPlus/Page7/E1/36.jpg' },
			],
			//Q8
			[
				{ url: 'img/FriendsPlus/Page7/E1/37.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/38.jpg', input: 8, isCorrect: true },
				{ url: 'img/FriendsPlus/Page7/E1/39.jpg' },
				{ url: 'img/FriendsPlus/Page7/E1/40.jpg', input: 8 },
				{ url: 'img/FriendsPlus/Page7/E1/41.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page7/E1/42.jpg' }],
		],
	},
	2: {
		unit: 'Introduction',
		id: 'FGF11-I-P7-E2',
		audio: '',
		video: '',
		component: T6,
		inputSize: 80,
		isHiddenCheck: true,
		checkUppercase: true,
		maxLength: 3,
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page7/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `
				Complete the text with <i>a / an, the,</i> or no article (–).
				`,
				color: 'black',
			},
		],
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 20,
			height: 27,
			// textTransform: 'uppercase',
		},

		questions: [
			{
				title: `
        <div style="position: relative">
				<img style="width:17cm; height:20cm" src='img/FriendsPlus/Page7/E2/1.jpg' />
				<div style='position: absolute; top:25px; left: 97px; width: 509px; font-size:20px'>
				This man is inside <sup>1 </sup><span style='color: gray'>a</span>
				cave. He’s <sup>2 </sup># professional 
				caver, but he’s still feeling nervous. Why? Because 
				<sup>3 </sup># cave is much bigger than it looks. In fact, 
				it’s <sup>4 </sup># deepest cave in the world. It’s called 
				<sup>5 </sup># Krubera Cave, and it’s near <sup>6 </sup># Black Sea. It is 
				<sup>7 </sup># only cave on Earth that is more than 2 km deep. 
				Caves like this are fascinating places for 
				<sup>8 </sup># explorers because there are always new parts 
				to discover. In 2005, <sup>9 </sup># Ukrainian caver called 
				Alexander Klimchouk organised <sup>10 </sup># expedition into 
				the cave. <sup>11 </sup># expedition involved 56 people and went 
				deeper than 2 km. In 2012, a Ukrainian 
				diver called Gennady Samokhin went even deeper by 
				diving down through the muddy water at 
				<sup>12 </sup># bottom
				</div>
        </div>
				`,
				answer: [
					`a`,
					`the`,
					`the`,
					`the`,
					`the`,
					`the`,
					`-`,
					`a`,
					`an`,
					`The`,
					`the`,
				],
			},
		],
	},
	3: {
		unit: 'Introduction',
		id: 'FGF11-I-P7-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page7/Key/E3answerKey.png',
		component: Circle_Write,
		totalInput: 9,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '3',
				title: `
        Circle the ending that is more natural.
			  `,
				color: 'black',
			},
		],
		question: {
			Write: {
			  isHiddenCheck: true,
			  maxLength: 11,
			  inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
			  answers: [''],
			  initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					borderRadius: '50%',
					fontWeight: 'bold',
					borderColor: 'transparent',
					border: '2px solid white',
					padding:'0px 4px',
			
				  },
				  selectWordStyle: {
					padding:'0px 4px',
					border: '2px solid black',
				  },
			  limitSelect: 1,
			  listWords: [
				`a <br> b `, //1
				`a <br> b `, //1
				`a <br> b `, //1
				`a <br> b `, //1
				`a <br> b `, //1
				`a <br> b `, //1
				`a <br> b `, //1
				`a <br> b `, //1
				`a <br> b `, //1
				
	  
			  ],
			  answers: ['0-4', '1-0', '2-4', '3-4', '4-4', '5-0', '6-0', '7-4', '8-4'],
			  initialValue: [],
			},
			Layout: `
			<div style='display:flex; line-height:2'>
			  <div>
				  <b>1</b>&ensp;
				  There’s somebody at the door.’ ‘OK,<br>
				  <div style="display: flex;margin-left:40px">
	  
						<div><input id='0' type='Circle'/></div>
						<div style='margin-left:20px'>
						I’m going to answer it. <br>
						I’ll answer it.’<br>
						  </div>
							 </div>
	  
				  <b>2</b>&ensp;
				  ‘These boxes are too heavy. Look out –
				  <div style="display: flex;margin-left:40px">
						<div><input id='1' type='Circle'/></div>
						<div style='margin-left:20px'>
						I’m going to drop them!’<br>
						I’ll drop them!’<br>
						</div>
				  </div>
	  
					<b>3</b>&ensp;
					‘Would you like something to drink?’ ‘Yes,
				  <div style="display: flex;margin-left:40px">
						<div><input id='2' type='Circle'/></div>
						<div style='margin-left:20px'>
						I’m going to have a glass of water, please. <br>
						I’ll have a glass of water, please.<br>
						</div>
				  </div>

						<b>4</b>&ensp;
					‘Show me your picture. I promise
				   <div style="display: flex;">
				  <div style="display: flex;margin-left:40px">
					  <div><input id='3' type='Circle'/></div>
						<div style='margin-left:20px'>
						I’m not going to laugh.<br>
						I won’t laugh.’<br>
						</div>
				  </div>
				 </div>
			</div>


			<div style ='margin-left:100px'>
			<b>5</b>&ensp;
			‘What are your plans for the afternoon?’ <br>
			<div style="display: flex;margin-left:40px">
				  <div><input id='4' type='Circle'/></div>
				  <div style='margin-left:20px'>
				  ‘I’ll stay in and watch TV.’<br>
				  ‘I’m going to stay in and watch TV.’
					</div>
					   </div>

			<b>6</b>&ensp;
			‘We’re going to the Caribbean this year.
			<div style="display: flex;margin-left:40px">
				  <div><input id='5' type='Circle'/></div>
				  <div style='margin-left:20px'>
				  It will be my first visit.’<br>
				  It’s going to be my first visit.’
				  </div>
			</div>

			  <b>7</b>&ensp;
			  ‘I’ve forgotten my pencil case.’ ‘Don’t worry,
			<div style="display: flex;margin-left:40px">
				  <div><input id='6' type='Circle'/></div>
				  <div style='margin-left:20px'>
				  I’ll lend you a pen.’ <br>
				  I’m going to lend you a pen.’
				  </div>
			</div>
					<b>8</b>&ensp;
					‘That’s a fantastic pass!
			 <div style="display: flex;">
			<div style="display: flex;margin-left:40px">
				<div><input id='7' type='Circle'/></div>
				  <div style='margin-left:20px'>
				  They’ll score a goal!’ <br>
			  	They’re going to score a goal!’
				  </div>
			</div>
		   </div>

		   <b>9</b>&ensp;
		   ‘I’m going to the cinema tonight.’ ‘Really?
	<div style="display: flex;">
   <div style="display: flex;margin-left:40px">
	   <div><input id='8' type='Circle'/></div>
		 <div style='margin-left:20px'>
		 What will you see?’ <br>
		 What are you going to see?’
		 </div>
   </div>
  </div>
	  </div>
	</div>
	  
	  
			  
			`,
		  },
	},
	4: {
		unit: 'Introduction',
		id: 'FGF11-I-P7-E4',
		audio: '',
		video: '',
		component: T6,
		inputSize: 250,
		isHiddenCheck: true,
		checkUppercase: true,
		maxLength: 25,
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page7/Key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `
				Complete the dialogue with the correct form of <i>will</i> or <i>be
				going to</i> and the verbs in brackets.
				`,
				color: 'black',
			},
		],
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 22,
			height: 27,
			// textTransform: 'uppercase',
		},

		questions: [
			{
				title: `
        <div style="display: flex; margin-top: 30px; font-size: 22px">
          <div style='margin-right: 40px'>
            <b>Jack</b><br><br>
            <b>Annie</b><br>

            <b>Jack</b><br>
            <b>Annie</b><br>

            <b>Jack</b><br>
						<b>Annie</b><br>

						<b>Jack</b><br><br>
						<b>Annie</b><br>

						<b>Jack</b><br>
						<b>Annie</b><br>
          </div>
          <div style='font-size: 22px'>
           <div>I <sup>1 </sup># (go) ice skating on 
						Saturday evening with Luke.<br> Would you like to come?</div>
           
						<div>Yes, please. Where <sup>2 </sup>#
					 (you / meet)? At the ice rink?</div>

           <div>No, at the bus stop near my house.</div>

           <div>I live really near to the ice rink, so I <sup>3 </sup>#
					 (see) you there. What time?</div>

						<div>Six o’clock in the evening. I think it 
						<sup>4 </sup># (be) quite busy.</div>

           <div>Yes, definitely. It’s more expensive on Saturday 
					 evenings, isn’t it?</div>
					 
					 <div>Don’t worry. I <sup>5 </sup># (get) a 
					 ticket for you. I’ve got some vouchers,<br> so it 
					 <sup>6 </sup># (not cost) too much.</div>

					<div>Thanks! I <sup>7 </sup># (see) you on 
					Saturday, then.</div>

					<div>At six o’clock.</div>

					<div>I <sup>8 </sup># (not be) late, I promise.</div>
        </div>

				`,
				answer: [
					`'m going to go`,
					`are you going to meet`,
					`'ll see`,
					`'s going to be`,
					`'ll get`,
					`won't cost`,
					`'ll see`,
					`won't be`,
				],
			},
		],
	},
}

export default json
