import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 2",
    id: "FGF11-U2-P23-E1",
    audio: "",
    video: "",
    component: T6,
    inputSize: 250,
    isHiddenCheck: true,
    maxLength: 25,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page23/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				Match six school clubs below with the pictures (1–6) opposite.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 20,
      height: 26,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: [
          "art club",
          "astronomy club",
          "baking club",
          "ballroom-dancing club",
          "computer club",
          "debating society",
          "drama society",
          "film club",
          "fitness club",
          "handball club",
          "photography club",
          "school choir",
          "school orchestra",
          "science club",
        ],
        borderColor: "none",
        width: 900,
        fontColor: "#93338e",
        fontSize: 18,
      },
    ],
    questions: [
      {
        title: `
				<hintbox id=0></hintbox>
        <div style=" position: relative; padding-top:10px; font-size: 19px; ">
          <div><img style="width: 23cm;" src='img/FriendsPlus/Page23/E1/1.jpg';/></div>

          <div style=" position: absolute; top: 156px; left: 60px">#</div>
          <div style=" position: absolute; top: 156px; left: 338px">#</div>
          <div style=" position: absolute; top: 156px; left: 609px">#</div>

          <div style=" position: absolute; top: 332px; left: 60px">#</div>
          <div style=" position: absolute; top: 332px; left: 338px">#</div>
          <div style=" position: absolute; top: 332px; left: 609px">#</div>
          
        </div>

				`,
        answer: [
          `handball club`,
          `film club`,
          `baking club`,
          `computer club`,
          `photography club`,
          `ballroom-dancing club`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FGF11-U2-P23-E2",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 23,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page23/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `<span style='font-size: 25px'>At which of the other school clubs from exercise 1 could you:</span>
				`,
        color: "black",
      },
    ],
    hintBox: [
      {
        src: [
          "art club",
          "astronomy club",
          "baking club",
          "ballroom-dancing club",
          "computer club",
          "debating society",
          "drama society",
          "film club",
          "fitness club",
          "handball club",
          "photography club",
          "school choir",
          "school orchestra",
          "science club",
        ],
        borderColor: "none",
        width: 900,
        fontColor: "#93338e",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      width: 270,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style="display: flex; margin-top: 30px; font-size: 22px; line-height: 40px">
          <div style="margin-right: 20px">
            <b>1&ensp;</b><br>
            <b>2&ensp;</b><br>
            <b>3&ensp;</b><br>
						<b>4&ensp;</b><br>
            <b>5&ensp;</b><br>
            <b>6&ensp;</b><br>
            <b>7&ensp;</b><br>
						<b>8&ensp;</b><br>

          </div>
          <div>
            <div>sing with other students? #</div>
            <div>act in a play? #</div>
            <div>do interesting experiments? #</div>
            <div>draw and paint? #</div>
            <div>discuss important issues? #</div>
            <div>do physical exercises? #</div>
            <div>look at the planets? #</div>
            <div>play a musical instrument? #</div>

            </div>
        </div>

				`,
        answer: [
          `school choir`,
          `drama society`,
          `science club`,
          `art club`,
          `debating society`,
          `fitness club`,
          `astronomy club`,
          `school orchestra`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FGF11-U2-P23-E3",
    audio: "",
    video: "",
    component: T6,
    inputSize: 40,
    isHiddenCheck: true,
    maxLength: 1,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page23/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				Read the task and the model text. Number the four points 
        (a–d) in the order they appear in the text.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 20,
      height: 26,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <div style=" position: relative; ">
          <div><img style="width: 29cm; height: 17cm" src='img/FriendsPlus/Page23/E2/1.jpg';/></div>

          <div style=" position: absolute; top: 123px; left: 615px">#</div>
          <div style=" position: absolute; top: 151px; left: 985px">#</div>
          <div style=" position: absolute; top: 206px; left: 781px">#</div>

          <div style=" position: absolute; top: 233px; left: 844px">#</div>
          
        </div>

				`,
				answer: [`2`, `3`, `1`, `4`],
			},
		],
	},
	4: {
		unit: 'Unit 2',
		id: 'FGF11-U2-P23-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page23/Key/E4answerKey.png',
		component: Circle_Write,
		totalInput: 2,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '4',
				title: `
        Read the <span style='color: rgb(81,128,190)'>Writing Strategy</span>. Which two of the five underlined 
        sentences in the text could you delete?
			  `,
				color: 'black',
			},
		],
		question: {
			Write: {
			  isHiddenCheck: true,
			  maxLength: 11,
			  inputStyle: {
				width: 149,
				fontSize: 23,
				borderBottom: "none",
				textAlign: "center",
			  },
			  answers: [""],
			  initialValue: [],
			},
			Circle: {
			  initialWordStyle: {},
			  selectWordStyle: {
				// padding: 5,
				color: "black",
			  },
			  limitSelect: 5,
			  listWords: [`✓  ✓ ✓ ✓ ✓ `],
			  answers: ['0-10', '0-6'],
			  initialValue: [],
			},
			Layout: `
			
			<div style='position: relative;top:0px;left:20px; display:flex; flex-direction: column;line-height:50px  '>
			
					<div style='display:flex; position: relative'>
					<img  src='img/FriendsPlus/Page23/E4/1.jpg' />
					  <div style='margin-right: 20px;margin-left:20px; margin-top:20px'>
					  	1<sup>st</sup> sentence <br>
						2<sup>nd</sup> sentence <br>
						3<sup>nd</sup> sentence<br>
						4<sup>th</sup> sentence<br>
						5<sup>th</sup> sentence<br>
					  </div>
					  <div style=' margin-top:25px'>
						<div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
						<div style='width:40px;height:40px; margin:10px 0px; border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
						<div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
						<div style='width:40px;height:40px; margin:10px 0px; border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
						<div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
					  </div>
					</div>
					
				   
				</div>
				<div style='position:relative; top:-348px;left:916px;color: white; display:flex; flex-direction: column;line-height:50px  '>
				<input id='0' type='Circle'>
				
			  </div>
			 
			</div>
		  </div>
			 
			  
			 
				 
			  
			`,
		  },
	},
	5: {
		unit: 'Unit 2',
		id: 'FGF11-U2-P23-E5',
		audio: '',
		video: '',
		component: T6,
		isHiddenCheck: true,
		hideBtnFooter: true,
		maxLength: 500,
		textAlign: 'left',
		// exerciseKey: 'img/FriendsPlus/Page14/Key/E6answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: `<span style='font-size: 24px'>Read the task. Then make notes for each point (a–d). 
        Use the questions (1–4) below to give you ideas.</span>
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 21,
      height: 150,
      padding:5,
    },
    questions: [
      {
        title: `
				
        <div style=" font-size: 21px; margin-left: 50px">
				<img style="width:25cm; height:5cm" src='img/FriendsPlus/Page23/E5/1.jpg' /><br>
				
        <b>a Say when and where it took place.</b><br>
        <div><textarea id='0' rows='6'></div>
				<b>b Say who attended the session and what happened.</b><br> 
				<div><textarea id='1' rows='6'></div>
        <b>c Suggest how the club could improve its activities.</b><br>
        <div><textarea id='1' rows='6'></div>
        <b>d Make a request for some equipment</b><br>
        <div><textarea id='1' rows='6'></div>
        </div>

				`,
        answer: [``],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "FGF11-U2-P23-E6",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 700,
    textAlign: "left",
    // exerciseKey: 'img/FriendsPlus/Page14/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: "6",
        title: `<span style='font-size: 25px'>Write a blog post. Use your notes from exercise 5.</span>
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 22,
      height: 450,
      padding:5,
    },
    questions: [
      {
        title: `
				
        <div style=" font-size: 22px; margin-left: 50px">
        <div><textarea id='0' rows='6'></div>
        </div>

				`,
        answer: [``],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "FGF11-U2-P15-E7",
    audio: "",
    video: "",
    component: DesignUnderLine,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 500,
    textAlign: "left",
    // exerciseKey: 'img/FriendsPlus/Page14/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: "",
        title: `
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 23,
      height: 300,
    },
    totalInput: 3,
    // isAllowSubmit: false,
    questionImage: [
      [{ url: "img/FriendsPlus/Page23/E7/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page23/E7/2.jpg" },
        { url: "img/FriendsPlus/Page23/E7/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page23/E7/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page23/E7/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page23/E7/6.jpg" },
        { url: "img/FriendsPlus/Page23/E7/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page23/E7/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page23/E7/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page23/E7/10.jpg" },
        { url: "img/FriendsPlus/Page23/E7/11.jpg", input: 1 },
        { url: "img/FriendsPlus/Page23/E7/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page23/E7/13.jpg" }],
    ],
  },
};

export default json;
