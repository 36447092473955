import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Cumulative Review 3",
    id: "FGF11-WB-demo-2023-CR-P76-E1",
    audio: "Audios/2.11 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page76/E1/key/E1answerKey.png",
    component: Circle_Write,
    // maxLength: 1,
    inputSize: 950,
    fontSize: "22px",
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "22px",
      fontWeight: "bold",
      //   textAlign: 'center',
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        <headphone name='2.11' src='Audios/2.11 tieu de.mp3'></headphone>
        Listen to five texts and choose the correct answers.
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 22, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px 10px",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "5px 10px",

          border: "2px solid black",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
        ],
        answers: ["0-8", "4-0", "1-4", "2-0", "3-4"],
        initialValue: [],
      },
      Layout: `
          <b>1</b>&ensp;
           Listen to speaker 1. What is special about the new headphones?
            <div style="display: flex;margin-left:40px">
		          <div><input id='0' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  They are worn over the ears. <br> 
                  They are bigger than usual. <br>
                  They have no cables.
                </div>
              </div>
            </div>

          <b>2</b>&ensp;
           Listen to dialogue 2. What is the girl's first instruction?
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  To find a number <br> 
                  To turn on the phone <br>
                  To unlock the phone
                </div>
              </div>
            </div>

          <b>3</b>&ensp;
           Listen to speaker 3. What is the gadget?
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  A tablet <br> 
                  A games console <br>
                  A camcorder
                </div>
              </div>
            </div>
          <b>4</b>&ensp;
           Listen to dialogue 4. What does the girl like most about
           the smartwatch?
            <div style="display: flex;margin-left:40px">
		          <div><input id='3' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  The size <br> 
                  The battery life <br>
                  The design
                </div>
              </div>
            </div>
          <b>5</b>&ensp;
           Listen to speaker 5. Which part(s) of the 'tree' is / are the
           turbines? 
            <div style="display: flex;margin-left:40px">
		          <div><input id='4' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  The leaves <br> 
                  The branches <br>
                  The trunk
                </div>
              </div>
            </div>
      `,
    },
  },
  2: {
    unit: "Cumulative Review 3",
    id: "FGF11-WB-demo-2023-CR-P76-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page76/E2/key/E2answerKey.png",
    component: T6v2,
    fontSize: "22px",
    textareaStyle: {
      // background: 'none',
      width: 950,
      fontSize: 22,
      // height: 70,
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        <div style='max-width: 1000px'>
        Work in pairs. Look at the photos. You are looking for
        something to do on a free afternoon and have three options.
        Choose the best one in your opinion, and justify your choice.
        Say why you are rejecting the other options.</div>
				`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page76/E2/1.jpg" style='width: 20cm; height: 15cm' />
        <textarea id='0' rows='7'>

				`,
        answer: [],
      },
    ],
  },

  3: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-CR-P76-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page76/E3/key/E3answerKey.png",
    component: Circle_Write,

    titleQuestion: [
      {
        num: "3",
        title: `
        Read the text and match paragraphs A-E with the questions
        1-7 below. There are two extra questions. Underline the
        evidence in the text that supports your answer.
       `,
        color: "black",
      },
    ],

    question: {
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          maxLength: 1,
          width: 70,
          height: 29,
          textAlign: "center",
          fontSize: 21,
        },
        answers: ["6", "2", "7", "1", "4"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: 5,
          // borderColor: "transparent",
          // fontWeight: 700,
          color: "black",
          fontSize: 21,
        },
        // initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: {
          // padding: 5,
          borderBottom: "2px solid black",
          // borderRadius: "50%",
          // borderColor: "",
        },

        limitSelect: 1,
        listWords: [
          `Los_Angeles_dancer,_Brian_Perez,_was_eating_out_with_his_friends_one_evening_when_suddenly_everyone_went_quiet.
          To_his_horror, he_saw_that_the_reason_for_this_was_that_people_were_checking_their_phones.
          Realising_he_had_to_do_something_to_stop_this, Brian_made_a_rather_daring_suggestion.
          What_if_they_all_put_their_gadgets_in_a_pile_in_the_middle_of_the_table_until_they_had_finished_the_meal?
          If_anyone_picked_up_their_phone, that_person_would_have_to_pay_the_whole_bill.
          And_so, it_is_said, the_game_of_'phone_stacking' was_born.`, //0

          `The_necessity_for_action_like_this_highlights_a_major_problem_in_today's_society: our_inability_to_disconnect_from_technology.
          But_while_Brian's_idea_deals_with_the_obsession_in_a_social_context, measures_also_need_to_be_taken_at_home.
          Some_people_drop_their_smartphones_into_a_box_the_moment_they_arrive_home,
          which_gives_them_the_chance_to_interact_with_the_people_they_live_with.
          The_fact_that_the_phone_cannot_be_heard - it_is_on_silent - nor_seen - the_flashing_lights_are_hidden_by_the_box -
          means_that_they_are_no_longer_tempted_to_use_it.`, // 1

          `A_less_drastic_solution_is to_ban_electronic_devices_at_certain_times_of_day_when_the_whole_family_is_likely_to_be_together,
          for_example, at_meal_times. This_can_be_hard_for_everyone,
          from_teenagers_desperate_to_text_friends_to_parents_unable_to_switch_off_from_work.
          On_a_normal_day, however, dinner_takes_less_than_an_hour,
          and the_benefits_of_exchanging_opinions_and_anecdotes_with_the_rest_of_the_family_certainly_make_up_for_the_time_spent_offline.`, // 2

          `Taking_a_break_from_technology_is_one_thing, but knowing_when_to_turn_off_a_device_is_another.
          Time_seems_to_stand_still_in_the_virtual_world, and before_you_know_it,
          you_find_that_it_is_three_o'clock_in_the_morning.
          This_is_where_a_digital_curfew_comes_in_handy, a_set_time_when_all_devices_must_be_put_away.
          Evenings_without_technology_are_usually_nice_and_peaceful_and_make_a_more_agreeable_end_to_the_day.`, // 3

          `And_then_it's_time_for_bed. One_of_the_best_ways_of_ensuring_you_can_sleep_at_night_is
          to ban_electronic_devices_altogether_from_the_bedroom.
          Lying_next_to_a_machine_bursting_with_information_is_far_from_relaxing,
          and the_sounds_it_emits_during_the_night_can_easily_wake_you_up.
          With_technology_out_of_the_room, a_line_has_been_drawn_between_daytime_and_sleep_time,
          which_enables_us_to_switch_off_ourselves_and_drift_off_to_sleep.`, // 4
        ],
        answers: ["0-120", "1-48", "2-2", "3-76", "4-26"],

        initialValue: [],
      },
      Layout: `
      <div style="line-height: 38px; margin-top: 10px; display: flex; font-size: 21px">
        <div>
          <div style='display:flex;'><b>1</b>&ensp; <span> a rule that sets the time limit for the use of gadgets each day?</span></div>

          <div style='display:flex;'><b>2</b>&ensp; <span> a tactic for putting electronic devices out of sight?</span></div>

          <div style='display:flex;'><b>3</b>&ensp; <span> a technique for making a venue a technology-free zone?</span></div>

          <div style='display:flex;'><b>4</b>&ensp; <span> a tip for getting a good night's sleep?</span></div>

          <div style='display:flex;'><b>5</b>&ensp; <span> a strategy for protecting the privacy of guests at a party?</span></div>

          <div style='display:flex;'><b>6</b>&ensp; <span> a method for preventing impolite phone behaviour in restaurants?</span></div>

          <div style='display:flex;'><b>7</b>&ensp; <span> a way to promote communication among families for a limited time each day?</span></div>
        </div>
        
        </div>
      </div> 
        <div style='margin-top: 30px; font-size: 21px'>
          <b>Answer:</b><br>
          <div style='display: flex'>
          <b style='margin-right: 30px'>A&ensp;-&ensp;<input id='0'></b>
          <b style='margin-right: 30px'>B&ensp;-&ensp;<input id='1'></b>
          <b style='margin-right: 30px'>C&ensp;-&ensp;<input id='2'></b>
          <b style='margin-right: 30px'>D&ensp;-&ensp;<input id='3'></b>
          <b style='margin-right: 30px'>E&ensp;-&ensp;<input id='4'></b>
        </div><br>
			  <div style="  padding-top: 10px; display: flex; width: 1000px">
          <div>
            <b>A&ensp;</b><br><br><br><br><br><br><br>
            <b>B&ensp;</b><br><br><br><br><br><br><br>
            <b>C&ensp;</b><br><br><br><br><br><br>
            <b>D&ensp;</b><br><br><br><br><br><br>
            <b>E&ensp;</b>
          </div>
					<div>	
            <div style='margin-left:20px; margin-bottom: 35px'><input id='0' type='Circle' /></div>
            <div style='margin-left:20px; margin-bottom: 33px'><input id='1' type='Circle' /></div>
            <div style='margin-left:20px; margin-bottom: 33px'><input id='2' type='Circle' /></div>
            <div style='margin-left:20px; margin-bottom: 33px'><input id='3' type='Circle' /></div>
            <div style='margin-left:20px'><input id='4' type='Circle' /></div>
          </div>
					</div>
          
        `,
    },
  },
};

export default json;
