import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import MatchDots from "../../components/ExcerciseTypes/TypeIn/MatchDots";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P85-E1",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    maxLength: 1,
    inputSize: 50,
    stylesTextInput: {
      //   background: "transparent",
      //   border: "none",
      //   fontSize: "24px",
      fontWeight: "bold",
      //   textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
			What is important to you when choosing a book to read? Put the following ideas from
			the most important to the least important.
									`,
        color: "rgb(78,187,122)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div>
				<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">•</span>
				<span style="font-size: 25px">the genre #</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">•</span>
				<span style="font-size: 25px">characters that I	can relate to #</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">•</span>
				<span style="font-size: 25px">famous writer #</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">•</span>
				<span style="font-size: 25px">easy to read #</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">•</span>
				<span style="font-size: 25px">ideas that I	can learn from #</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">•</span>
				<span style="font-size: 25px">well-written #</span>
			</div>
	
			
									`,
        answer: ["", "", "", "", "", ""],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P85-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page85/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 350,
    stylesTextInput: {
      //   background: "transparent",
      //   border: "none",
      fontSize: "24px",
      fontWeight: "bold",
      //   textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
		Read the review. Which features in exercise 1 are mentioned?
									`,
        color: "rgb(78,187,122)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="display: flex; flex-wrap: wrap;gap:30px;margin-bottom:30px">
			<div>
				<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">•</span>
				<span style="font-size: 25px">the genre</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">•</span>
				<span style="font-size: 25px">characters that I	can relate to</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">•</span>
				<span style="font-size: 25px">famous writer</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">•</span>
				<span style="font-size: 25px">easy to read</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">•</span>
				<span style="font-size: 25px">ideas that I	can learn from</span>
			</div>

			<div>
				<span style="font-weight: bold; margin-right: 10px;justify-content: space-around;">•</span>
				<span style="font-size: 25px">well-written</span>
			</div>

		</div>

		<div>
			<img src="img/FriendsPlus/Page85/12.jpg" width="1000px" />
		</div>

		<h1 style="margin-top: 30px">Answers</h1>
		<div style="display: flex; flex-wrap: wrap;gap:30px;">
			<span style="font-size: 25px">#,</span>
			<span style="font-size: 25px">#,</span>
			<span style="font-size: 25px">#</span>
		</div>
			
									`,
        answer: ["the genre", "easy to read", "ideas that I can learn from"],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P85-E3",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page85/key/E3answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: "Match the sentences 1–4 in exercise 1 with rules a–d.",
        color: "rgb(78,187,122)",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "30px",
              left: "501px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "501px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "188px",
              left: "501px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "272px",
              left: "-19px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "85px",
              right: "489px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "182px",
              right: "489px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "206px",
              right: "489px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
        ],
        answers: ["0-4", "1-3", "2-5"],
        initialValue: [],
      },
      Layout: `
		  <div class="container">
				<div style="display: flex;justify-content: space-between;align-items: center; flex-wrap: wrap; gap: 10px;min-width:1200px">
					<div class="col-5" style="flex:1;margin-left:-60px;margin-top: 30px; ">
						<p style="font-size: 22px; ">
							<span style="font-weight: bold; margin-right: 10px;">1</span>
							<span>Chicken Soup for the Soul is a
							heartwarming collection of true stories.</span>
							<div><input id="0" type="boxMatch" /></div>
	
						</p>
		  
						<p style="font-size: 22px; margin-top: 30px; ">
							<span style="font-weight: bold; margin-right: 10px;">2</span>
							<span> The thing I like most about the book.</span>
							<div><input id="1" type="boxMatch" /></div>
	
						</p>
		  
						<p style="font-size: 22px; margin-top: 30px; ">
							<span style="font-weight: bold; margin-right: 10px;">3</span>
							<span>I’d recommend the book.</span>
							
							<div><input id="2" type="boxMatch" /></div>
						</p>
		  
					</div>
	
					<div class="col-5" style="flex:1;">
	
						<div>
	
								<div style="margin-top: 50px;font-size:22px">
									<span style="font-weight: bold;margin-right: 10px;">a </span>
									The stories demonstrate the best qualities of human beings.

									<div><input id="3" type="boxMatch" /></div>
								</div>
	
								<div style="margin: 10px 10px;font-size:22px">
									<span style="font-weight: bold;margin-right: 10px;">b </span>
									The book comprises inspirational stories
									that help spread happiness and hope
									throughout the society.
									<div><input id="4" type="boxMatch" /></div>
								</div>
	
								<div style="margin: 10px 10px;font-size:22px">
									<span style="font-weight: bold;margin-right: 10px;">c </span>
									The way I am taught to care for others,
									love myself, and overcome obstacles.
									<div><input id="5" type="boxMatch" /></div>
								</div>

						</div>
	
					</div>
				</div>
			</div>	
	
	
		  `,
    },
  },
  4: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P85-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page85/key/E4answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    // maxLength: 1,
    inputSize: 350,
    stylesTextInput: {
      //   background: "transparent",
      //   border: "none",
      fontSize: "24px",
      fontWeight: "bold",
      //   textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
		Study the Key Phrases. Which phrases don’t appear in the book review? Why don’t they
		appear there?
									`,
        color: "rgb(78,187,122)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left: 200px">
			<img src="img/FriendsPlus/Page85/13.jpg"/>
		</div>

		<div style="margin-top: 50px">
			<img src="img/FriendsPlus/Page85/12.jpg" width="1000px" />
		</div>

		<h1 style="margin-top: 30px">Answers</h1>
		<div><textarea id="0" rows="2"></textarea></div>

			
									`,
        answer: [
          "It was directed by; It stars The two phrases don't appear because they are used for writing a film review.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P85-E5",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 350,
    stylesTextInput: {
      //   background: "transparent",
      //   border: "none",
      fontSize: "24px",
      fontWeight: "bold",
      //   textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
		Follow the steps in the Writing Guide.
									`,
        color: "rgb(78,187,122)",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div >
			<img src="img/FriendsPlus/Page85/14.jpg"/>
		</div>
			
									`,
        answer: [],
      },
    ],
  },
};

export default json;
