import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "FGF11-U2-P22-E1",
    audio: "",
    video: "",
    component: T6,
    inputSize: 35,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 2,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page22/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the words with <i>a, e, i, o</i> and <i>u</i>. Then match them with the diagrams (A–L).
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        
				<div style="display: flex; font-size: 22px">
					<div style="display: flex; margin-top: 10px; margin-right: 70px">
						<div style='margin-right: 20px'>
							<div><b>1 </b></div>
							<div><b>2 </b></div>
							<div><b>3 </b></div>
							<div><b>4 </b></div>
							<div><b>5 </b></div>
							<div><b>6 </b></div>
						</div>
			
						<div>
							<span>k#rt#ng</span><br>
							<span>k#y#k#ng</span><br>
							<span>#bs##l#ng</span><br>
							<span>cl#mb#ng</span><br>
							<span>sn#wb##rd#ng</span><br>
							<span>s#rf#ng</span><br>
						</div>
					</div>

					<div style="display: flex; margin-top: 10px">
						<div style='margin-right 20px'>
							<div><b>7 </b>&ensp;&ensp; </div>
							<div><b>8 </b>&ensp;&ensp; </div>
							<div><b>9 </b>&ensp;&ensp; </div>
							<div><b>10 </b>&ensp; </div>
							<div><b>11 </b>&ensp; </div>
							<div><b>12 </b>&ensp; </div>
						</div>
			
						<div>
							<span>b#ng## &ensp;j#mp#ng</span><br>
							<span>b#dyb##rd#ng</span><br>
							<span>p#rk##r</span><br>
							<span>h#ng-gl#d#ng</span><br>
							<span>h#k#ng</span><br>
							<span>m##nt##n &ensp;b#k#ng</span><br>
						</div>
					</div>
				</div>

        <div style='position: relative; '>
          <img style="width:23cm; height:13.1cm" src='img/FriendsPlus/Page22/E1/1.jpg' />
          
          <div style='position:absolute; top:7px ;left:22px '><input id='40' width='45px' font-size='18.5px' border-bottom='none'></div>
          <div style='position:absolute; top:7px ;left:199px'><input id='41' width='45px' font-size='18.5px' border-bottom='none'></div>
          <div style='position:absolute; top:7px ;left:375px'><input id='42' width='45px' font-size='18.5px' border-bottom='none'></div>
          <div style='position:absolute; top:7px ;left:547px'><input id='43' width='45px' font-size='18.5px' border-bottom='none'></div>
          <div style='position:absolute; top:7px ;left:724px'><input id='44' width='45px' font-size='18.5px' border-bottom='none'></div>

          <div style='position:absolute; top:173px ;left:22px '><input id='45' width='45px' font-size='18.5px' border-bottom='none'></div>
          <div style='position:absolute; top:171px ;left:197px'><input id='46' width='45px' font-size='18.5px' border-bottom='none'></div>
          <div style='position:absolute; top:171px ;left:376px'><input id='47' width='45px' font-size='18.5px' border-bottom='none'></div>
          <div style='position:absolute; top:170px ;left:551px'><input id='48' width='45px' font-size='18.5px' border-bottom='none'></div>
          <div style='position:absolute; top:169px ;left:722px'><input id='49' width='45px' font-size='18.5px' border-bottom='none'></div>

          <div style='position:absolute; top:332px ;left:198px'><input id='50' width='45px' font-size='18.5px' border-bottom='none'></div>
          <div style='position:absolute; top:332px ;left:374px'><input id='51' width='45px' font-size='18.5px' border-bottom='none'></div>
        </div>
				`,
        answer: [
          `a`,
          `i`,
          `a`,
          `a`,
          `i`,
          `a`,

          `e`,
          `i`,
          `i`,
          `i`,
          `i`,
          `o`,
          `o`,
          `a`,
          `i`,
          `u`,
          `i`,
          `u`,
          `e`,
          `e`,
          `u`,
          `i`,
          `o`,
          `o`,
          `a`,
          `i`,
          `a`,
          `o`,
          `u`,
          `a`,
          `i`,
          `i`,
          `i`,
          `i`,
          `o`,
          `u`,
          `a`,
          `i`,
          `i`,
          `i`,
          `3`,
          `8`,
          `7`,
          `4`,
          `10`,
          `11`,
          `1`,
          `2`,
          `12`,
          `9`,
          `5`,
          `6`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FGF11-U2-P22-E2",
    // audio: 'Audios/1.07.mp3',
    video: "",
    component: T6,
    inputSize: 800,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 105,
    textAlign: "center",
    // exerciseKey: 'img/FriendsPlus/Page20/Key/E5answerKey.png',
    titleQuestion: [
      {
        num: "2",
        title: `
				Write four sentences using the phrases below and activities from exercise 1.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,

      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <img style="width:23cm; height:6cm" src='img/FriendsPlus/Page22/E2/1.png' />
        <div style='color: rgb(147,51,142); line-height: 40px; font-size: 22px; margin-top:20px'>
				I like the idea of … &ensp;&ensp;&ensp; I think … would be (fun).<br>
				I’m quite keen on … &ensp;&ensp;&ensp; I quite fancy … 
				</div>
				<div style="display: flex; font-size: 22px; line-height: 40px">
						<div style=''>
							<div><b>1&ensp;&ensp; </b></div>
							<div><b>2&ensp;&ensp; </b></div>
							<div><b>3&ensp;&ensp; </b></div>
							<div><b>4&ensp;&ensp; </b></div>            
						</div>
			
						<div>
              <span>#</span><br>
							<span>#</span><br>
							<span>#</span><br>
							<span>#</span><br>
						</div>
					</div>

				</div>
        
				`,
        answer: [``],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FGF11-U2-P22-E3",
    audio: "Audios/1.11 audio.mp3",
    video: "",
    component: T6,
    inputSize: 750,
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 65,
    textAlign: "left",
    exerciseKey: "img/FriendsPlus/Page22/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				<headphone name='1.11' src='Audios/1.11 tieu de.mp3'></headphone> Read the task above. Then listen to two students doing the task. Answer the questions. 
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,

      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <img style="width:15cm; height:4cm" src='img/FriendsPlus/Page22/E3/1.jpg' />
        <div style="display: flex; font-size: 22px; margin-left: 0px; line-height: 40px">
						<div style=''>
							<div><b>1&ensp;&ensp; </b></div><br>
							<div><b>2&ensp;&ensp; </b></div><br>
							<div><b>3&ensp;&ensp; </b></div><br>
							<div><b>4&ensp;&ensp; </b></div>
              
						</div>
			
						<div>
              <span>Why does the girl want to go bodyboarding?</span><br>
							<span>#</span><br>
							<span>Why doesn’t the boy want to go bodyboarding?</span><br>
							<span>#</span><br>
							<span>Why does the girl prefer parkour to abseiling?</span><br>
							<span>#</span><br>
							<span>In the end, they agree to</span>
							<span><input id='3' width='513px'></span>
						</div>
					</div>

				</div>
        
				`,
        answer: [
          `She's never done it before and she'd really like to try it.`,
          `Some of his friends don't have bodyboards.`,
          `You don't need any equipment.`,
          `go climbing./go climbing`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FGF11-U2-P22-E4",
    audio: "Audios/1.11 audio.mp3",
    video: "",
    component: T6,
    inputSize: 200,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 20,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page22/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `
				<headphone name='1.11' src='Audios/1.11 tieu de.mp3'></headphone> Read the <span style='color: rgb(7,142,65)'>Speaking Strategy</span>. Complete the 
				sentences. Then listen again and check. 
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 29,

      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <img style="width:15cm; height:5cm" src='img/FriendsPlus/Page22/E4/1.jpg' />
        <div style="display: flex; font-size: 22px; margin-left: 0px; line-height: 40px; max-width: 1000px">
						<div style=''>
							<div><b>1&ensp;&ensp; </b></div>
							<div><b>2&ensp;&ensp; </b></div>
							<div><b>3&ensp;&ensp; </b></div>
							<div><b>4&ensp;&ensp; </b></div>
              <div><b>5&ensp;&ensp; </b></div>
							<div><b>6&ensp;&ensp; </b></div>
						</div>
			
						<div>
              <span>I’m # # on bodyboarding.</span><br>
							<span>Sorry, but I don’t # that’s a very # #.</span><br>
							<span>I # # trying that.</span><br>
							<span>I think parkour is a # # than abseiling.</span><br>
							<span>Yes, I #.</span><br>
							<span># # #on climbing, then?</span><br>
						</div>
					</div>

				</div>
        
				`,
        answer: [
          `quite`,
          `keen`,
          `think`,
          `good`,
          `idea`,
          `quite`,
          `fancy`,
          `better`,
          `option`,
          `agree`,
          `Can`,
          `we`,
          `agree`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FGF11-U2-P22-E5",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 400,
    textAlign: "left",
    // exerciseKey: 'img/FriendsPlus/Page14/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: "5",
        title: `<span style='font-size: 24px'>You are going to do the task in exercise 3. Make notes about 
				which two activities you would like to do and why.</span>
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 22,
      height: 150,
      padding: 5,
    },
    questions: [
      {
        title: `
				
        <div style=" font-size: 22px; margin-left: 50px">
				<img style="width:16cm; height:4cm" src='img/FriendsPlus/Page22/E3/1.jpg' /><br>
				Which activities would you choose? Why?<br>
        <div><textarea id='0' rows='6'></div>
				Which activities would you not like to do? Why?<br> 
				<div><textarea id='1' rows='6'></div>
        </div>

				`,
        answer: [``],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "FGF11-U2-P22-E6",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    hideBtnFooter: true,
    recorder: true,
    maxLength: 400,
    textAlign: "left",
    // exerciseKey: 'img/FriendsPlus/Page14/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: "6",
        title: `Now do the speaking task. Use your notes from exercise 5.
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 22,
      height: 150,
    },
    questions: [
      {
        title: `
				
        <div style=" font-size: 22px; margin-left: 50px">
				<img style="width:16cm; height:4cm" src='img/FriendsPlus/Page22/E3/1.jpg' /><br>
				
        </div>

				`,
        answer: [``],
      },
    ],
  },
};

export default json;
