import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Cumulative Review 4",
    id: "FGF11-WB-demo-2023-CR-P78-E1",
    audio: "Audios/2.12 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page78/E1/key/E1answerKey.png",
    component: T6v2,
    inputSize: 35,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      textAlign: "center",
      border: "2px solid black",
      borderRadius: "5px",
      height: 45,
      width: 45,
      marginBottom: 10,
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        <headphone name='2.12' src='Audios/2.12 tieu de.mp3'></headphone>
         Listen to four speakers talking about memorable art
         exhibitions. Match speakers 1-4 with sentences A-E. There is
         one extra sentence. 
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
           
           <div>
           <span style="font-weight: bold; margin-right: 10px; margin-bottom: 10px">Speaker 1</span>
           <input id="0">
           </div>
           
           <div>
           <span style="font-weight: bold; margin-right: 10px; margin-bottom: 10px">Speaker 2</span>
           <input id="1">
           </div>
           
           <div>
           <span style="font-weight: bold; margin-right: 10px; margin-bottom: 10px">Speaker 3</span>
           <input id="2">
           </div>
           
           <div>
           <span style="font-weight: bold; margin-right: 10px; margin-bottom: 10px">Speaker 4</span>
           <input id="3">
           </div>
           
           <div>
           <span style="font-weight: bold">A</span>
           <span style="margin-left: 20px">He/ She doesn't remember anything about the exhibition itself.</span>
           </div>

           <div>
           <span style="font-weight: bold">B</span>
           <span style="margin-left: 20px">He/ She remembers the exhibition because it was very boring.</span>
           </div>

           <div>
           <span style="font-weight: bold">C</span>
           <span style="margin-left: 20px">He/ She remembers the exhibition being rather amusing</span>
           </div>

           <div>
           <span style="font-weight: bold">D</span>
           <span style="margin-left: 20px">He/ She only remembers one of the pieces in the exhibition.</span>
           </div>

           <div>
           <span style="font-weight: bold">E</span>
           <span style="margin-left: 20px">He/ She remembers something embarrassing happening at the exhibition.</span>
           </div>
					`,
        answer: ["C", "E", "D", "A"],
      },
    ],
  },
  2: {
    unit: "Cumulative Review 4",
    id: "FGF11-WB-demo-2023-CR-P78-E2",
    audio: "Audios/2.12 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page78/E2/key/E2answerKey.png",
    component: T6v2,
    maxLength: 200,
    inputSize: 850,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        <headphone name='2.12' src='Audios/2.12 tieu de.mp3'></headphone>
         Listen again. Which words or phrases helped give
         you the information that you needed to match the speakers
         with the sentences? 
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 60px'>
           <span style="font-weight: bold; margin-right: 10px">1</span>
           <input id="0">
           </div>
           
           <div style='font-size: 22px; line-height: 60px'>
           <span style="font-weight: bold; margin-right: 10px">2</span>
           <input id="1">
           </div>
           
           <div style='font-size: 22px; line-height: 60px'>
           <span style="font-weight: bold; margin-right: 10px">3</span>
           <input id="2">
           </div>
           
           <div style='font-size: 22px; line-height: 60px'>
           <span style="font-weight: bold; margin-right: 10px">4</span>
           <input id="3">
           </div>
					`,
        answer: [
          "made me laugh",
          "made me want to disappear",
          "I only had eyes for the queen",
          "Who knows what we were going to see?",
        ],
      },
    ],
  },
  3: {
    unit: "Cumulative Review 4",
    id: "FGF11-WB-demo-2023-CR-P78-E3",
    audio: "",
    video: "",
    // recorder: true,
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page78/E3/key/E3answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 350,
    textareaStyle: {
      // background: 'none',
      width: 950,
      fontSize: 22,
      // height: 150,
    },
    titleQuestion: [
      {
        num: "3",
        title: `<div style='max-width: 1000px'>
        Work in pairs. You are staying with your English penfriend
        and want to go to an exhibition where you will learn more
        about British culture. Express your opinions about the three
        exhibitions. Which one will you go to see? Justify your choice
        and say why you are rejecting the other options.</div>
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <textarea id='0' rows='6'>
					`,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Cumulative Review 4",
    id: "FGF11-WB-demo-2023-CR-P78-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page78/E4/key/E4answerKey.png",
    component: Circle_Write,
    maxLength: 1,
    inputSize: 350,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "18px",
      // fontWeight: "bold",
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Read three texts connected with films and choose the
        correct answers.
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 22, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px 10px",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "5px 10px",

          border: "2px solid black",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
        ],
        answers: ["0-0", "1-4", "2-8"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page78/E4/1.jpg" width="600px" /> <br>
          <b>1</b>&ensp;
           The author of the text wants to 
            <div style="display: flex;margin-left:40px">
		          <div><input id='0' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  encourage readers to watch a TV programme. <br> 
                  find out if children's backgrounds affect their futures. <br>
                  explore the differences between the classes in the UK.
                </div>
              </div>
            </div>

          <b>2</b>&ensp;
           What is remarkable about the new technology?
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  The films will be shown in 3D. <br>
                  The special effects will appeal to more of the senses. <br>
                  The audience will take part in the action.
                </div>
              </div>
            </div>

          <b>3</b>&ensp;
          The purpose of the text is to 
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  explain what film extras have to do. <br>
                  provide a list of requirements to be a film extra. <br>
                  give advice on finding work as a film extra.
                </div>
              </div>
            </div>
      `,
    },
  },
};

export default json;
