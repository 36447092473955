import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P59-E1",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page59/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
        Order the words to make sentences with have something done.
												`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style='line-height:2.5'>
        <div><b>1</b>&ensp; You look different. (had / you / your / dyed / hair / have)?</div>
        #

        <div><b>2</b>&ensp; There’s room for you all to stay at our house this year. (extension / we’ve / built / an / had).</div>
        #

        <div><b>3</b>&ensp; I can’t text you. (phone / had / I’ve / stolen / my).</div>
        #

        <div><b>4</b>&ensp; He’s started wearing glasses. (tested / eyes / had / his / he’s)</div>
        #

        <div><b>5</b>&ensp; I couldn’t open my parents’ front door. (locks / they / had / the / changed / had)!</div>
        #

        <div><b>6</b>&ensp; This is a great photo. (can / copy / have / made / we / a)?</div>
        #
      
      </div>
						
												`,
        answer: [
          `Have you had your hair dyed?`,
          `We've had an extension built.`,
          `I've had my phone stolen.`,
          `He's had his eyes tested.`,
          `They had had the locks changed.`,
          `Can we have a copy made?`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P59-E2",
    audio: "",
    // hideBtnFooter:true,
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page59/key/E2answerKey.png",
    component: T6v2,
    maxLength: 50,
    inputSize: 400,
    textareaStyle: { width: "530px" },
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        Complete the text with the words in brackets and <i>have</i> something <i>done</i>.
												`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
        <div style='background: rgb(238,238,240); width:100%; margin-top:20px; border-radius:20px;box-shadow: 3px 3px  3px  black;'>
        <img src="img/FriendsPlus/Page59/E2/1.jpg" style='width:70%; margin-left:130px;border-radius:20px' />
        <div style='width:781px;line-height:2; margin:auto;padding:20px; font-size: 22px'>
        The British TV show <i>10 Years Younger</i> helps members 
of the public to change their appearance and look 
younger. At the start of the show, the participants 
<sup><b>1</b></sup> # (their age / guess) 
by a hundred members of the public. They then <br>
<sup><b>2</b></sup> # (their hair / do) by top 
hairdressers. They also <sup><b>3</b></sup> #
(new clothes / choose) by fashion experts. If necessary, 
they <br> <sup><b>4</b></sup> # (their teeth / fix) 
by a dentist. Some of them even <sup><b>5</b></sup> #
(some work / do) on their face by a 
cosmetic surgeon. At the end of the show, they 
<sup><b>6</b></sup> # (their appearance / 
judge) by a hundred strangers again to see if they look 
younger. The show is popular, although it has also been 
criticised for focusing too much on appearance.
        </div>
      </div>
												`,
        answer: [
          `have their age guessed`,
          `have their hair done`,
          `have new clothes chosen`,
          `have their teeth fixed`,
          `have some work done`,
          `have their appearance judged`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P59-E5",
    // audio: 'Audios/1-22 Friends Plus 8_WB.mp3',

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page59/key/E3answerKey.png",
    component: T6v2,
    maxLength: 60,
    inputSize: 445,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
      // fontWeight: "bold",
      borderBottom: "2px dotted",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        <div style='max-width: 1000px'>
        Complete the sentences with the words below, <i>have</i> something <i>done</i> and the words in brackets.</div>
		`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:50px; font-size: 22px'>
        <div style='color:rgb(146,39,143);font-weight:bold'>dye paint publish remove steal</div>

        <div><b>1</b>&ensp; My grandfather has written an interesting book. He # (should / it).</div>

        <div><b>2</b>&ensp; The graffiti on our house looks terrible. We really # (must / it).</div>

        <div><b>3</b>&ensp; She used to have blonde hair, but she # (must / it).</div>

        <div><b>4</b>&ensp;Your bedroom looks too dark. You # (should / the walls).</div>

        <div><b>5</b>&ensp; You shouldn’t leave your bike unlocked. You # (might / it).</div>


      </div>
        `,
        answer: [
          `should have it published`,
          `must have it removed`,
          `must have it dyed`,
          `should have the walls painted`,
          `might have it stolen`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P59-E4",
    // audio: "Audios/1-24 Friends Plus 8_WB.mp3",
    video: "",
    component: T6v2,
    exerciseKey: "img/FriendsPlus/Page59/key/E4answerKey.png",
    inputSize: 220,
    maxLength: 30,
    checkUppercase: true,
    isHiddenCheck: true,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
      // fontWeight: "bold",
      borderBottom: "2px dotted",
    },
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: "Put the verbs into the correct forms.",

        color: "black",
      },
    ],
    questions: [
      {
        title: `
       <div style='line-height:50px'>
        <div style='display:flex'><b>1</b><div style='margin-left:10px'>My neighbour (have) # their house (burgle) # last weekend.</div></div>

        <div style='display:flex'><b>2</b><div style='margin-left:10px'>The new manager always (have) # her coffee (make) # by one of the clerks.</div></div>

        <div style='display:flex'><b>3</b><div style='margin-left:10px'>The teacher (have) # some of our essays (rewrite) # quite a few times this semester.</div></div>

        <div style='display:flex'><b>4</b><div style='margin-left:10px'>In the near future, we (have) # most of our work (do) # by robots.</div></div>

        <div style='display:flex'><b>5</b><div style='margin-left:10px'>Don't worry about the dinner ! I (have) # some food (deliver) #.</div></div>

        <div style='display:flex'><b>6</b><div style='margin-left:10px'>I cannot drive to work today. I (have) # my car (fix) # .</div></div>
       
       </div>
       	`,
        answer: [
          `had`,
          `burgled`,
          `has`,
          `made`,
          `has had`,
          `rewritten`,
          `will have`,
          `done`,
          `will have`,
          `delivered`,
          `am having`,
          `fixed`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P59-E5",
    audio: "Audios/1-24 Friends Plus 8_WB.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page59/key/E5answerKey.png",
    component: T6v2,
    maxLength: 200,
    inputSize: 700,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "24px",
      // fontWeight: 'bold',
      // borderBottom:'dotted',
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        Correct the mistake in each of the following sentences. 
		`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2.2'>
        <div style='display:flex'><b>1.</b>&ensp; Mark has his hair be cut in a local barber's.</div>
        #
        <div style='display:flex'><b>2.</b>&ensp; Nina has her pet take care of twice a month.</div> 
        #
        <div style='display:flex'><b>3.</b>&ensp; The police had some cars towing away from the high street.</div> 
        #
        <div style='display:flex'><b>4.</b>&ensp; My brother used to have his car to be washed every Friday.</div> 
        #
        <div style='display:flex'><b>5.</b>&ensp; The old lady loves to have her milk is delivered early in the morning.</div> 
        #
      </div>
        `,
        answer: [
          `has his hair cut`,
          `has her pet taken`,
          `had some cars towed`,
          `have his car washed`,
          `have her milk delivered`,
        ],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P59-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page56/key/E6answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 150,
    textareaStyle: { width: "500px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "20px",
      textAlign: "center",
      fontWeight: "bold",
      // border:'dotted',
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        A TASK Write a discussion essay with the title Film first or book first? Discuss.
        `,
        color: "#6A5EA1",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2'>
        <div>
        <b>B THINK AND PLAN</b>
        <div><b>1</b>&ensp; Introduce the topic.</div>
        <div><b>2</b>&ensp; Why is it better to read the book first? Present the arguments.</div>
        <div><b>3</b>&ensp; Why is it better to watch the film first? Present the arguments.</div>
        <div><b>4</b>&ensp; In your opinion, which is better: watching the film first or reading the book first?</div>
        </div>

        <div>
        <b>C WRITE Use the text in exercise 2 and the writing guide.</b>
        <div style='display:flex  '>
        <img src="img/FriendsPlus/Page59/E6/1.jpg" style='width:45%' />
          <div style='margin-left:15px'>
          <i>ANSWER:</i> 
          <textarea id='0' rows='5'></textarea></div>
        </div>
          
        </div>
      
  
  


      </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
