import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P26-E1",
    audio: "Audios/1.13 audio.mp3",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page26/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 180,
    stylesTextInput: {
      textAlign: "center",
      borderStyle: "dotted",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        <headphone name='1.13' src='Audios/1.13 tieu de.mp3'></headphone>&ensp;Read the <span style='color:rgb(207,75,116)'>Listening Strategy</span>. Then listen and circle the <br>number or measurement you hear.
				`,
        color: "black",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        //  listWords: [
        //   `should have % `,
        //   `shouldn’t have % `,
        //   `must have % `,
        //   `may not have % `,
        //   `may have % `,
        //   `don’t have to % `,
        //   `might have % `,
        //   `have to % `,
        //   `have to % `,
        // ],
        answers: ["0-8", "1-0", "2-0", "3-8", "4-4", "5-8", "6-8", "7-0"],

        initialValue: [],
      },

      Layout: `
      <img style='border-radius:30px' src="img/FriendsPlus/Page26/E1/1.jpg" />
      <div style="display:flex">
      <div>
        <b>1</b>&ensp;
          <div style='display:flex'>
          <div style='width:1cm;margin-left:20px;'>
          <input id='0'  type='Circle' />
        </div>
          <div>
              115,000<br>
              100,050<br>
              150,000<br><br>
            </div>
         </div>
  
      <b>2</b>&ensp;
      <div style='display:flex'>
        <div style='width:1cm;margin-left:20px'>
        <input id='1'  type='Circle' />
        </div>
        <div>
          3,700,000<br>
          37,000,000<br>
          3,000,700<br><br>
        </div>
      </div>
  
      <b>3</b>&ensp;
      <div style='display:flex'>
        <div style='width:1cm;margin-left:20px'>
        <input id='2'  type='Circle' />
        </div>
        <div>
        2.07<br>
        0.27<br>
        2.70<br><br>
        </div>
      </div>
  
      <b>4</b>&ensp;
      <div style='display:flex'>
        <div style='width:1cm;margin-left:20px'>
        <input id='3'  type='Circle' />
        </div>
        <div>
        3 <sup>1</sup>/<sub>10</sub><br>
        <sup>1</sup>/<sub>10</sub><br>
        <sup>3</sup>/<sub>10</sub><br><br>
        </div>
      </div>
      </div>
  
    <div style='margin-left:150px'>
      <b>5</b>&ensp;
      <div style='display:flex'>
        <div style='width:1cm;margin-left:20px'>
          <input id='4'  type='Circle' />
        </div>
        <div>
            35%<br>
            30.5%<br>
            13%<br><br>
        </div>
      </div>
  
      <b>6</b>&ensp;
      <div style='display:flex'>
        <div style='width:1cm;margin-left:20px'>
          <input id='5'  type='Circle' />
        </div>
        <div>
          22–25<br>
          2–25<br>
          20–25<br><br>
        </div>
      </div>
  
      <b>7</b>&ensp;
      <div style='display:flex'>
        <div style='width:1cm;margin-left:20px'>
          <input id='6'  type='Circle' />
        </div>
        <div>
          25°C<br>
          -5°C<br>
          -5°C<br><br>
        </div>
      </div>
  
          
      <b>8</b>&ensp;
      <div style='display:flex'>
        <div style='width:1cm;margin-left:20px'>
          <input id='7'  type='Circle' />
        </div>
        <div>
        1930<br>
        1913<br>
        913<br><br>
        </div>
      </div>
      </div>
      </div>
     </div>
                
      `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P26-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page26/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
        Read aloud all the numbers and measurements in exercise 1.
				`,
        color: "black",
      },
    ],
    hideBtnFooter: true,
    recorder: true,
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        //  listWords: [
        //   `should have % `,
        //   `shouldn’t have % `,
        //   `must have % `,
        //   `may not have % `,
        //   `may have % `,
        //   `don’t have to % `,
        //   `might have % `,
        //   `have to % `,
        //   `have to % `,
        // ],
        answers: ["0-8", "1-0", "2-0", "3-8", "4-4", "5-8", "6-8", "7-0"],

        initialValue: [],
      },

      Layout: `
      
      <div style="display:flex">
      <div>
        <b>1</b>&ensp;
          <div style='display:flex'>
          <div style='width:1cm;margin-left:20px;'>
          <input id='0'  type='Circle' />
        </div>
          <div>
              115,000<br>
              100,050<br>
              150,000<br><br>
            </div>
         </div>
  
      <b>2</b>&ensp;
      <div style='display:flex'>
        <div style='width:1cm;margin-left:20px'>
        <input id='1'  type='Circle' />
        </div>
        <div>
          3,700,000<br>
          37,000,000<br>
          3,000,700<br><br>
        </div>
      </div>
  
      <b>3</b>&ensp;
      <div style='display:flex'>
        <div style='width:1cm;margin-left:20px'>
        <input id='2'  type='Circle' />
        </div>
        <div>
        2.07<br>
        0.27<br>
        2.70<br><br>
        </div>
      </div>
  
      <b>4</b>&ensp;
      <div style='display:flex'>
        <div style='width:1cm;margin-left:20px'>
        <input id='3'  type='Circle' />
        </div>
        <div>
        3 <sup>1</sup>/<sub>10</sub><br>
        <sup>1</sup>/<sub>10</sub><br>
        <sup>3</sup>/<sub>10</sub><br><br>
        </div>
      </div>
      </div>
  
    <div style='margin-left:150px'>
      <b>5</b>&ensp;
      <div style='display:flex'>
        <div style='width:1cm;margin-left:20px'>
          <input id='4'  type='Circle' />
        </div>
        <div>
            35%<br>
            30.5%<br>
            13%<br><br>
        </div>
      </div>
  
      <b>6</b>&ensp;
      <div style='display:flex'>
        <div style='width:1cm;margin-left:20px'>
          <input id='5'  type='Circle' />
        </div>
        <div>
          22–25<br>
          2–25<br>
          20–25<br><br>
        </div>
      </div>
  
      <b>7</b>&ensp;
      <div style='display:flex'>
        <div style='width:1cm;margin-left:20px'>
          <input id='6'  type='Circle' />
        </div>
        <div>
          25°C<br>
          -5°C<br>
          -5°C<br><br>
        </div>
      </div>
  
          
      <b>8</b>&ensp;
      <div style='display:flex'>
        <div style='width:1cm;margin-left:20px'>
          <input id='7'  type='Circle' />
        </div>
        <div>
        1930<br>
        1913<br>
        913<br><br>
        </div>
      </div>
      </div>
      </div>
     </div>
                
      `,
    },
  },
  3: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P26-E3",
    audio: "Audios/1.14 audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 150,
    maxLenght: 10,
    stylesTextInput: {
      textAlign: "center",
      borderStyle: "dotted",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				<headphone name='1.14' src='Audios/1.14 tieu de.mp3'></headphone>&ensp;Listen and complete the facts with the numbers and measurements you hear.
				`,
        color: "black",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:2.3'>
          <div style="display:flex"><b>1.</b>&ensp; <span>Your body makes # new blood cells every 
          second.</span></div>

          <div style="display:flex"><b>2.</b>&ensp; <span>There are nearly # kilometres of blood vessels 
          in an average adult body.</span></div>

          <div style="display:flex"><b>3.</b>&ensp; <span>Only # of the cells in our body are human; the 
          other # are bacteria.</span></div>

          <div style="display:flex"><b>4.</b>&ensp; <span>Your brain is only # of your body’s weight, but 
          it uses # of the oxygen.</span></div>

          <div style="display:flex"><b>5.</b>&ensp; <span>Your temperature is usually about # lower in 
          the morning than in the evening.</span></div>

          <div style="display:flex"><b>6.</b>&ensp; <span>Blondes have about # more hairs on their 
          head than people with black hair.</span></div>

          <div style="display:flex"><b>7.</b>&ensp; <span>Adult humans have # bones, but newborn 
          babies have a lot more.</span></div>

          <div style="display:flex"><b>8.</b>&ensp; <span>The smallest muscle in the body is inside the ear; it is only #
          millimetres long.</span></div>

          <div style="display:flex"><b>9.</b>&ensp; <span>Men usually stop growing when they are #
          years old, women when they are#</span></div>
        
        </div>
        `,
        answer: [
          `2 million`,
          `100,000`,
          `10%`,
          `90%`,
          `2%`,
          `1/5`,
          `0.5°C`,
          `30,000`,
          `206`,
          `1.27`,
          `20–22`,
          `15–16`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P26-E4",
    audio: "Audios/1.15 audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/Key/E4answerKey.png",
    component: Circle_Write,
    //inputSize: 40,
    stylesTextInput: {
      textAlign: "center",
      //borderStyle: "dotted",
      background: "none",
      borderBottom: "none",
      fontSize: "25px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        <headphone name='1.15' src='Audios/1.15 tieu de.mp3'></headphone> Read the three short texts below about a Swedish 
        woman called Anna Bågenholm, who had an accident. Then 
        listen to an interview about her and decide which is the best 
        summary of the accident. Underline the incorrect parts of 
        the other options.
				`,
        color: "black",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,

    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: {
          width: 149,
          fontSize: 25,
          borderBottom: "none",
          textAlign: "center",
        },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {},
        selectWordStyle: {
          // padding: 5,
          color: "black",
        },
        limitSelect: 5,
        listWords: [
          `<div> Anna_had_an_accident_while_skiing. Nobody_found_her_for_several_hours and_when_they_did, they_believed_she_was_dead. But when_she_arrived_at_the_hospital, she_came_back__to_life </div> <br>

         <div> Anna_tried_to_rescue_a_colleague_who_had_an_accident_in_the_mountains, but_fell_into_some_freezing_water. She_only_survived_because_a_helicopter_took_her_to_hospital. </div> <br>

         <div> Anna_had_an_accident_while_skiing. She_became_so_cold_that_her_breathing_and_heartbeat_stopped_for_hours, but_she_made_a_full_recovery. </div>`,
          `✓  ✓ ✓`,
        ],
        answers: ["0-4", "0-12", "1-0", "1-4", "0-42"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;font-size:25px;display:flex'>
      <div style=''>
        <div style='margin-top:15px'><b>a.</b></div>
        <div style='margin-top:90px'><b>b.</b></div>
        <div style='margin-top:95px'><b>c.</b></div>
      </div>
        <div style='width:20cm;margin-left:25px'>
         <div> <input id='0' type='Underline'></div>
        </div>
        <div style='position:absolute;top:20px;left:835px; display:flex; flex-direction: column;line-height:50px  '>
              <div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
              <div style='width:40px;height:40px; margin:90px 0px; border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
              <div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>

             
          </div>
        <div style='position:absolute;top:-25px;left:846px;color: white; display:flex; flex-direction: column;line-height:130px  '>
          <input id='1' type='Circle'>
          
        </div>
       
      </div>
       
           
        
      `,
    },
  },
};

export default json;
