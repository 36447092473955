import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Cumulative Review 2",
    id: "FGF11-WB-demo-2023-CR-P74-E1",
    audio: "Audios/2.10 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page74/E1/key/E1answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 38,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      // fontWeight: "bold",
      textAlign: "center",
      border: "2px solid black",
      borderRadius: "5px",
      height: 35,
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        <headphone name='2.10' src='Audios/2.10 tieu de.mp3'></headphone>
         Read the sentences and practise saying the
         numbers. Then listen to an interview with an endurance
         cyclist. Are the sentences true (T) or false (F)?
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
           
           <div>
           <span style="font-weight: bold">1</span>
           <span style="margin-left: 20px">The distance an endurance cyclist usually cycles is between 50 and 100 kilometres.</span>
           <input id="0">
           </div>
           
           <div>
           <span style="font-weight: bold">2</span>
           <span style="margin-left: 20px">Participants in the Paris-Brest-Paris event have to complete the course in 90 hours.</span>
           <input id="1">
           </div>
           
           <div>
           <span style="font-weight: bold">3</span>
           <span style="margin-left: 20px">The French event started back in 1871.</span>
           <input id="2">
           </div>
           
           <div>
           <span style="font-weight: bold">4</span>
           <span style="margin-left: 20px">The winners of the Race Across America sleep for less than two hours a day. </span>
           <input id="3">
           </div>
           
           <div>
           <span style="font-weight: bold">5</span>
           <span style="margin-left: 20px">In the American event, the fastest cyclists ride around 800 km per day.</span>
           <input id="4">
           </div>
					`,
        answer: ["F", "T", "F", "T", "F"],
      },
    ],
  },
  2: {
    unit: "Cumulative Review 2",
    id: "FGF11-WB-demo-2023-CR-P74-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page74/E2/key/E2answerKey.png",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 180,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // fontSize: "22px",
      // fontWeight: "bold",
      // textAlign: "center",
    },
    textareaStyle: {
      // background: 'none',
      width: 1000,
      fontSize: 24,
      padding: 0,
    },
    titleQuestion: [
      {
        num: "2",
        title: `
		     Work in pairs. Look at the photo and answer the questions.
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div>
           <span style="font-weight: bold">1</span>
           <span style="margin-left: 20px">How do you think the people are feeling?</span>
           <textarea id='0' rows='3'>
           </div>
           
           <div>
           <span style="font-weight: bold">2</span>
           <span style="margin-left: 20px">Do you think cycling is a good way of keeping fit?</span>
           <textarea id='1' rows='3'>
           </div>
           
           <div>
           <span style="font-weight: bold">3</span>
           <span style="margin-left: 20px">What do you do to keep fit? Do you think this is enough? Why? / Why not?</span>
           <textarea id='2' rows='3'>
           </div>
           <img src="img/FriendsPlus/Page74/E2/1.jpg" width="700px" />
					`,
        answer: [],
      },
    ],
  },
  3: {
    unit: "Cumulative Review 2",
    id: "FGF11-WB-demo-2023-CR-P74-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page74/E3/key/E3answerKey.png",
    component: Circle_Write,
    // maxLength: 1,
    inputSize: 950,
    fontSize: "22px",
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "22px",
      fontWeight: "bold",
      //   textAlign: 'center',
    },
    titleQuestion: [
      {
        num: "3",
        title: `
         Read the text and choose the correct answers.
					`,
        color: "#262324",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 22, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px 10px",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "5px 10px",

          border: "2px solid black",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c <br> d`, //1
          `a <br> b <br> c <br> d`, //1
          `a <br> b <br> c <br> d`, //1
          `a <br> b <br> c <br> d`, //1
          `a <br> b <br> c <br> d`, //1
        ],
        answers: ["0-12", "1-4", "2-8", "3-0", "4-4"],
        initialValue: [],
      },
      Layout: `
          <b>1</b>&ensp;
            The participants of the study were asked to run  
            <div style="display: flex;margin-left:40px">
		          <div><input id='0' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  for a certain amount of time a day. <br> 
                  for a certain distance a day. <br>
                  for a certain amount of time several times a day <br>
                  for a certain distance several times a day.
                </div>
              </div>
            </div>

          <b>2</b>&ensp;
            The results of the study suggest that
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  anybody can win a sports event. <br> 
                  an athlete's success can depend on the time of their event. <br>
                  'larks' are more likely to win sports events than 'owls'. <br>
                  only the best athletes can break records.
                </div>
              </div>
            </div>

          <b>3</b>&ensp;
            Athletes can take advantage of these results before an event to
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  choose the time that they compete. <br> 
                  adjust the number of hours they sleep. <br>
                  work out a bedtime schedule. <br>
                  create a suitable training programme.
                </div>
              </div>
            </div>
          <b>4</b>&ensp;
            According to the article, talent spotting would be more effective if
            <div style="display: flex;margin-left:40px">
		          <div><input id='3' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  testing happened at different times of the day. <br> 
                  teenagers got more sleep. <br>
                  schoolchildren started doing sport at a younger age. <br>
                  high performers were more enthusiastic.
                </div>
              </div>
            </div>
          <b>5</b>&ensp;
          The most successful football teams in Europe appear to have players 
            <div style="display: flex;margin-left:40px">
		          <div><input id='4' type='Circle'/></div>
		            <div style='margin-left:20px'>
                  who get a good night's sleep before a match. <br> 
                  whose body clock coincides with the time of the matches. <br>
                  who are used to playing in international competitions. <br>
                  whose bedtime schedule never changes.
                </div>
              </div>
            </div>
      `,
    },
  },
};

export default json;
