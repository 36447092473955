import MatchDots from "../../components/ExcerciseTypes/TypeIn/MatchDots";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P69-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page69/key/E1answerKey.png",
    component: MatchDots,
    titleQuestion: [
      {
        num: "1",
        title: `
        Match the abbreviations with their meaning.
					`,
        color: "black",
      },
    ],
    stylesTextInput: { borderBottom: "10px dashed", textAlign: "center" },
    inputSize: 50,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "28px",
              left: "250px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "80px",
              left: "250px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "134px",
              left: "250px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "185px",
              left: "250px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "234px",
              left: "250px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "286px",
              left: "250px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "338px",
              left: "250px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "390px",
              left: "250px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "28px",
              left: "530px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "82px",
              left: "530px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "134px",
              left: "530px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "185px",
              left: "530px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "234px",
              left: "530px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 13
          {
            boxMatchStyle: {
              position: "absolute",
              top: "286px",
              left: "530px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 14
          {
            boxMatchStyle: {
              position: "absolute",
              top: "338px",
              left: "530px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 15
          {
            boxMatchStyle: {
              position: "absolute",
              top: "390px",
              left: "530px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 16
        ],
        answers: ["0-14", "1-13", "11-2", "3-8", "4-9", "15-5", "12-6", "10-7"],
        initialValue: [],
      },
      Layout: `
          <div style='display: flex; justify-content: space-around;  width:1000px; font-size: 26px;;line-height:2'>
            <div >
                <div><b> 3D  </b></div>
                <div><b> 4G  </b></div>
                <div><b> App  </b></div>
                <div><b> IoT  </b></div>
                <div><b> Wi-fi  </b></div>
                <div><b> IT  </b></div>
                <div><b> LAN  </b></div>
                <div><b> OS  </b></div>
            </div>    
            <div >
              <div style='display: flex;'>
                <div>  internet of things </div>
              </div>
              <div style='display: flex;'>
                <div> wireless fidelity.</div>
              </div>
              <div style='display: flex;'>
                <div> operating system </div>
              </div>
              <div style='display: flex;'>
                <div> application </div>
              </div>
              <div style='display: flex;'>
                <div> local area network </div>
              </div>

              <div style='display: flex;'>
                <div> fourth generation </div>
              </div>

              <div style='display: flex;'>
                <div> three dimensional</div>
              </div>

              <div style='display: flex;'>
                <div> information technology </div>
              </div>
            </div>     
          </div>     
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          <input id='10' type= 'boxMatch' />
          <input id='11' type= 'boxMatch' />
          <input id='12' type= 'boxMatch' />
          <input id='13' type= 'boxMatch' />
          <input id='14' type= 'boxMatch' />
          <input id='15' type= 'boxMatch' />

      `,
    },
  },
  2: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P69-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page69/key/E2answerKey.png",
    component: T6,
    maxLength: 1,
    isHiddenCheck: true,

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
      width: 50,
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Read the texts (A–C) and match the titles with the texts.
        There is one extra title.
			  `,
        color: "black",
      },
    ],

    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page69/E2/1.jpg" style='width:100%; margin:auto;border-radius:20px' />
		<div style="line-height:2.5;margin-top:20px;display:flex">
        <div>
        <div style='display:flex;'><b>1.</b>&ensp; <span>Life is impossible without internet</span> </div>

        <div style='display:flex;'><b>2.</b>&ensp; <span>Life is better with green energy</span>  </div>

        <div style='display:flex;'><b>3.</b>&ensp; <span>Life is a reflection of activities</span> </div>

        <div style='display:flex;'><b>4.</b>&ensp; <span>Life is a collection of skills</span></div>
        </div>
    
    </div>	
    <div style='margin-left: 50px;display:flex; '>
              <b>Answer:&ensp;&ensp;</b><br>
              &ensp;<b>A - #&ensp;&ensp;&ensp;</b>
              &ensp;<b>B - #&ensp;&ensp;&ensp;</b>
              &ensp;<b>C - #&ensp;&ensp;&ensp;</b>
</div>			
											`,
        answer: [`2`, `1`, `4`],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P69-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page69/key/E3answerKey.png",
    component: T6,
    maxLength: 1,
    titleQuestion: [
      {
        num: "3",
        title: `
        Read the <span style='color:rgb(96,74,159)'>Reading Strategy.</span> Then match the texts 
        (A–C) with statements (1–4). One text matches with two 
        statements.
			  `,
        color: "black",
      },
    ],
    stylesTextInput: {
      // borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: "black",
      textTransform: "uppercase",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page69/E3/1.jpg" style='width:60%; margin-left:100px;border-radius:20px' />
        <img src="img/FriendsPlus/Page69/E2/1.jpg" style='width:100%; margin:auto;border-radius:20px' />
       
		<div style="line-height:2.5;margin-top:20px;display:flex">
        <div>
        <div><b>1.</b>&ensp; <span>There seems to be no free time for city people in the future as it is often guessed.</span></div>

        <div><b>2.</b>&ensp; <span>People will become more concerned about the 
        environment.</span></div>

        <div><b>3.</b>&ensp; <span>Adaptation is one of the important skills of the city 
        people in the future.</span></div>

        <div><b>4.</b>&ensp; <span>Energy may cost next to nothing in the future.</span></div>
        </div>
        <div style='margin-left:10px'>
        # <br>
        # <br>
        # <br>
        # <br>
        </div>
    
    </div>				
											`,
        answer: [`B`, `A`, `C`, `A`],
      },
    ],
  },
};

export default json;
