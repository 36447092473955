import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P27-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page27/Key/E1answerKey.png",
    component: T6v2,
    inputSize: 300,
    stylesTextInput: {
      textAlign: "center",
      // borderStyle: "dotted",
      background: "none",
      // borderBottom:'none',
      fontSize: "25px",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
       <div style='max-width:90%'>Make predictions about your future. Use the future continuous, affirmative or negative form of the verbs in brackets.</div>
				`,
        color: "black",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:2.5'>
          <span style='color:rgb(50,92,121);margin-left:30px'>When I’m thirty years old,</span>
          <div><b>1.</b>&ensp; I <span style='color:rgb(50,92,121)'>’ll be living</span>(live) in New York.</div>

          <div><b>2.</b>&ensp; I # (work) for a large company.</div>

          <div><b>3.</b>&ensp; I # (earn) a lot of money.</div>

          <div><b>4.</b>&ensp; I # (spend) a lot of time doing hobbies.</div>

          <div><b>5.</b>&ensp; I # (drive) an expensive car.</div>

          <div><b>6.</b>&ensp; I # (live) with friends.</div>

          <div><b>7.</b>&ensp; I # (travel) a lot for work and pleasure.</div>

          <div><b>8.</b>&ensp; I # (see) the same people I see now.</div>
        </div>
        `,
        answer: [
          `'ll be working | won't be working`,
          `'ll be earning | won't be earning`,
          `'ll be spending | won't be spending`,
          `'ll be driving | won't be driving`,
          `'ll be living | won't be living`,
          `'ll be travelling | won't be travelling`,
          `'ll be seeing | won't be seeing`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "FGF11_WB-demo-2023-U3-P27-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page27/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
        Look at the timeline for a new medical school. Write 
        sentences using the affirmative or negative form of the 
        future perfect and the prompts below.
				`,
        color: "black",
      },
    ],
    inputSize: 700,
    checkUppercase: true,
    stylesTextInput: {
      // textAlign: "center",
      // borderStyle: "dotted",
      background: "none",
      // borderBottom:'none',
      fontSize: "25px",
    },
    questions: [
      {
        title: `
        <img style="padding-top:10px; padding-left:90px" src='img/FriendsPlus/Page27/E2/1.jpg'  />
        <div style='line-height:2.3'>
          <div><b>1.</b>&ensp; by 2038 / architects / complete / the plans</div>
          <div style='color:rgb(50,92,121);margin-left:40px'>By 2038, the architects won’t have completed the plans.</div>

          <div><b>2.</b>&ensp; by 2039 / architects / complete / the plans</div>
          #

          <div><b>3.</b>&ensp; by 2039 / building work / begin</div>
          #

          <div><b>4.</b>&ensp; by 2039 / building work / begin</div>
          #

          <div><b>5.</b>&ensp; by 2045 / the first students / arrive</div>
          #

          <div><b>6.</b>&ensp; by 2047 / the first students / graduate</div>
          #

          <div><b>7.</b>&ensp; by 2047 / the first students / graduate</div>
          #
        
        </div>
        
        `,
        answer: [
          `By 2039, the architects will have completed the plans.`,
          `By 2039, building work won't have begun.`,
          `By 2043, building work won't have finished.`,
          `By 2045, the first students will have arrived.`,
          `By 2047, the first students won't have graduated.`,
          `By 2049, the first students will have graduated.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FGF11_WB-demo-2023-U3-P27-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page27/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 250,
    stylesTextInput: {
      textAlign: "center",
      // borderStyle: "dotted",
      background: "none",
      // borderBottom:'none',
      fontSize: "25px",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Complete the text with the future continuous or future perfect form of the verbs in brackets.
				`,
        color: "black",
      },
    ],

    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='background: rgb(254,242,226); width:100%; margin-top:20px;margin-left:50px; border-radius:20px;box-shadow: 15px 15px 15px rgb(207,207,207)'>
        <div style='width:840px;line-height:2; margin:auto;padding:20px'>  
          According to researcher Cadell Last from the 
          Global Brain Institute, a completely new type of 
          human <sup><b>1</b></sup> # (evolve) by 2050. We 
          <sup><b>2</b></sup> # (live) much longer on average 
          – perhaps to the age of 120. And we 
          <sup><b>3</b></sup> # (enjoy) our old age much more 
          because even when we are 90 or 100, we 
          <sup><b>4</b></sup> # (lead) active lives. Mr Last 
          believes that, by the middle of this century, 
          scientists <sup><b>5</b></sup> # (invent) robots that 
          can do most tasks, so humans <sup><b>6</b></sup> #
          (not do) boring housework or repetitive jobs. 
          Instead, they <sup><b>7</b></sup> # (spend) more 
          time living in virtual reality. But not everybody 
          agrees with Mr Last’s vision. Some scientists think 
          that even by the end of the century, humans 
          <br><sup><b>8</b></sup> # (not change) very much.
        </div>
      </div>
												`,
        answer: [
          `will have evolved`,
          `will be living`,
          `will be enjoying`,
          `will be leading`,
          `will have invented`,
          `won't be doing`,
          `will be spending`,
          `won't have changed`,
        ],
      },
    ],
  },

  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P27-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page27/Key/E4answerKey.png",
    component: T6v2,
    inputSize: 330,
    stylesTextInput: {
      textAlign: "center",
      borderStyle: "dotted",
      fontSize: "24px",
    },
    textareaStyle: { width: 830 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title: `
        Write one or two sentences about the topics using quantifiers.
				`,
        color: "rgb(78, 59, 151)",
        star: 3,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div>
            <div>the time you spend doing homework</div>
            <div><i>We usually have a lot of homework. I spend a few hours a week doing it.</i></div>

            <div>
            <div><b>1 </b> the money you spend on clothes<br><textarea id="0" rows="2"></textarea></div>
            <div><b>2 </b> the different foods you eat<br><textarea id="0" rows="2"></textarea></div>
            <div><b>3 </b> the money that people earn in different jobs<br><textarea id="0" rows="2"></textarea></div>
            <div><b>4 </b> the things for teenagers to do in your area<br><textarea id="0" rows="2"></textarea></div>
            </div>
          </div>
        `,
        answer: [],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P27-E7",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page27/E7/Key/answerKey.png",
    // hideBtnFooter: true,
    titleQuestion: [
      {
        num: "7",
        title: `
          Choose the correct words.
				`,
        color: "rgb(78, 59, 151)",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          marginLeft: "10px",
          padding: "-30px",
          fontWeight: 100,
          fontStyle: "italic",
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `a_In_these&nbsp;&nbsp;&nbsp;&nbsp; b_In_those&nbsp;&nbsp;&nbsp;&nbsp; c_On_these&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_Such_as&nbsp;&nbsp;&nbsp;&nbsp; b_Like&nbsp;&nbsp;&nbsp;&nbsp; c_For_example&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_The_sixties_was_the_decade&nbsp;&nbsp;&nbsp;&nbsp; b_In_the_sixties&nbsp;&nbsp;&nbsp;&nbsp; c_These_days&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_like&nbsp;&nbsp;&nbsp;&nbsp; b_of&nbsp;&nbsp;&nbsp;&nbsp; c_as&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_At&nbsp;&nbsp;&nbsp;&nbsp; b_When&nbsp;&nbsp;&nbsp;&nbsp; c_For&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_On&nbsp;&nbsp;&nbsp;&nbsp; b_In&nbsp;&nbsp;&nbsp;&nbsp; c_At&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_decade&nbsp;&nbsp;&nbsp;&nbsp; b_times&nbsp;&nbsp;&nbsp;&nbsp; c_days&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_example&nbsp;&nbsp;&nbsp;&nbsp; b_such&nbsp;&nbsp;&nbsp;&nbsp; c_for_instance&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_such&nbsp;&nbsp;&nbsp;&nbsp; b_like&nbsp;&nbsp;&nbsp;&nbsp; c_instance&nbsp;&nbsp;&nbsp;&nbsp;`,
        ],
        answers: [
          "0-2",
          "1-4",
          "2-0",
          "3-4",
          "4-0",
          "5-2",
          "6-0",
          "7-4",
          "8-2",
        ],
        initialValue: [],
      },
      Layout: `
        <div style='position: relative; padding: 15px; border: 1px solid rgb(143, 144, 148); border-radius: 10px; box-shadow: 7px 7px 10px rgb(143, 144, 148);'>
          <div style='position: absolute; top: 90px; left: 421px;'><img src='img/FriendsPlus/Page27/E7/1.jpg' width='70%' /></div>
          <div style='font-size: 28px;'>Fact file: <strong>Flared trousers</strong></div>
          <div style='margin-top: 7px; display: flex; flex-direction: column; gap: 5px;'>
            <div style='width: 400px;'>
              <strong>The beginnings</strong>
              <div>
              American sailors were the first to 
              wear flared trousers in the 19th 
              century. <sup>1</sup>.......... days, trousers 
              with a wide leg below the knee were 
              practical for many reasons. 
              <sup>2</sup>.........., they were easy to roll 
              up when cleaning the ship or to take 
              off when they got wet.
              </div>
            </div>
            <div style='width: 700px;'>
              <strong>Craze of the 1960s and 1970s</strong>
              <div>
                <sup>3</sup>.......... when flared trousers 
                became popular. Many singers, 
                such <sup>4</sup>.......... Sonny and Cher, 
                wore them. <sup>5</sup>.......... that time,  
                young people made their own flared trousers because 
                shops weren’t selling them. <sup>6</sup>.......... the 
                seventies, you could buy them in almost every shop. 
                Hardly anyone chose a different style of trousers 
                during this <sup>7</sup>.......... .
              </div>
            </div>
            <div style='width: 700px;'>
              <strong>A comeback</strong>
              <div>
              There were a few times when flared trousers came 
              back, <sup>8</sup>.........., throughout the 1990s and in 
              2015. Although fashion designers, <sup>9</sup>.......... 
              Balmain and Gucci, presented them during fashion 
              shows, they didn’t become as popular as in the 1970s.
              </div>
            </div>
          </div>
        </div>

        <div style='margin-top: 20px; display: flex; gap: 20px; flex-direction: column;'>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>1</span>
            <div><input id='0' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>2</span>
            <div><input id='1' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>3</span>
            <div><input id='2' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>4</span>
            <div><input id='3' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>5</span>
            <div><input id='4' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>6</span>
            <div><input id='5' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>7</span>
            <div><input id='6' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>8</span>
            <div><input id='7' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>9</span>
            <div><input id='8' type='Circle' /></div>
          </div>
        </div>
			  `,
    },
  },
};

export default json;
