import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P28-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/key/E1answerkey.png",
    component: T6v2,
    inputSize: 160,
    stylesTextInput: {
      // borderStyle: 'dotted',
      fontSize: "25px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the sentences with adverbs formed from the nouns in brackets. 
				`,
        color: "rgb(0,0,0)",
      },
    ],
    // textareaStyle: {
    // 	width: 800
    // },
    checkUppercase: true,
    maxLength: 13,
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='padding:10px 10px;line-height:1.85'>
				<div style='display:flex'>
    			<b>1</b>
    			<div style='display:flex;margin-left:10px'>
          <span style='color:blue'>Angrily</span>, (anger) he threw his bag onto the floor. 
        
    			</div>
				</div>

				<div style='display:flex'>
    			<b>2</b>
    			<div style='display:flex;margin-left:10px'>
          ‘It’s broken,’ she said # (sadness).
          </div> 
        
    			</div>

				<div style='display:flex'>
    			<b>3</b>
    			<div style='display:flex;margin-left:10px'>
         # (happiness), everyone passed the exam.
          </div> 
        
    			</div>

				<div style='display:flex'>
    			<b>4</b>
    			<div style='display:flex;margin-left:10px'>
          We looked # (anxiety) at the screen.
          </div> 
        
    			</div>

				<div style='display:flex'>
    			<b>5</b>
    			<div style='display:flex;margin-left:10px'>
          He looked # (surprise) calm as the exam began.

				 </div> 
        
    			</div>

        <div style='display:flex'>
        <b>6</b>
        <div style='display:flex;margin-left:10px'>
        When she’d finished the painting, she # (pride) showed it to her friends.
        </div> 
      
        </div>

      <div style='display:flex'>
      <b>7</b>
      <div style='display:flex;margin-left:10px'>
      I left my bag in the café. # (hope), it’s still there!
      </div> 
    
    </div>

    <div style='display:flex'>
    <b>8</b>
    <div style='display:flex;margin-left:10px'>
    The police officer looked # (suspicion) at the men on the street corner.
    </div> 
  
  </div>

  <div style='display:flex'>
    <b>9</b>
    <div style='display:flex;margin-left:10px'>
    We stood outside the restaurant and stared # (hunger) at the menu in the window.
    </div> 
  
  </div>
				</div>


				
				
				
        `,
        answer: [
          `sadly`,
          `Happily`,
          `anxiously`,
          `surprisingly`,
          `proudly`,
          `Hopefully`,
          `suspiciously`,
          `hungrily`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P28-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/key/E2answerkey.png",
    component: T6v2,
    inputSize: 170,
    stylesTextInput: {
      // borderStyle: 'dotted',
      fontSize: "26px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Complete the sentences with adjectives formed from the words in brackets. 				`,
        color: "rgb(0,0,0)",
      },
    ],
    maxLength: 11,
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:2'>
        <div style='display:flex'>
    			<b>1</b>
    			<div style='margin-left:10px'>
          The leg injury was really # (pain) – in fact, I was # (surprise) it wasn’t broken.
        
    			</div>
				</div>

				<div style='display:flex'>
    			<b>2</b>
    			<div style='margin-left:10px'>
          The shop assistant was very # (help) and gave me a refund.
          </div> 
        
    			</div>

				<div style='display:flex'>
    			<b>3</b>
    			<div style='margin-left:10px'>
          I’m # (annoy) with my sister because she broke my sunglasses. She’s so # (care)!
          </div> 
        
    			</div>

				<div style='display:flex'>
    			<b>4</b>
    			<div style='margin-left:10px'>
          This TV has got really # (power) speakers, so action films are very loud and # (excite).
          </div> 
        
    			</div>

				<div style='display:flex'>
    			<b>5</b>
    			<div style='margin-left:10px'>
          I enjoyed the play, but some parts of it were # (bore).

				 </div> 
        
    			</div>

        <div style='display:flex'>
        <b>6</b>
        <div style='margin-left:10px'>
        I’ll never get these trainers clean. It’s # (hope)! They’re # (disgust)!
        </div> 
      
        </div>
        </div>

        `,
        answer: [
          `painful`,
          `surprised`,
          `helpful`,
          `annoyed`,
          `careless`,
          `powerful`,
          `exciting`,
          `boring`,
          `hopeless`,
          `disgusting`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P28-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/key/E3answerkey.png",
    component: T6v2,
    inputSize: 170,
    stylesTextInput: {
      fontSize: "23px",
      textAlign: "center",
      background: "none",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
<span style='color:rgb(142,33,137)'>USE OF ENGLISH</span> Complete the article with nouns, adjectives and adverbs formed from the words in brackets. Add prefixes and suffixes if necessary.`,
        color: "rgb(0,0,0)",
      },
    ],
    maxLength: 20,
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
       <div>
	 <div>
	 <img src='img/FriendsPlus/Page28/E3/1.jpg' width='700px';/>
	 </div>
	 <div style='font-size: 23px; width:700px;background-color:rgb(239,229,240);padding:10px 20px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;'>
	 Everybody knows that smiling is a sign of <br/> <sup>1</sup> # (happy) and that we frown when we are feeling <sup>2</sup> # (happy). 
	 Our feelings affect our face. But scientists now believe that our face can also affect our feelings. 
	 In other words, smiling can actually help to create a feeling of <sup>3</sup> # (content). And one of the best ways 
	 to prevent  <sup>4</sup># (anxious) is to control your facial expression. In one study, volunteers looked at very 
	 unpleasant pictures. Some of the volunteers held a pen in their mouth so their face could not move easily. 
	 Those volunteers did not feel as <sup>5</sup># (disgust) by the pictures as the others. Researchers also looked at 
	 women who were unable to frown because of botox injections. <br/> <sup>6</sup># (surprise), these women were less likely 
	 to suffer from <sup>7</sup># (depressed), even though they did not feel particularly  <sup>8</sup># (happiness) about the change 
	 in their appearance.
	 </div>  
	   </div>

        `,
        answer: [
          `happiness`,
          `unhappy`,
          `contentment`,
          `anxiety`,
          `disgusted`,
          `Surprisingly`,
          `depression`,
          `happy`,
        ],
      },
    ],
  },
};

export default json;
