import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P95-E1",
    audio: "",
    video: "",
    component: T6v2,
    // maxLength: 11,
    inputSize: 120,
    // textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page95/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",

        title: `
				Look at the photos and answer the questions.
Then read the article and check your answers.

				
									`,
        color: "#1dc1d8",
      },
    ],
    textareaStyle: { width: 800 },
    // stylesTextInput: {
    //   fontSize: 22,
    //   height: 24,
    //   background: "none",
    //   borderBottom: "none",
    //   color: "white",
    // },
    hintBox: [
      {
        src: [
          "alter",
          "crops",
          "diseases",
          "gene",
          "genetic engineering",
          "herbicides",
          "nutrients",
          "weeds",
        ],
        width: 500,
        borderColor: "#1dc1d8",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style='position: relative;margin-left:20px'>
				<img src="img/FriendsPlus/Page95/E1/1.jpg" />
				<div>
					<b>1.</b>&ensp;What job categories can you see?<br>
					<div style='display:flex;justify-content: space-around'>
						<div><b>A</b>&ensp;#</div>
						<div><b>B</b>&ensp;<input id='1' width='160px'></input></div>
						<div><b>C</b>&ensp;#</div>
						<div><b>D</b>&ensp;#</div>
					</div>
				</div>
					<b>2.</b>&ensp;Why do you think some teenagers work in
					their holidays? <br> <textarea id='4' rows='4'></textarea>
			</div>

			
									`,
        answer: ["teaching", "engineering", "media", "finance", ""],
      },
    ],
  },
  2: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P95-E2",
    audio: "Audios/3.07.mp3",
    video: "",
    component: T6v2,
    // maxLength: 13,
    inputSize: 540,
    // textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page95/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",

        title: `
				<headphone name="3.07"></headphone >
				Read and listen to the article again
and answer the questions.
				
									`,
        color: "#1dc1d8",
      },
    ],
    textareaStyle: { width: 700 },
    questionImage: [],
    // hideBtnFooter: true,
    // isHiddenCheck: true,
    questions: [
      {
        title: `
						
								<div><b>1</b>&ensp;What time of year do most teenagers do
								work experience in the UK?<br>#</div>
								<div><b>2</b>&ensp;Why does the writer think work experience
								is useful? <br><textarea id='3' rows='2'></textarea></div>
								<div><b>3</b>&ensp;How long are placements?
								<br>#</div>
								<div><b>4</b>&ensp;What does Paul want to do when he
								finishes school?
								 <br>#</div>
							<img src='img/FriendsPlus/Page95/E2/1.jpg'>

            
			
									`,
        answer: [
          "summer",
          "two weeks",
          "Go back to work on the farm.",
          "Because it’s a useful way to find out what jobs you might want to do in the future. It can also help you to get a job.",
        ],
      },
    ],
  },
  3: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P95-E3",
    audio: "",
    video: "",
    // recorder: true,
    component: UI,
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: `Work in pairs and
				answer the questions.`,
        color: "#1dc1d8",
        prefix: { icons: ["far fa-comment"], text: "YOUR CULTURE" },
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page95/E3/1.jpg",
        },
      ],
    ],
  },
  4: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P95-E4",
    audio: "",
    video: "",
    component: UI,
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Imagine your country has a new
				rule: All fourteen-year-olds must do work
				experience in the school holidays. Prepare a
				class debate. Follow instructions 1–3.`,
        color: "#1dc1d8",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page95/E4/1.jpg",
        },
      ],
    ],
  },
  5: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P95-E5",
    audio: "",
    video: "",
    component: T6,
    textareaStyle: { width: 740 },
    titleQuestion: [
      {
        num: "5",
        title: `Work in pairs. Create a poster
        to show what happens in the water cycle.<br>
        Include information from this page.`,
        color: "#1dc1d8",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questions: [
      {
        title: `
          
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
