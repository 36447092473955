import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P56-E1",
    // audio: "Audios/2.08.mp3",
    video: "",
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page56/key/E1answerKey.png",
    component: T6v2,
    isHiddenCheck: true,
    // hideBtnFooter:true,
    stylesTextInput: {},
    titleQuestion: [
      {
        num: "1",
        title: `
        Complete the art forms.
						`,
        color: "black",
        // prefix: { text: "Speaking" },
      },
    ],
    questions: [
      {
        title: `
        <div >
        <img src="img/FriendsPlus/Page56/E1/1.jpg" width='90%' />
        
        </div >
					<div style='line-height:2.3;display:flex;margin-top:20px'>
            <div>
            <div><b>1.</b>&ensp; b#</div>

            <div><b>2.</b>&ensp; c# m#</div>

            <div><b>3.</b>&ensp; d#</div>

            <div><b>4.</b>&ensp; m#</div>

            <div><b>5.</b>&ensp; p#</div>

            <div><b>6.</b>&ensp; c#</div>

            <div><b>7.</b>&ensp; d#</div>

            <div><b>8.</b>&ensp; m#</div>

            <div><b>9.</b>&ensp; n#</div>
            </div>
            <div style='margin-left: 100px'>
              <div><b>10.</b>&ensp; o#</div>

              <div><b>11.</b>&ensp; p#</div>

              <div><b>12.</b>&ensp; p#</div>

              <div><b>13.</b>&ensp; p# m#</div>

              <div><b>14.</b>&ensp; s#</div>

              <div><b>15.</b>&ensp; s#</div>

            </div>
          
          </div>
											`,
        answer: [
          `allet`,
          `lassical`,
          `usic`,
          `ance`,
          `ime`,
          `oem`,
          `artoon`,
          `rawing`,
          `usical`,
          `ovel`,
          `pera`,
          `ainting`,
          `lay`,
          `op`,
          `usic`,
          `culpture`,
          `itcom`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P56-E2",
    // audio: "Audios/2.08.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page56/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 280,
    textareaStyle: { width: "500px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Complete the sentences with types of artist.
											`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='line-height:2.3'>
            <div><b>1.</b>&ensp; <span style='color:rgb(123,155,164)'><i>A playwright</i></span> writes plays.</div>

            <div><b>2.</b>&ensp; # choreographs dances.</div>

            <div><b>3.</b>&ensp; # performs in operas.</div>

            <div><b>4.</b>&ensp; # directs films and plays.</div>

            <div><b>5.</b>&ensp; #  sings in a band.</div>

            <div><b>6.</b>&ensp; # conducts an orchestra.</div>

            <div><b>7.</b>&ensp; #  paints pictures.</div>

            <div><b>8.</b>&ensp; # acts in plays and films.</div>

            <div><b>9.</b>&ensp; # writes novels.</div>

            <div style='margin-left:-13px'><b>10.</b>&ensp; # carves statues and creates sculptures.</div>
          
          
          </div>
											`,
        answer: [
          `A choreographer`,
          `An opera singer`,
          `A director`,
          `A pop singer`,
          `A conductor`,
          `A painter`,
          `An actor`,
          `A novelist`,
          `A sculptor`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P56-E3",
    audio: "Audios/2.02 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page56/key/E3answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 150,
    stylesTextInput: {
      // borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        <headphone name='2.02' src='Audios/2.02 tieu de.mp3'></headphone>&ensp;Listen to three speakers talking about art forms that they study. <br>Match speakers 1–3 with sentences A–D. There is one extra sentence.
        `,
        color: "black",
      },
    ],
    selectStyle: {
      border: "solid 1px black",
      marginLeft: 10,
      height: 34,
      width: 37,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: [`_`, `1`, `2`, `3`],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2.3'>
        <div><b>Which speaker …</b></div> 

      <div style='display:flex'><b>A</b>&ensp; already earns some money from his / her artistic skills? &ensp;<select id=0></select></div>

      <div style='display:flex'><b>B</b>&ensp; describes an old and a new artistic skill that have a connection with each other? &ensp;<select id=1></select></div>

      <div style='display:flex'><b>C</b>&ensp; wants to experiment with different performance ideas in his / her chosen art form? &ensp;<select id=2></select></div>

      <div style='display:flex'><b>D</b>&ensp; says that his / her art form is not appreciated by many people? &ensp;<select id=3></select></div>


      </div>
        `,
        answer: [`3`, `2`, `1`, `_`],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P56-E4",
    audio: "Audios/2.02 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page56/key/E4answerKey.png",
    component: T6v2,
    maxLength: 13,
    inputSize: 200,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "23px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        <headphone name='2.02' src='Audios/2.02 tieu de.mp3'></headphone>&ensp;Complete the sentences with the verbs below. Then listen again and check.
        `,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:60px'>
      <div style='color: rgb(146,39,143);font-weight:bold';>
      carving&ensp;&ensp;&ensp;choreograph&ensp;&ensp;&ensp;compose&ensp;&ensp;&ensp;create&ensp;&ensp;&ensp;perform&ensp;&ensp;&ensp;sing
      </div>
        <div><b>Speaker 1</b></div> 

      <div>And I would like to # ballets as well as # them.</div>

      <div><b>Speaker 2</b></div>

      <div><b>a</b>&ensp; Firstly, I love # sculptures out of wood.</div>

      <div><b>b</b>&ensp; I’m also fascinated by 3D modelling, where you # 3D models.</div>

      <div><b>Speaker 3</b></div>

      <div><b>a</b>&ensp; I # in two choirs.</div>

      <div><b>b</b>&ensp; One day, I’d also like to # my own songs.</div> 


      </div>
        `,
        answer: [
          `choreograph`,
          `perform`,
          `carving`,
          `create`,
          `sing`,
          `compose`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P56-E5",
    audio: "Audios/1-21 Friends Plus 8_WB.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page56/key/E5answerKey.png",
    component: Circle_Write,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        <headphone name="21"></headphone >&ensp; Listen again and choose the correct words.
        `,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `teenagers_and_adults / restaurant_cooks.`, //1
          `smell / taste.`, //1
          `delicious / disgusting.`, //1
          `cooks / students`, //1
          `flavour / texture`, //1
          `don’t_pay / pay`, //1
        ],
        answers: ["0-0", `1-4`, `2-4`, `3-0`, `4-0`, `5-0`],
        initialValue: [],
      },
      Layout: `
      
        
      
        <div style='line-height:2'>
          
          <div><b>1</b>&ensp; Daniel Nold organises cookery courses for <input id='0' type='Circle'/></div>

          <div><b>2</b>&ensp; Daniel’s book will be about <input id='1' type='Circle'/></div>
          
          <div><b>3</b>&ensp; People sometimes say their food is great or <input id='2' type='Circle'/></div>
          
          <div><b>4</b>&ensp; Daniel and his <input id='3' type='Circle'/> organise taste sessions in his restaurant.</div>

          <div><b>5</b>&ensp; Learning about <input id='4' type='Circle'/>&ensp; helps people to cook better.</div>

          <div><b>6</b>&ensp; You <input id='5' type='Circle'/>&ensp; for Daniel’s sessions</div>
    
        </div>
      `,
    },
  },
  6: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P56-E6",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page56/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 150,
    textareaStyle: { width: "500px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "20px",
      textAlign: "center",
      fontWeight: "bold",
      // border:'dotted',
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        A TASK Write a competition entry about a course you have done.
        `,
        color: "black",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2'>
        <div>
        <b>B THINK AND PLAN</b>
        <div><b>1</b>&ensp; What kind of course was it? Where was it? Who was it for?</div>
        <div><b>2</b>&ensp; What did you learn there? What were the classes like?</div>
        <div><b>3</b>&ensp; What made this course special?</div>
        </div>

        <div>
        <b>C WRITE Use the text in exercise 2 and the writing guide.</b>
        <div style='display:flex  '>
        <img src="img/FriendsPlus/Page56/E6/1.jpg" style='width:45%' />
          <div style='margin-left:15px'>
          <i>ANSWER:</i> 
          <textarea id='0' rows='5'></textarea></div>
        </div>
        
        </div>
      
  
  


      </div>
        `,
        answer: [
          `amazing`,
          `breakfast`,
          `breakfast`,
          `baggy`,
          `patterned`,
          `delicious`,
        ],
      },
    ],
  },
};

export default json;
