import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "FGF11-U1-P8-E1",
    audio: "",
    video: "",
    component: T6,
    inputSize: 200,
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 50,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page8/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				<span style='font-size: 24px'>Label the pictures with the life stages below.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 20,
      height: 27,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: [
          "centenarian",
          "elderly",
          "infant",
          "in his / her teens ",
          "in his / her twenties",
          "middle-aged",
          "toddler",
          "young child",
        ],
        borderColor: "none",
        width: 900,
        fontColor: "#93338e",
        fontSize: 18,
      },
    ],
    questions: [
      {
        title: `
				<hintbox id=0></hintbox>
        <div style=" position: relative; padding-top:10px; font-size: 19px; width: max-content">
          <div><img style="width: 25cm" src='img/FriendsPlus/Page8/E1/1.jpg';/></div>

          <div style=" position: absolute; top: 237px; left: -17px"><b>1 </b>She’s a <input id='0' width='125px'></binput></div>
          <div style=" position: absolute; top: 237px; left: 236px"><b>2 </b>He’s<input id='1' width='145px'></input></div>
          <div style=" position: absolute; top: 237px; left: 465px"><b>5 </b><input id='2' ></input></div>
          <div style=" position: absolute; top: 237px; left: 720px"><b>6 </b><input id='3' ></input></div>
          
          <div style=" position: absolute; top: 492px; left: -22px"><b>3 </b><input id='4' ></binput></div>
          <div style=" position: absolute; top: 492px; left: 217px"><b>4 </b><input id='5' ></input></div>
          <div style=" position: absolute; top: 492px; left: 450px"><b>7 </b><input id='6' width='240px' ></input></div>
          <div style=" position: absolute; top: 492px; left: 721px; display: flex"><b>8 </b><input id='7' width='230px' ></input></div>

        </div>

				`,
        answer: [
          `toddler`,
          `in his teens`,
          `He's a centenarian.`,
          `She's elderly.`,
          `She's middle-aged.`,
          `He's an infant./ She's an infant.`,
          `He's a young child./ She's a young child.`,
          `He's in his twenties.`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "FGF11-U1-P8-E2",
    audio: "",
    video: "",
    component: T6,
    inputSize: 220,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 30,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page8/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the life events with the words below. Use all the words.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: [
          "be",
          "be",
          "emigrate",
          "fall",
          "get",
          "get",
          "go",
          "inherit",
          "learn",
          "move",
          "split",
          "start",
        ],
        borderColor: "none",
        width: 900,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
				<div style="display: flex; font-size: 22px">
					<div style="display: flex; margin-top: 10px; margin-right: 40px">
						<div style='margin-right: 30px'>
							<div><b>1 </b>#&ensp; </div>
							<div><b>2 </b>#&ensp; </div>
							<div><b>3 </b>#&ensp; </div>
							<div><b>4 </b>#&ensp; </div>
							<div><b>5 </b>#&ensp; </div>
							<div><b>6 </b>#&ensp; </div>
						</div>
			
						<div>
							<span>born</span><br>
							<span> a business</span><br>
							<span>in love</span><br>
							<span>(money, etc.)</span><br>
							<span>to drive</span><br>
							<span>up</span><br>
						</div>
					</div>

					<div style="display: flex; margin-top: 10px">
						<div style='margin-right 30px'>
							<div><b>7 </b>#&ensp; </div>
							<div><b>8 </b>#&ensp; </div>
							<div><b>9 </b>#&ensp; </div>
							<div><b>10 </b># </div>
							<div><b>11 </b># </div>
							<div><b>12 </b># </div>
						</div>
			
						<div>
							<span>to university</span><br>
							<span>house</span><br>
							<span>your first job</span><br>
							<span>engaged</span><br>
							<span>brought up (by)</span><br>
							<span>from abroad</span><br>
						</div>
					</div>
				</div>
        <div style='color: rgb(147,51,142); text-align:center; margin-top:30px'>
				away&ensp; a change of career&ensp; divorced&ensp; down&ensp; 
				a family&ensp; from work&ensp; a grandparent&ensp;<br> home&ensp; a house or flat&ensp; 
				married&ensp; school&ensp; school&ensp; up
				</div>
				<div style="display: flex; font-size: 22px">
					<div style="display: flex; margin-top: 10px; margin-right: 40px">
						<div style='margin-right: 30px'>
							<div><b>13 </b>get&ensp; </div>
							<div><b>14 </b>leave&ensp; </div>
							<div><b>15 </b>start&ensp; </div>
							<div><b>16 </b>settle&ensp; </div>
							<div><b>17 </b>leave&ensp; </div>
							<div><b>18 </b>start&ensp; </div>
							<div><b>19 </b>have&ensp; </div>
						</div>
			
						<div>
							<span>#</span><br>
							<span>#</span><br>
							<span>#</span><br>
							<span>#</span><br>
							<span>#</span><br>
							<span>#</span><br>
							<span>#</span>
						</div>
					</div>
					<div></div>
					<div style="display: flex; margin-top: 10px; margin-left: 70px">
						<div style='margin-right: 30px'>
							<div><b>20 </b>become </div>
							<div><b>21 </b>grow </div>
							<div><b>22 </b>pass </div>
							<div><b>23  </b>buy </div>
							<div><b>24  </b>get </div>
							<div><b>25  </b>retire </div>
						</div>
			
						<div>
							<span>#</span><br>
							<span>#</span><br>
							<span>#</span><br>
							<span>#</span><br>
							<span>#</span><br>
							<span>#</span><br>
						</div>
					</div>
				</div>
				`,
        answer: [
          `be`,
          `start`,
          `fall`,
          `inherit`,
          `learn`,
          `split`,
          `go`,
          `move`,
          `get`,
          `get`,
          `be`,
          `emigrate`,

          `married`,
          `home / school`,
          `a family / school`,
          `down`,
          `school / home`,
          `school / a family`,
          `a change of career`,
          `a grandparent`,
          `up`,
          `away`,
          `a house or flat`,
          `divorced`,
          `from work`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "FGF11-U1-P8-E3",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 100,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page8/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `<span style='font-size: 24px'>Write six true sentences about you or members of your family 
				using different phrases from exercise 2.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
      width: 900,
    },
    questions: [
      {
        title: `
				<img style="width:22cm; height:15cm" src='img/FriendsPlus/Page8/E3/1.png' />
        <div style="margin-top: 30px; font-size: 23px">
					<div style='color: rgb(152,198,204); '>My grandad retired when he was in his sixties.</div>
          <div><b>1&ensp;#</div>
					<div><b>2&ensp;#</div>
					<div><b>3&ensp;#</div>
					<div><b>4&ensp;#</div>
					<div><b>5&ensp;#</div>
					<div><b>6&ensp;#</div>
          
        </div>

				`,
        answer: [``, ``, ``, ``, ``, ``],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "FGF11-U1-P8-E4",
    audio: "Audios/1.02 audio.mp3",
    video: "",
    component: T6,
    inputSize: 400,
    isHiddenCheck: true,
    maxLength: 20,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page8/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `
				<span style='font-size: 24px'><headphone name='1.02' src='Audios/1.02 tieu de.mp3'></headphone> Listen to a teenage girl asking people in the street 
				about the best age to do certain things in life. Complete the table.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
				
        <div style=" position: relative; padding-top:10px; font-size: 23px; width: max-content; margin-left: 200px">
          <div><img style="width: 17cm" src='img/FriendsPlus/Page8/E4/1.jpg';/></div>

          <div style=" position: absolute; top: 63px; left: 192px">#</div>
          <div style=" position: absolute; top: 147px; left: 192px">#</div>
          <div style=" position: absolute; top: 236px; left: 192px">#</div>
          <div style=" position: absolute; top: 319px; left: 192px">#</div>

        </div>

				`,
        answer: [`late twenties`, `early twenties`, `30-35`, `30 (or so) / 30`],
      },
    ],
  },
};

export default json;
