import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 7",
    id: "FGF-WB-demo-2023-U7-P62-E1",
    // audio: "Audios/2.13.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page62/key/E1answerKey.png",
    component: T6,
    maxLength: 23,
    inputSize: 300,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
      // borderBottom: 'dotted',
    },
    titleQuestion: [
      {
        num: "1",

        title: `
        Look at photos A and B. What types of show are they? Match them with two of <br>the types of show below
									`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <img src="/img/FriendsPlus/Page62/E1/1.jpg" style='margin-left:150px'  >
      <div style='display:flex'>
       <div>
         <span style='color:rgb(148,37,148);font-weight:bold'>an art exhibition a circus a classical concert <br>
         a comedy club a magic show a musical <br>
         an open-air theatre a piano recital</span>
       </div>
       <div style='display:flex;margin-left:35px;margin-top:15px'>
       <div><u><i><b>Answer:</b></i></u></div>
       <div style='margin-left:50px'><span><i>A:#</i></span> <br>
       <span><i>B:#</i></span></div>
       </div>
      </div>

        

      </div>
									`,
        answer: [`a classical concert`, `a circus`],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P62-E2",
    // audio: "Audios/2.14.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page62/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 170,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    textareaStyle: { width: 700, paddingTop: 4, height: 100 },
    titleQuestion: [
      {
        num: "2",

        title: `
        Read the task. Then complete the sentences in your own words to compare <br>the photos in exercise 1. Write one 
          additional sentence to say which show <br>you think is more appealing.

									`,
        color: "black",
      },
    ],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <img src="/img/FriendsPlus/Page62/E2/2.jpg" style='margin-left:150px'  >
      <div >
       <div><b>1</b>&ensp;Both photos show <textarea id='0' rows='2'></textarea></div>

       <div><b>2</b>&ensp;Whereas photo B shows acrobats, photo A<textarea id='1' rows='2'></textarea></div>

       <div><b>3</b>&ensp;Unlike photo A, in photo B the audience are sitting <textarea id='2' rows='2'></textarea></div>

       <div style='display:flex'><b>4</b>&ensp;<textarea id='3' rows='2'></textarea></div>
      </div>

        

      </div>
									`,
        answer: [],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P62-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page62/key/E3answerKey.png",
    component: T6v2,
    maxLength: 14,
    inputSize: 200,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "25px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Read <span style='color:rgb(0,147,69)'>Speaking Strategy 1</span>. Then complete the phrases with the words below.
									`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <img src="/img/FriendsPlus/Page62/E3/1.jpg" style='margin-left:100px;width:50%'  >
      <div style='line-height:2.5'>
        <div>
        <span style='color:rgb(148,37,148);font-weight:bold'>absolutely&ensp; do&ensp; fan&ensp; keen&ensp; much&ensp; quite&ensp; stand&ensp; thing</span>
        </div>

        <div>
          <b>1.</b>&ensp; I’m (not) a big # of … <br>
        </div>

        <div>
          <b>2.</b>&ensp; I’m # into … / I’m not really into … <br>
        </div>

        <div>
          <b>3.</b>&ensp; I’m really / I’ve never been that # on … <br>
        </div>

        <div>
          <b>4.</b>&ensp; I # love … <br>
        </div>

        <div>
        <b>5.</b>&ensp; I really can’t # <br>
      </div>

      <div>
      <b>6.</b>&ensp; I enjoy … very # 
    </div>

    <div>
      <b>7.</b>&ensp; … is not really my #
    </div>

    <div>
      <b>8.</b>&ensp; … doesn’t # anything for me. 
    </div>

      </div>
									`,
        answer: [
          `fan`,
          `quite`,
          `keen`,
          `absolutely`,
          `stand`,
          `much`,
          `thing`,
          `do`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P62-E4",
    // audio: "Audios/2.15.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page62/key/E4answerKey.png",
    component: T6v2,
    maxLength: 9,
    inputSize: 150,
    stylesTextInput: {
      background: "transparent",
      //   border: 'none',
      borderBottom: "1px solid black",
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
        Read <span style='color:rgb(0,147,69)'>Speaking Strategy 2</span>. Complete the phrases with the words below.
									`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
    <div style='line-height:2'>
    <div>
    <span style='color:rgb(148,37,148);font-weight:bold'>afraid&ensp; be&ensp; frank&ensp; I’m&ensp; say</span>
    </div>
    <div style='background: rgb(229,239,230); width:100%; margin-top:20px; border-radius:20px; border:5px dotted rgb(0,146,58) '>
        <div style='width:693px;line-height:2; margin:auto;padding:20px'>
        <div style='color:rgb(0,146,58);font-size:30px'><b>Speaking Strategy 2</b></div>
        We often use one of the phrases below to introduce a preference, 
        particularly when it is negative. <br>I’m <sup><b>1</b></sup> # … I must <sup><b>2</b></sup>#  … 
        <br>To be <sup><b>3</b></sup># … If <sup><b>4</b></sup># honest, … <br> To <sup><b>5</b></sup># blunt, … Personally, …
        </div>
      </div>
    </div>
		
	
			
								`,
        answer: [`afraid`, `say`, `frank`, `I'm`, `be`],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P62-E5",
    // audio: "Audios/2.15.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page62/key/E5answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px", paddingTop: 4 },
    // maxLength: 6,
    inputSize: 830,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        Write two sentences about something you dislike (a type 
          of music, sport, show, etc.). Use one phrase from exercise 3 
          and one phrase from <span style='color:rgb(0,146,58);'>Speaking Strategy 2</span>.
									`,
        color: "black",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
    <div style='line-height:2.5'>
    <div>
        <span style='color:rgb(148,37,148);font-weight:bold'>absolutely&ensp; do&ensp; fan&ensp; keen&ensp; much&ensp; quite&ensp; stand&ensp; thing</span>
    </div>
    <div>
    <span style='color:rgb(148,37,148);font-weight:bold'>afraid&ensp; be&ensp; frank&ensp; I’m&ensp; say</span>
    </div>
    <div>
        <div style='display:flex'><b>1.</b> <textarea id='0' rows='2'></textarea></div>
        <div style='display:flex'><b>2.</b> <textarea id='1' rows='2'></textarea></div>
    </div>
    </div>
		
	
			
								`,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P62-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    textareaStyle: { width: 800 },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        You are going to do the task in exercise 2. Look at photos C and D below.

        `,
        color: "black",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    selectStyle: {
      border: "solid 1px black",
      marginLeft: 10,
      height: 34,
      width: 37,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["✓", "_"],
    questions: [
      {
        title: `
     
     
     <div style='position: relative;'>
      
       <div style='display:flex'>
        <b>Your choice:</b>&ensp;C:<select id=0></select>&ensp;&ensp; D:<select id=1>
       </div>
       
     </div>
     <img src="img/FriendsPlus/Page62/E4/1.jpg"/>
     <div>
        <div>
          <div>Why this one? <br> <textarea id='2' rows='2'></textarea></div>
          <div>Why not the other? <br> <textarea id='3' rows='2'></textarea></div>
        </div>
     
     </div>
     
     `,
        answer: [],
      },
    ],
  },
  7: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P62-E7",
    audio: "",
    video: "",
    recorder: true,
    // exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 70,
    },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "7",
        title: `
        Now do the speaking task comparing photos C and D. <br>Use your notes from exercises 5 and 6.

        `,
        color: "black",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
     <img src="img/FriendsPlus/Page62/E4/1.jpg"/>
     <textarea id='0' rows='7'>
     
     `,
        answer: [],
      },
    ],
  },
};

export default json;
