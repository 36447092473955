import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P43-E1",
    exerciseKey: "img/FriendsPlus/Page43/Key/E1answerKey.png",
    audio: "",
    video: "",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
        Circle the correct modals to complete the sentences. 
					`,
        color: "#000",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "3px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: [
          `must / have_to / has_to`, // 0
          `mustn’t / have_to / don’t_have_to`, // 1
          `can’t_have / may_have / must_have`, // 2
          `can’t_have / could_have / must_have`, // 3
          `mustn’t / must / don’t_have_to`, // 4,
          `can’t_have / might_have / must_have`,
          `mustn’t / can’t_have_to / must`,
          `can’t_have / might_have / must_have`,
        ],
        answers: ["0-4", "1-8", "2-0", "3-4", "4-4", "5-8", "6-0", "7-0"],
        initialValue: {},
      },
      Layout: `
    <div style='line-height:2'>
    <div style='width: 1200px;margin: 10px 10px;'>
    <span style="font-weight: bold;">1. </span>
    All students <input id='0' type='Circle' /> post their assignments on the online discussion forum today.
  </div>

  <div style='width: 1200px;margin: 10px 10px;'>
    <span style="font-weight: bold;">2. </span>
    There’s still some  pizza. We <input id='1' type='Circle' /> buy more. 
  </div>

  <div style='width: 1200px;margin: 10px 10px;'>
    <span style="font-weight: bold;">3. </span>
    Juliet <input id='2' type='Circle' />  gone on holiday. She was at school yesterday! 
  </div>

  <div style='width: 1200px;margin: 10px 10px;'>
    <span style="font-weight: bold;">4. </span>
    I didn’t get your email. Do you think you <input id='3' type='Circle' /> sent it to my old email address? 
  </div>

  <div style='width: 1200px;margin: 10px 10px;'>
    <span style="font-weight: bold;">5. </span>
    Your smartwatch probably is dead again. You <input id='4' type='Circle' /> let the batteries charge fully before using. 
  </div>

  <div style='width: 1200px;margin: 10px 10px;'>
    <span style="font-weight: bold;">6. </span>
    The school show is on YouTube. Our teacher <input id='5' type='Circle' /> uploaded it. 
  </div>

  <div style='width: 1200px;margin: 10px 10px;'>
    <span style="font-weight: bold;">7. </span>
    You are going 130 km in less than an hour. You <input id='6' type='Circle' /> drive very fast! 
  </div>

  <div style='width: 1200px;margin: 10px 10px;'>
    <span style="font-weight: bold;">8. </span>
    Max isn’t replying to my texts. He <input id='7' type='Circle' /> taken his phone with him.
  </div>
    </div>
			
				`,
    },
  },
  2: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P43-E2",
    exerciseKey: "img/FriendsPlus/Page43/Key/E2answerKey.png",
    component: T6v2,
    inputSize: 300,
    stylesTextInput: {
      fontSize: "23px",
      textAlign: "center",
    },
    isHiddenCheck: true,
    maxLength: 30,
    titleQuestion: [
      {
        num: "2",
        title: `
        Complete the sentences with <i>must have</i> or <i>can't have</i> and the verbs in brackets. 
				`,
        color: "#000000",
      },
    ],

    questions: [
      {
        title: `
      <div style='line-height:2'>
      <div style='display:flex'>
      <b>1</b>
      <div style='margin-left:10px'>
      Jack is usually here by now. He # (miss) his bus. 
      </div>
      </div>

      <div style='display:flex'>
      <b>2</b>
      <div style='margin-left:10px'>
      Madison didn’t know about the party. She # (get) your email. 
      </div>
      </div>

      <div style='display:flex'>
      <b>3</b>
      <div style='margin-left:10px'>
      They’ve left school already. Their lessons # (finish) early today.
      </div>
      </div>

      <div style='display:flex'>
      <b>4</b>
      <div style='margin-left:10px'>
      He # (injure) his leg really badly. He cycled home!

      </div>
      </div>
      </div>

       
        `,
        answer: [
          `must have missed`,
          `can't have got`,
          `must have finished`,
          `can't have injured`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P43-E3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 650,
    stylesTextInput: {
      fontSize: "23px",
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Write the correct replies a–d after sentences 1–4. Use <i>should/ shouldn't have</i> and the past participle form of the verbs in brackets.
              `,
        color: "#000000",
      },
    ],
    textareaStyle: { width: 500 },
    // maxLength:7,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        ‘My camcorder screen is damaged.’ <br/>
        ‘#’ 
        </div>
        </div>

        <div style='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        ‘I think we’re driving in the wrong direction.’ <br/>
        ‘#’ 
        </div>
        </div>

        <div style='display:flex'>
        <b>3</b>
        <div style='margin-left:10px'>
        ‘My email account has been hacked again.’  <br/>
        ‘#’ 
        </div>
        </div>

        <div style='display:flex'>
        <b>4</b>
        <div style='margin-left:10px'>
        ‘My tablet is completely dead.’  <br/>
        ‘#’ 
        </div>
        </div>

        <div style='margin-top:10px;line-height:2'>
        <b>a</b>&ensp;We (bring) the satnav. <br/>
        <b>b</b>&ensp;You (choose) a better password.  <br/>
        <b>c</b>&ensp;You (charge) it overnight.  <br/>
        <b>d</b>&ensp;You (drop) it on the pavement.
        <br/>

        </div>
        
        `,
        answer: [
          `You shouldn't have dropped it on the pavement.`,
          `We should have brought the satnav.`,
          `You should have chosen a better password.`,
          `You should have charged it overnight.`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P43-E4",
    exerciseKey: "img/FriendsPlus/Page43/Key/E4answerKey.png",
    component: T6v2,
    inputSize: 350,
    stylesTextInput: {
      fontSize: "22px",
      textAlign: "center",
    },
    isHiddenCheck: true,
    // maxLength: 8,
    // hideBtnFooter: true,
    // textareaStyle: { width: 820 },
    titleQuestion: [
      {
        num: "4",
        title: `
				Complete the dialogue with the modals below. You can use the same modal more than once.
				`,
        color: "#000000",
      },
    ],
    checkUppercase: true,
    questions: [
      {
        title: `
        <div style="width:max-content;font-size:24px;font-weight:bold;color:rgb(142,15,177);">
        <span>can't have&ensp;&ensp;</span>
        <span> may / might / could have&ensp;&ensp;</span>
        <span>might not have&ensp;&ensp;</span>
        <span>must have&ensp;&ensp;</span>
        </div>

        <div style='display:flex; line-height:40px; font-size: 22px'>
        <b>
        Erica <br/>
        Jack <br/>
        Erica <br/>
        Jack <br/> <br/>
        Erica <br/>
        Jack <br/>
        Erica <br/>
        Jack <br/>
        Erica <br/>
        Jack <br/>
        Erica <br/>
        Jack <br/>
        Erica <br/>
        Jack 
        </b>
        <div style='margin-left:10px; width: max-content'>
        <div>
        What’s the matter, Jack? You look worried. 
        </div>
        <div>
        I can’t find my phone. I <sup>1</sup> # left it somewhere.         </div>
        <div>
        Did you have it on the bus?         </div>
        <div>
        Yes, I did. But I <sup>2</sup> # left it there because I used it just after I got off. <br/> I sent you a text.         </div>
        <div>
        You <sup>3</sup> # dropped it when you were walking here from the bus stop.         </div>
        <div>
        Yes, that’s possible. I was listening to my MP3 player.         </div>
        <div>
        Somebody <sup>4</sup> # found it by now and handed it in to the police.         </div>
        <div>
        Or they <sup>5</sup> # handed it in. They <sup>6</sup> # kept it.         </div>
        <div>
        Shall I phone the police station and ask?         </div>
        <div>
        Yes, OK. But why don’t you call my phone number first?         </div>
        <div>
        OK, I will. I can hear your phone ringing.        </div>
        <div>
        Me too. I <sup>7</sup> # dropped it in the street.         </div>
        <div>
        No. You <sup>8</sup> # put it down somewhere in the house.         </div>
        <div>
        That's a relief! But where is it?        </div>
        </div>
        
        </div>
        `,

        answer: [
          `must have`,
          `can't have`,
          `may | might | could have`,
          `may | might | could have`,
          `might not have`,
          `may | might | could have`,
          `can't have`,
          `must have`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P43-E5",
    // exerciseKey: 'img/FriendsPlus/Page43/Key/E4answerKey.png',
    component: T6v2,
    inputSize: 750,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
    },
    isHiddenCheck: true,
    // maxLength: 8,
    hideBtnFooter: true,
    // textareaStyle: { width: 820 },
    titleQuestion: [
      {
        num: "5",
        title: `
        Write replies for the sentences. Use the words in brackets. 				`,
        color: "#000000",
      },
    ],
    checkUppercase: true,
    questions: [
      {
        title: `
       <div style='display:flex'>
       <b>1</b>
       <div style='margin-left:10px'>
       ‘Ellie has got a really expensive new phone.’ (must have) <br/>
       <input/>
       </div>
       </div>

       <div style='display:flex'>
       <b>2</b>
       <div style='margin-left:10px'>
       ‘I failed my science exam.’ (can’t have)  <br/>
       <input/>
       </div>
       </div>

       <div style='display:flex'>
       <b>3</b>
       <div style='margin-left:10px'>
       ‘I’ve deleted all my photos by mistake.’ (should have)  <br/>
       <input/>
       </div>
       </div>

       <div style='display:flex'>
       <b>4</b>
       <div style='margin-left:10px'>
       ‘I haven’t got enough money for my bus ticket.’ (shouldn’t have)  <br/>
       <input/>
       </div>
       </div>

       <div style='display:flex'>
       <b>5</b>
       <div style='margin-left:10px'>
       ‘I was hungry all morning at school.’  (can’t have)   <br/>
       <input/>
       </div>
       </div>

       <div style='display:flex'>
       <b>6</b>
       <div style='margin-left:10px'>
       ‘Molly seemed really disappointed with her exam result.’  (might have)   <br/>
       <input/>
       </div>
       </div>
        `,

        answer: [`Few`, `little`, `a little`, `a few`, `few`, `little`],
      },
    ],
  },
};

export default json;
