import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P38-E1",
    exerciseKey: "img/FriendsPlus/Page38/Key/E1answerKey.png",
    audio: "Audios/1.19 audio.mp3",

    component: T6v2,
    inputSize: 100,
    stylesTextInput: {
      // background: 'transparent',
      fontSize: "26px",
      textAlign: "center",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        <headphone name='1.19' src='Audios/1.19 tieu de.mp3'></headphone> Read the task above. Then listen to a student doing the task comparing photos A and B. Which house did he choose? 
				`,
        color: "#000000",
      },
    ],
    maxLength: 1,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='display:flex'>
          <div style='flex:1'>
          <img src='img/FriendsPlus/Page38/E1/1.jpg'/>
          </div>
          <div style='flex:1;margin-left:10px'>
          <b>Which house did he choose? </b> <br/>
          <b><u><i>Answer:</i></u></b>&ensp;<input/>
          </div>
          
          </div>
        `,
        answer: [`B`],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P38-E2",
    exerciseKey: "img/FriendsPlus/Page38/Key/E2answerKey.png",
    component: T6v2,
    inputSize: 220,
    stylesTextInput: {
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
      fontWeight: "bold",
    },
    isHiddenCheck: true,
    maxLength: 1,
    titleQuestion: [
      {
        num: "2",
        title: `
        Read the <span style='color:rgb(2,144,68)'>Speaking Strategy</span>. Then match 1–7 with a–g to make phrases for gaining time. 			`,
        color: "#000000",
      },
    ],

    questions: [
      {
        title: `
        <div style='padding: 10px; border: 5px dotted rgb(2, 144, 68); border-radius: 20px; width: 700px; background-color: rgb(229, 239, 230);'>
        <span style='color: rgb(2, 144, 68); font-weight: bold;'>Speaking Strategy</span> <br> 
        <div style='margin-top: 10px;'>
          Learn phrases that create time for you to formulate opinions. <br> They will also make you sound more fluent.
        </div>
      </div>

				<div style='display:flex'>
        <div>
        <div style='line-height:2;margin-top:10px'>
				<div style='display:flex'>
				  <b>1</b>
				  <div style='margin-left:10px; flex: 1;'>
          Actually, now I   
				  </div>
				  <div style='margin-left:10px'>
					<input/>
				  </div>
				</div>
				
				<div style='display:flex'>
				  <b>2</b>
				  <div style='margin-left:10px; flex: 1;'>
					All things   
				  </div>
				  <div style='margin-left:10px'>
					<input/>
				  </div>
				</div>

				<div style='display:flex'>
				  <b>3</b>
				  <div style='margin-left:10px; flex: 1;'>
          What   
				  </div>
				  <div style='margin-left:10px'>
					<input/>
				  </div>
				</div>
				
				<div style='display:flex'>
				  <b>4</b>
				  <div style='margin-left:10px; flex: 1;'>
          Let me   
				  </div>
				  <div style='margin-left:10px'>
					<input/>
				  </div>
				</div>

        
				<div style='display:flex'>
        <b>5</b>
        <div style='margin-left:10px; flex: 1;'>
        That’s a good point. 
        </div>
        <div style='margin-left:10px'>
        <input/>
        </div>
      </div>

      
				<div style='display:flex'>
        <b>6</b>
        <div style='margin-left:10px; flex: 1;'>
        Thinking   
        </div>
        <div style='margin-left:10px'>
        <input/>
        </div>
      </div>

      
				<div style='display:flex'>
        <b>7</b>
        <div style='margin-left:10px; flex: 1;'>
        I suppose
        </div>
        <div style='margin-left:10px'>
        <input/>
        </div>
      </div>


      
				
				</div>
        </div>

        <div style='margin-left:200px;margin-top:10px'>
        <div style='line-height:2'>
        <b>a</b>&ensp;What I’d say to that is … <br/>
        <b>b</b>&ensp;else? <br/>
        <b>c</b>&ensp;considered, …<br/>
        <b>d</b>&ensp;come to think about it, …  <br/>
        <b>e</b>&ensp;the thing is, … <br/>
        <b>f</b>&ensp;see.  <br/>
        <b>g</b>&ensp;about it, …


        </div>
        </div>

        </div>
      
			
        `,
        answer: [`d`, `c`, `b`, `f`, `a`, `g`, `e`],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P38-E3",
    // exerciseKey: 'img/FriendsPlus/Page38/Key/E3answerKey.png',
    component: T6v2,
    inputSize: 40,
    stylesTextInput: {
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        You are going to do the task in exercise 1 about photos C  and D. Make notes.  `,
        color: "#000000",
      },
    ],
    textareaStyle: { width: 500,padding:5 },
    maxLength: 1,
    isHiddenCheck: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
        <div>
        <img src='img/FriendsPlus/Page38/E3/1.jpg' width='100%'/>

        </div>

        <div>
        <b>Photo C</b> &ensp;<textarea  id='2' rows='3'></textarea>
        <b>Photo D</b> &ensp;<textarea  id='3' rows='3'></textarea>
        Your choice: C <input/> D <input/> Why? 
        <textarea  id='4' rows='3'></textarea>
        </div>
        </div>
				


        
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P38-E3",
    audio: "",
    video: "",
    recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page38/E4/Key/answerKey.png',
    component: T6v2,
    stylesTextInput: {
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },
    textareaStyle:{width:800,padding:5},
    isHiddenCheck: true,
    inputSize: 100,
    hideBtnFooter: true,

    titleQuestion: [
      {
        num: "4",
        title: `
				Now do the task in exercise 1, comparing and contrasting photos C and D.
  
				`,
        color: "#000000",
      },
    ],
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page38/E3/1.jpg' width='80%'/>
        <div style='display:flex,'>
        <div><b><i>ANSWER:</i></b></div>
        <textarea id='0' rows='5'></textarea>
        </div>
				
        `,
        answer: [],
      },
    ],
  },
};

export default json;
