import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P90-E1",
    audio: "",
    video: "",
    component: T6v2,
    maxLength: 4,
    inputSize: 70,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page90/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",

        title: `
		Look at the photos. Then read the article. Which photo(s) match(es) each decade?
									`,
        color: "#1dbfd6",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='position: relative;margin-left:0px'>
				<img src="img/FriendsPlus/Page90/E1/1.jpg" />
				<span style='position: absolute; top: 18px; left: 20px'><u style='color: white'>1990s:</u>#</span>
				<span style='position: absolute; top: 198px; left: 20px'><u style='color: white'>2000s:</u>#</span>
				<span style='position: absolute; top: 434px; left: 20px'><u style='color: white'>2010s:</u>#</span>
						
		</div>
			
									`,
        answer: ["c", "a", "B, D"],
      },
    ],
  },
  2: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P90-E2",
    audio: "Audios/3.02.mp3",
    video: "",
    // recorder: true,
    component: T6v2,
    // maxLength: 4,
    inputSize: 70,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page90/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",

        title: `
				<headphone name="3.02"></headphone >
				Read and listen to the article again
				and answer the questions. 
									`,
        color: "#1dbfd6",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    // isHiddenCheck: true,
    textareaStyle: {
      width: 500,
    },
    questions: [
      {
        title: `
				<img src="img/FriendsPlus/Page90/E2/1.jpg" />
				<div style='display:flex'>
					<div>
						<b>1.</b>&ensp;Why was a hairstyle called ‘the Rachel’?<br><textarea id='0' rows='3'></textarea>
					</div>
					<div style='margin-left:30px'>
							<b>3.</b>&ensp;What new hair colour became popular in the 2010s?<br><textarea id='2' rows='3'></textarea>
					</div>
				</div>
					
					<div>
							<b>2.</b>&ensp;What did the hairstyle in the 2000s look like?<br><textarea id='1' rows='3'></textarea>
					</div>
			
									`,
        answer: [
          "Jennifer Aniston, who played Rachel Green in Friends, made ‘the Rachel’ style popular.",
          "The hairstyle in the 2000s was hair extensions, which made hair very long and straight.",
          "Unnaturally grey hair became popular in the 2010s.",
        ],
      },
    ],
  },
  3: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P90-E3",
    audio: "",
    video: "",
    component: UI,
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: `Work in pairs. Answer
				the questions about Việt Nam. `,
        color: "#1dbfd6",
        prefix: { icons: ["far fa-comment"], text: "YOUR CULTURE" },
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page90/E3/1.jpg",
        },
      ],
    ],
  },
  4: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P90-E4",
    audio: "",
    video: "",
    component: UI,
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Do a class survey using the
				questions below. Then report the results. `,
        color: "#1dbfd6",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page90/E4/1.jpg",
        },
      ],
    ],
  },
};

export default json;
