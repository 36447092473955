import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P33-E1",
    // audio: "Audios/1.24.mp3",
    exerciseKey: "img/FriendsPlus/Page33/key/E1answerkey.png",
    component: T6v2,
    // hideBtnFooter: true,
    inputSize: 310,
    stylesTextInput: {
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },

    titleQuestion: [
      {
        num: "1",
        title: `
      Complete the sentences with a double comparative.       `,
        // color: "rgb(46,180,106)",
        color: "rgb(0,0,0)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
     <div style='line-height:2'>
     <b>1</b>&ensp;The weather is getting <span style='color:rgb(0,103,180)'>colder and colder</span>. (cold) <br>
     <b>2</b>&ensp;This area is becoming <input/> . (popular) <br/>
     <b>3</b>&ensp;Jimmy is getting <input/> . (tall)  <br/>
     <b>4</b>&ensp; Petrol was getting  <input/> . (expensive) <br/>
     <b>5</b>&ensp;Maria is becoming  <input/> . (confident) <br/>
     <b>6</b>&ensp;The film just got  <input/> . (silly) 

     </div>
      `,
        answer: [
          `more and more popular`,
          `taller and taller`,
          `more and more expensive`,
          `more and more confident`,
          `sillier and sillier`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P33-E2",
    // audio: "Audios/1.24.mp3",
    exerciseKey: "img/FriendsPlus/Page33/key/E2answerkey.png",
    component: T6v2,
    // hideBtnFooter: true,
    inputSize: 50,
    stylesTextInput: {
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },

    textareaStyle: {
      width: 200,
      fontSize: 25,
      textAlign: "center",
      padding: 5,
    },
    maxLength: 1,

    titleQuestion: [
      {
        num: "2",
        title: `
        Complete the sentence halves with the comparative form of the adjectives and adverbs in brackets. Then match 1–5 with a–e. 
				`,
        // color: "rgb(46,180,106)",
        color: "rgb(0,0,0)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
       <div style='line-height:2.25 '>
       <div>
       <div style='display:flex'>
       <b>1.</b>&ensp;The <textarea id='5' rows='1'></textarea> (long) I work, <input/> <br>
       </div>
       <div style='display:flex'>
       <b>2.</b>&ensp;The <textarea id='6' rows='1'></textarea> (spacious)  the house is,  <input/> <br>
       </div>
       <div style='display:flex'>
       <b>3.</b>&ensp;The <textarea id='7' rows='1'></textarea> (fast) you drive,<input/> <br>
       </div>
       <div style='display:flex'>
       <b>4.</b>&ensp;The <textarea id='8' rows='1'></textarea> (carefully) you check your work,<input/> <br>
       </div>
       <div style='display:flex'>
       <b>5.</b>&ensp;The <textarea id='9' rows='1'></textarea> (long) you keep him waiting,   <input/> <br>
       </div>

       </div>

       <div style=''>
       <div style='display:flex'>
       <b>a.</b>&ensp;the <textarea id='10' rows='1'></textarea>  (expensive) it will be.   <br>
       </div>
       <div style='display:flex'>
       <b>b.</b>&ensp;the <textarea id='11' rows='1'></textarea>  (impatient) he’ll become.   <br>
       </div>
       <div style='display:flex'>
       <b>c.</b>&ensp;the <textarea id='12' rows='1'></textarea>  (few) mistakes you make.   <br>
       </div>
       <div style='display:flex'>
       <b>d.</b>&ensp;the <textarea id='13' rows='1'></textarea>  (likely) you are to have an accident.  <br>
       </div>
       <div style='display:flex'>
       <b>e.</b>&ensp;the <textarea id='14' rows='1'></textarea>  (tired) I feel.
       <br>
       </div>


       </div>
       
       </div>
				`,
        answer: [
          `e`,
          `a`,
          `d`,
          `c`,
          `b`,
          `longer`,
          `more spacious`,
          `faster`,
          `more carefully`,
          `longer`,
          `more expensive`,
          `more impatient`,
          `fewer`,
          `more likely`,
          `more tired`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P33-E3",
    // audio: "Audios/1.24.mp3",
    exerciseKey: "img/FriendsPlus/Page33/key/E3answerkey.png",
    component: T6v2,
    // hideBtnFooter: true,
    inputSize: 800,
    stylesTextInput: {
      fontSize: "23px",
      textAlign: "left",
    },
    checkUppercase: true,

    titleQuestion: [
      {
        num: "3",
        title: `
        Write superlative sentences with the present perfect.     `,
        // color: "rgb(46,180,106)",
        color: "rgb(0,0,0)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
       <div style='line-height: 50px; font-size: 23px'>
       <b>1</b>&ensp;this / good / book / I / ever / read <br> <span style='color:rgb(0,103,180)'>This is the best book I’ve ever read. </span> <br>
       <b>2</b>&ensp;this / remote / region of the UK / I / ever / visit <br/> <input/> <br/>
       <b>3</b>&ensp;those / expensive / jeans / I / ever / buy  <br/> <input/> <br/>
       <b>4</b>&ensp;that / charming / cottage / I / ever / stay in  <br/> <input/> <br/>
       <b>5</b>&ensp;this / lively / area of town / I / ever / live in  <br/> <input/> <br/>
       <b>6</b>&ensp;that / pretty / flower bed / I / ever / see  <br/> <input/> <br/>
  
       </div>
        `,
        answer: [
          `This is the remotest region of the UK I've ever visited.`,
          `Those are the most expensive jeans I've ever bought.`,
          `That is the most charming cottage I've ever stayed in.`,
          `This is the liveliest area of town I've ever lived in.`,
          `That is the prettiest flower bed I've ever seen.`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P33-E4",
    // audio: "Audios/1.24.mp3",
    exerciseKey: "img/FriendsPlus/Page33/key/E4answerkey.png",
    component: T6v2,
    // hideBtnFooter: true,
    inputSize: 150,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
    },

    maxLength: 10,
    titleQuestion: [
      {
        num: "4",
        title: `
        Complete the sentences with less, the least, fewer, or  the fewest.     `,
        // color: "rgb(46,180,106)",
        color: "rgb(0,0,0)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
       <div style='line-height:2'>
       <b>1</b>&ensp;The kitchen is <input/> spacious room in the house. <br/>
       <b>2</b>&ensp;My flat is <input/> conveniently located than yours.  <br/>
       <b>3</b>&ensp;This house has got  <input/> rooms of the three we’ve looked at.  <br/>
       <b>4</b>&ensp; Which costs <input/> , the semi-detached house or the terraced house?  <br/>
       <b>5</b>&ensp;This is <input/>  popular area of the city.<br/>
       <b>6</b>&ensp;Which room has got <input/> furniture, the dining room, living room, or kitchen? <br/>
       <b>7</b>&ensp;There are  <input/>  flowers in the flower bed this year than last.  <br/>
       <b>8</b>&ensp;Wales is  <input/> remote than the north of Scotland.  
       </div>
        `,
        answer: [
          `the least`,
          `less`,
          `the fewest`,
          `less`,
          `the least`,
          `the least`,
          `fewer`,
          `less`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P33-E5",
    // audio: "Audios/1.24.mp3",
    exerciseKey: "img/FriendsPlus/Page33/key/E5answerkey.png",
    component: T6v2,
    // hideBtnFooter: true,
    inputSize: 220,
    stylesTextInput: {
      fontSize: "23px",
      textAlign: "center",
    },

    titleQuestion: [
      {
        num: "5",
        title: `
        Complete the sentences with the comparative and superlative form of the adverbs in brackets.    `,
        // color: "rgb(46,180,106)",
        color: "rgb(0,0,0)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
       <div style='line-height:60px ;width: max-content; font-size: 23px'>
       <b>1</b>&ensp;Dan drives <input id='' > than Ed, but Sam drives <input id='' width='340px'> of all. (slow) <br/>
       <b>2</b>&ensp;Amy works  <input id='' >  than Kate, but Frances works  <input id='' > . (hard)  <br/>
       <b>3</b>&ensp;Adam speaks French <input id='' > t than Jason, but Chris speaks <input id='' > . (fluently) <br/>
       <b>4</b>&ensp;Jed walks  <input id='' > than Fred, but Tom walks <input id='' >  . (fast) <br/>
       <b>5</b>&ensp;Ed writes  <input id='' > than Fran, but Susan writes <input id='' >  . (good) <br/>

  
       </div>
        `,
        answer: [
          `slower | slowlier`,
          `the slowest | the slowliest`,
          `harder`,
          `the hardest`,
          `more fluently`,
          `the most fluently`,
          `faster`,
          `the fastest`,
          `better`,
          `the best`,
        ],
      },
    ],
  },
};

export default json;
