import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
// import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P39-E1",
    exerciseKey: "img/FriendsPlus/Page40/Key/E1answerKey.png",
    component: T6v2,
    inputSize: 45,
    stylesTextInput: {
      // borderWidth: 1,
      // borderColor: 'black',
      // width: 40,
      // height: 40,
      // justifyContent: 'center',
      background: "none",
      borderBottom: "none",
      fontSize: "27px",
      textAlign: "center",
      fontWeight: "bold",
    },
    isHiddenCheck: true,
    maxLength: 1,
    titleQuestion: [
      {
        num: "1",
        title: `
        Complete the crossword. 
				`,
        color: "#000000",
      },
    ],

    questions: [
      {
        title: `
        <b>Across</b> <br/>
        <div style='line-height:2'>
        <b>3</b> &ensp; If you need information for your project,__online. <br>
        <b>4</b> &ensp; They’ve got Wi-Fi at the café in town, but I can never__to the network. <br>
        <b>6</b> &ensp; How many YouTube channels do you__to? <br>
        <b>8</b> &ensp; Can you show me how to__a video to YouTube? <br>
        <div style='margin-left:-13px '>
        <b>10</b> &ensp; Please__Joe’s email to me as soon as you get it. <br>
        <b>12</b> &ensp; Please don’t__on my status unless you have something nice to say! <br>
        <b>13</b> &ensp; I need to__my email account on my new computer.
        </div>
        </div>
        <b>Down</b> <br/>
        <div style='line-height:2'>
        <b>1</b> &ensp; Can you__my contribution to the blog? Please give it five stars!  <br>
        <b>2</b> &ensp; Can you help me__this new app on my phone?  <br>
        <b>5</b> &ensp; In IT, we are learning how to create and__our own video games.  <br>
        <b>7</b> &ensp; Why don’t you__your Facebook profile? It’s really old. <br>
        <b>9</b> &ensp; I can’t__this document because we’ve run out of ink.  <br>
        <div style='margin-left:-13px '>
        <b>11</b> &ensp; I’m going to__this photo on Facebook.

        </div>        
        </div>
        <div style=" position: relative;">
        <div> <img style='width:25cm' src='img/FriendsPlus/Page40/E1/1.jpg' /> </div>
        
        <div style=" background-color:none;position: absolute; top:50px; left: 705px; ">
        <input/> 
        </div>
        <div style=" background-color:none;position: absolute; top: 110px; left: 705px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 170px; left: 705px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 230px; left: 705px; ">
        <input/>
        </div>
      

        <div style=" background-color:none;position: absolute; top: 110px; left: 297px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 170px; left: 297px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 230px; left: 297px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 290px; left: 297px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 350px; left: 297px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 410px; left: 297px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 470px; left: 297px; ">
        <input/>
        </div>

        <div style="background-color:none; position: absolute; top: 170px; left: 551px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 230px; left: 551px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 290px; left: 551px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 350px; left: 551px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 410px; left: 551px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 470px; left: 551px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 530px; left: 551px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 590px; left: 551px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 650px; left: 551px; ">
        <input/>
        </div>

        
        <div style="background-color:none; position: absolute; top: 290px; left: 755px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 350px; left: 755px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 410px; left: 755px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 470px; left: 755px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 530px; left: 755px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 590px; left: 755px; ">
        <input/>
        </div>

        <div style="background-color:none; position: absolute; top: 350px; left: 145px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 410px; left: 145px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 470px; left: 145px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 530px; left: 145px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 590px; left: 145px; ">
        <input/>
        </div>

        <div style="background-color:none; position: absolute; top: 470px; left: 42px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 530px; left: 42px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 590px; left: 42px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 650px; left: 42px; ">
        <input/>
        </div>

        <div style=" background-color:none;position: absolute; top: 110px; left: 603px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 110px; left: 655px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 110px; left: 757px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 110px; left: 807px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 110px; left: 858px; ">
        <input/>
        </div>

        <div style=" background-color:none;position: absolute; top: 170px; left: 42px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 170px; left: 93px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 170px; left: 145px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 170px; left: 195px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 170px; left: 247px; ">
        <input/>
        </div>

        <div style=" background-color:none;position: absolute; top: 230px; left: 350px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 230px; left: 399px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 230px; left: 450px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 230px; left: 501px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 230px; left: 603px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 230px; left: 654px; ">
        <input/>
        </div>


        <div style=" background-color:none;position: absolute; top: 350px; left: 94px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 350px; left: 196px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 350px; left: 247px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 350px; left: 348px; ">
        <input/>
        </div>
        

        <div style=" background-color:none;position: absolute; top: 410px; left: 450px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 410px; left: 501px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 410px; left: 605px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 410px; left: 655px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 410px; left: 700px; ">
        <input/>
        </div>

        <div style=" background-color:none;position: absolute; top: 530px; left: 450px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 530px; left: 501px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 530px; left: 604px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 530px; left: 655px; ">
        <input/>
        </div>
        <div style=" background-color:none;position: absolute; top: 530px; left: 705px; ">
        <input/>
        </div>

        <div style="background-color:none; position: absolute; top: 590px; left: 93px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 590px; left: 196px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 590px; left: 247px; ">
        <input/>
        </div>
        <div style="background-color:none; position: absolute; top: 590px; left: 298px; ">
        <input/>
        </div>
    </div>

   
        `,
        answer: [
          `r`,
          `a`,
          `t`,
          `e`,
          `i`,
          `n`,
          `s`,
          `t`,
          `a`,
          `l`,
          `l`,
          `p`,
          `r`,
          `o`,
          `g`,
          `r`,
          `a`,
          `m`,
          `m`,
          `e`,
          `u`,
          `p`,
          `d`,
          `a`,
          `t`,
          `e`,
          `p`,
          `r`,
          `i`,
          `n`,
          `t`,
          `p`,
          `o`,
          `s`,
          `t`,
          `s`,
          `e`,
          `r`,
          `c`,
          `h`,
          `l`,
          `o`,
          `g`,
          ` `,
          `o`,
          `u`,
          `b`,
          `s`,
          `c`,
          `i`,
          `b`,
          `u`,
          `l`,
          `o`,
          `d`,
          `f`,
          `o`,
          `w`,
          `a`,
          `r`,
          `c`,
          `o`,
          `m`,
          `e`,
          `n`,
          `e`,
          ` `,
          `u`,
          `p`,
        ],
      },
    ],
  },

  2: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P40-E1",
    exerciseKey: "img/FriendsPlus/Page40/Key/E2answerKey.png",
    component: T6v2,
    inputSize: 250,
    stylesTextInput: {
      fontSize: "23px",
      textAlign: "center",
    },
    isHiddenCheck: true,
    maxLength: 30,
    titleQuestion: [
      {
        num: "2",
        title: `
        Complete the collocations with the nouns below. 
				`,
        color: "#000000",
      },
    ],

    questions: [
      {
        title: `
        <div style="font-size: 24px; width:800px; font-weight:bold;color:rgb(142,15,177);">
        <span>a link&ensp;&ensp;</span>
        <span>a new window&ensp;&ensp;</span>
        <span>the trash&ensp;&ensp;</span>
        <span>your username&ensp;&ensp;</span>
        <span>your work&ensp;&ensp;</span>
        </div>

        <div style='line-height:50px; font-size: 23px'>
        <div style='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        empty <input/>
        </div>
        </div>

        <div style='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        follow  <input/>
        </div>
        </div>

        <div style='display:flex'>
        <b>3</b>
        <div style='margin-left:10px'>
        save  <input/>, a document, a file, a photo 
        </div>
        </div>

        <div style='display:flex'>
        <b>4</b>
        <div style='margin-left:10px'>
        enter your password, <input/> your address, etc. 
        </div>
        </div>

        <div style='display:flex'>
        <b>5</b>
        <div style='margin-left:10px'>
        open / close an app, <input/> , a folder,  a file, a document 
        </div>
        </div>
        </div>

        <div style="width:800px;font-size:25px;font-weight:bold;color:rgb(142,15,177);">
        <span>an account&ensp;&ensp;</span>
        <span>a box&ensp;&ensp;</span>
        <span>a button&ensp;&ensp;</span>
        <span>a page&ensp;&ensp;</span>
        <span>text&ensp;&ensp;</span>
        </div>

        <div style='line-height:1.75'>
        <div style='display:flex'>
        <b>6</b>
        <div style='margin-left:10px'>
        check / uncheck <input/>
        </div>
        </div>

        <div style='display:flex'>
        <b>7</b>
        <div style='margin-left:10px'>
        copy and paste <input/>, a photo, a file,  a link, a folder 
        </div>
        </div>

        <div style='display:flex'>
        <b>8</b>
        <div style='margin-left:10px'>
        click / double click on <input/>, an icon, a link, a menu 
        </div>
        </div>

        <div style='display:flex'>
        <b>9</b>
        <div style='margin-left:10px'>
        scroll up / down <input/>, a menu, a document 
        </div>
        </div>

        <div style='display:flex'>
        <b>10</b>
        <div style='margin-left:10px'>
        create <input/>, a new document, a file, a link, a folder 
        </div>
        </div>
        </div>
        `,
        answer: [
          `the trash`,
          `a link`,
          `your work`,
          `your username`,
          `a new window`,
          `a box`,
          `text`,
          `a button`,
          `a page`,
          `an account`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U5-P40-E3",
    audio: "Audios/1.20 audio.mp3",

    exerciseKey: "img/FriendsPlus/Page40/Key/E3answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "3",
        title: `
        <headphone name='1.20' src='Audios/1.20 tieu de.mp3'></headphone> Listen. What is the boy trying to do? Choose the correct answer. 
              `,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 500 },
    // maxLength:1,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: {
          width: 149,
          fontSize: 23,
          borderBottom: "none",
          textAlign: "center",
        },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {},
        selectWordStyle: {
          // padding: 5,
          color: "black",
        },
        limitSelect: 3,
        listWords: [`✓  ✓ ✓ `],
        answers: ['0-4'],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;top:0px;left:20px; display:flex; flex-direction: column;line-height:50px  '>
              <div style='display:flex; position: relative'>
                <div style='margin-right: 20px'>
                  <b>1</b>&ensp; update a Facebook account <br>
                  <b>2</b>&ensp; create a Facebook account <br>
                  <b>3</b>&ensp; delete a Facebook account <br>
                </div>
                <div style='position: relative'>
                  <div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
                  <div style='width:40px;height:40px; margin:10px 0px; border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
                  <div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
                </div>
              </div>
              
             
          </div>
          <div style='position:relative; top:-154px;left:380px;color: white; display:flex; flex-direction: column;line-height:50px  '>
          <input id='0' type='Circle'>
          
        </div>
       
      </div>
    </div>
       
        
       
           
        
      `,
    },
  },
  4: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P40-E4",
    exerciseKey: "img/FriendsPlus/Page40/Key/E4answerKey.png",
    audio: "Audios/1.20 audio.mp3",
    video: "",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "4",
        title: `
        <headphone name='1.20' src='Audios/1.20 tieu de.mp3'></headphone> Listen again. Choose the correct words.
					`,
        color: "#000",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "3px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWords: [
          `icon / button`, // 0
          `enter / save`, // 1
          `confirm / create`, // 2
          `following / saving`, // 3
          `profile / password`, // 4,
          `copy_and_paste / upload`,
          `Open / Empty`,
        ],
        answers: ["0-4", "1-0", "3-0", "2-0", "4-0", "5-4", "6-0"],
        initialValue: {},
      },
      Layout: `
    <div style='line-height:2'>
    <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">1 </span>
    Click on the ‘Sign up’ <input id='0' type='Circle' style="letter-spacing: 10px;"/> and <input id='1' type='Circle' style="letter-spacing: 10px;"/> the information.
  </div>

  <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">2 </span>
    You’ll need to <input id='2' type='Circle' style="letter-spacing: 10px;"/> your email address by <input id='3' type='Circle' style="letter-spacing: 10px;"/> the link in the email. 
  </div>

  <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">3 </span>
    Let’s create a  <input id='4' type='Circle' style="letter-spacing: 10px;"/> for you. 
  </div>

  <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">4 </span>
    You need to <input id='5' type='Circle' style="letter-spacing: 10px;"/> a photo. 
  </div>

  <div style='margin: 10px 10px;'>
    <span style="font-weight: bold;">5 </span>
    <input id='6' type='Circle' style="letter-spacing: 10px;"/> the folder where your photos are stonone. 
  </div>
    </div>
			
				`,
    },
  },
  5: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P40-E5",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E1answerKey.png',
    component: T6v2,
    inputSize: 850,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "left",
    },
    isHiddenCheck: true,
    // maxLength: 1,
    hideBtnFooter: true,
    textareaStyle: { width: 820,padding:5 },
    titleQuestion: [
      {
        num: "5",
        title: `
				Write instructions on how to do one of these things with  a computer. 
				`,
        color: "#000000",
      },
    ],

    questions: [
      {
        title: `
        <ul>
        <li>download music </li>
        <li>subscribe to a YouTube channel</li>
        </ul>
        <textarea id='0' rows='8'></textarea>
        `,

        answer: [],
      },
    ],
  },
};
export default json;
