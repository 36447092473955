import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import MatchDots from "../../components/ExcerciseTypes/TypeIn/MatchDots";

const json = {
  2: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P83-E2",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page83/key/E2answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: "Match the sentences 1–4 in exercise 1 with rules a–d.",
        color: "rgb(28,190,215)",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "54px",
              left: "489px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "489px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "198px",
              left: "489px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "272px",
              left: "489px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "85px",
              right: "489px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "144px",
              right: "489px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "206px",
              right: "489px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "263px",
              right: "489px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
        ],
        answers: ["0-6", "1-5", "2-7", "3-4"],
        initialValue: [],
      },
      Layout: `
		  <div class="container">
				<div style="display: flex;justify-content: space-between;align-items: center; flex-wrap: wrap; gap: 10px;min-width:1200px">
					<div class="col-5" style="flex:1;font-size:25px;margin-left:-60px">
						<p style="font-size: 25px; margin-top: 30px; ">
							<span style="font-weight: bold; margin-right: 10px;">1</span>
							<span>Was the book adapted for film?</span>
							<div><input id="0" type="boxMatch" /></div>
	
						</p>
		  
						<p style="font-size: 25px; margin-top: 30px; ">
							<span style="font-weight: bold; margin-right: 10px;">2</span>
							<span>What is the series of books called?</span>
							<div><input id="1" type="boxMatch" /></div>
	
						</p>
		  
						<p style="font-size: 25px; margin-top: 30px; ">
							<span style="font-weight: bold; margin-right: 10px;">3</span>
							<span>Where was the film made?</span>
							
							<div><input id="2" type="boxMatch" /></div>
						</p>
		  
						<p style="font-size: 25px; margin-top: 30px; ">
							<span style="font-weight: bold; margin-right: 10px;">4</span>
							<span>Is the actor nominated for an award every year?</span>
							<div><input id="3" type="boxMatch" /></div>
						</p>
	  
						
	
	
					</div>
	
					<div class="col-5" style="flex:1;">
	
						<div style="border: 4px solid rgb(28, 190, 215); border-radius: 30px">
								<div
									style="
									background-color: rgb(28, 190, 215);
									border-radius: 40px;
									margin: 10px 10px;
									"
								>
									<h3 style="padding-left: 20px; color: white; font-size: 30px">RULES</h3>
								</div>
	
								<div style="margin: 10px 10px;font-size:22px">
									<span style="font-weight: bold;margin-right: 10px;">a </span>
									<i>am / is / are</i> + subject + past participle.
									<div><input id="4" type="boxMatch" /></div>
								</div>
	
								<div style="margin: 10px 10px;font-size:22px">
									<span style="font-weight: bold;margin-right: 10px;">b </span>
									a question word + <i>am / is / are</i> + subject + past participle.
									<div><input id="5" type="boxMatch" /></div>
								</div>
	
								<div style="margin: 10px 10px;font-size:22px">
									<span style="font-weight: bold;margin-right: 10px;">c </span>
									<i>was / were</i> + subject + past participle.
									<div><input id="6" type="boxMatch" /></div>
								</div>

								<div style="margin: 10px 10px;font-size:22px">
									<span style="font-weight: bold;margin-right: 10px;">d </span>
									a question word + <i>was / were</i> + subject + past participle.
									<div><input id="7" type="boxMatch" /></div>
								</div>
	
						</div>
	
					</div>
				</div>
			</div>	
	
	
		  `,
    },
  },
  3: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P83-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 450,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      //   fontWeight: "bold",
      //   textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
		Correct the mistakes in the sentences.
								`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `

		<div style="display: flex;flex-direction: column; flex-wrap: wrap;gap:30px;margin-top: 30px;">
			<div>
				<span style="font-weight: bold; margin-right: 10px">1</span>
				<span>What he was given as a prize?</span>
				<p style="font-size:25px">Answer:#</p>
			</div>

			<div >
				<span style="font-weight: bold; margin-right: 10px">2</span>
				<span>Is this crime novel written last year?</span>
				<p style="font-size:25px">Answer:#</p>
			</div>

			<div >
				<span style="font-weight: bold; margin-right: 10px">3</span>
				<span>What was award to the actor?</span>
				<p style="font-size:25px">Answer:#</p>
			</div>

			<div >
				<span style="font-weight: bold; margin-right: 10px">4</span>
				<span>Who was the film directed for?</span>
				<p style="font-size:25px">Answer:#</p>
			</div>

			<div >
				<span style="font-weight: bold; margin-right: 10px">5</span>
				<span>Were this book written by a man?</span>
				<p style="font-size:25px">Answer:#</p>
			</div>
			
		</div>

		

		

		
								`,
        answer: [
          "What was he given as a prize?",
          "Was this crime novel written last year?",
          "What was awarded to the actor?",
          "Who was the film directed by?",
          "Was this book written by a man?",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P83-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page83/key/E4answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    // maxLength: 1,
    inputSize: 450,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      //   fontWeight: "bold",
      //   textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
		Read the description on the right. Write questions in the passive about the
		information in <span style="color: rgb(28, 190, 215)">blue</span>.

								`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<p style="font-size: 25px;">When..........................?</p>
		<i style="font-weight: bold;color:gray;">When was the Edible Book Festival started?</i>
		<div style="margin-left: 200px">
			<img src="img/FriendsPlus/Page83/16.jpg"/>
		</div>

		<h1 style="margin-top: 30px">Answers</h1>

		<div style="display: flex;flex-direction: column; flex-wrap: wrap;gap:30px;margin-top: 30px;">
			<div style="display: flex;">
				<span style="font-weight: bold; margin-right: 10px">1</span>
				<span >what</span>
				<div style="margin-top:-7px"><textarea id="0" rows="3"></textarea></div>
				
			</div>

			<div style="display: flex;">
				<span style="font-weight: bold; margin-right: 10px">2</span>
				<span>Where</span>
				<div style="margin-top:-7px"><textarea id="1" rows="3"></textarea></div>

			</div>

			<div style="display: flex;">
				<span style="font-weight: bold; margin-right: 10px">3</span>
				<span>Was</span>
				<div style="margin-top:-7px"><textarea id="2" rows="3"></textarea></div>

			</div>

			<div style="display: flex;">
				<span style="font-weight: bold; margin-right: 10px">4</span>
				<span>Who</span>
				<div style="margin-top:-7px"><textarea id="3" rows="3"></textarea></div>

			</div>

			<div style="display: flex;">
				<span style="font-weight: bold; margin-right: 10px">5</span>
				<span>Are</span>
				<div style="margin-top:-7px"><textarea id="4" rows="3"></textarea></div>

			</div>
			
		</div>

		
								`,
        answer: [
          "are made into cakes?",
          "are the cakes displayed?",
          "the Hunger Games series made into a cake?",
          "were the Hunger Games trilogy and Wool cakes made by? / were the Hunger Games trilogy, Game of Thrones and Wool cakes made by?",
          "the 'books' eaten at the end of the festival?",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P83-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page83/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 600,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      //   fontWeight: "bold",
      //   textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
		Work in pairs. Take turns to ask and answer the questions in exercise 4. Answer in
		complete sentences.

								`,
        color: "rgb(28,190,215)",
        prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		
		<h1 style="margin-top: 30px">Answers</h1>
		<div style="display: flex;flex-direction: column; flex-wrap: wrap;gap:30px;margin-top: 30px;">
			<div style="display: flex;">
				<span style="font-weight: bold; margin-right: 10px">1</span>
				<span >#</span>
				
			</div>

			<div style="display: flex;">
				<span style="font-weight: bold; margin-right: 10px">2</span>
				<span >#</span>
			</div>

			<div style="display: flex;">
				<span style="font-weight: bold; margin-right: 10px">3</span>
				<span >#</span>

			</div>

			<div style="display: flex;">
				<span style="font-weight: bold; margin-right: 10px">4</span>
				<span >#</span>

			</div>

			<div style="display: flex;">
				<span style="font-weight: bold; margin-right: 10px">5</span>
				<span >#</span>

			</div>
			
		</div>
		
								`,
        answer: [
          "The latest books are made into cakes.",
          "They are displayed all over the world.",
          "Yes, it was.",
          "It was / They were made by Crystal Watanabe.",
          "Yes, they are.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P83-E6",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "",
    hideBtnFooter: true,
    component: T6v2,
    // maxLength: 1,
    inputSize: 600,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      //   fontWeight: "bold",
      //   textAlign: "center",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
		Work in groups. Each group writes three questions about books using
		passive voice, then reads out for the others to answer. The group with the most correct
		answers is the winner.

								`,
        color: "rgb(28,190,215)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<i style="font-weight: bold;color:rgb(87,88,90);font-size:18px;">Who was The last leaf written by?</i><br>

		<i style="font-weight: bold;color:rgb(87,88,90);font-size:18px;">When was the poem Mùa xuân nho nho written?</i>

		<div style="margin-left: 200px">
			<img src="img/FriendsPlus/Page83/17.jpg" />
		</div>
		
								`,
        answer: [],
      },
    ],
  },
};

export default json;
