import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write2 from "../../components/ExcerciseTypes/Circle_Write2";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P39-E1",
    exerciseKey: "img/FriendsPlus/Page39/Key/E1answerKey.png",
    component: T6v2,
    inputSize: 100,
    stylesTextInput: {
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
      fontWeight: "bold",
    },
    isHiddenCheck: true,
    maxLength: 1,
    titleQuestion: [
      {
        num: "1",
        title: `
        Read the task and the model email. In what order does Lisa cover the four points? Number them. 
				`,
        color: "#000000",
      },
    ],

    questions: [
      {
        title: `
         <div style='display:flex'>
         <div>
         <img src='img/FriendsPlus/Page39/E1/1.jpg' width='100%'/>
         </div>
         <div>
         <div style='width:700px;border:none;background-color:rgb(234,238,249);padding:10px 10px'>
         <b>You are moving in with relatives next week. Write an email  to a friend. Include the following: </b> <br/>
         <ul>
         <li>	Explain the reasons for the arrangement. <input/></li> <br/>
         <li>	Describe your relatives’ house. <input/></li> <br/>
         <li>	Describe the relatives. <input/></li> <br/>
         <li>	Ask permission to borrow something from your friend. <input/></li> <br/>

         </ul>
         </div>
         </div>
         </div>
        `,
        answer: [`1`, `3`, `2`, `4`],
      },
    ],
  },

  2: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P39-E2",
    audio: "",
    component: Circle_Write2,
    exerciseKey: "img/FriendsPlus/Page39/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
			Read the <span style='color: rgb(38,130,196)'>Writing Strategy</span>. Circle eight different contractions in the model email. Then underline one sentence with a word omitted at the start.
					`,
        color: "#000",
      },
    ],
    question: {
      Write: {
        inputStyle: {
          fontSize: "24px",
          border: "",
        },
        answers: [],

        initialValue: [],
      },
      Circle: {
        selectWordStyle: { border: "3px solid black", borderRadius: "50%" },
        // limitSelect: 8,
        listWordsStyle: {},
        listWords: [
          // Circle
          `I’m`, // 0
          `we’re`, // 1
          `don’t`, // 2
          `He’s`, // 3
          `She’s`, // 4
          `You’d`, // 5
          `I’ve`, // 6
          `can’t`, // 7
          `I_hope_all_is_well_with_you.`, //8
          `We_need_to_move_out_of_our_flat_because_the_landlord_is_selling_it,`, //19
          `My_cousin_Joe_is_a_really_nice_guy.&ensp;`, //10
          `My_cousin_Samantha_looks_a_bit_like_you.&ensp;`, //11
          `She_is_tall,_with_curly_hair_and_green_eyes.&ensp;`, //12,
          `My_cousins’_house_is_quite_large_and_includes_a_new_extension,`, //13
          `but_it_will_still_be_a_bit_crowded.`, //14
          `There_are_four_bedrooms_in_the_house_and_two_in_the_extension,&ensp;`, //15
          `I_think,_but_I’m_going_to_sleep_in_the_conservatory!`, //16
          `Better_get_back_to_my_homework.&ensp;`, //17
          `Would_it_be_OK_if_I_borrowed_your_tennis_racket_this_weekend?&ensp;`, //18
          `I_think_Mum_might_have_already_packed_it! `, //19
        ],
        answers: [
          "0-0",
          "1-0",
          "2-0",
          "3-0",
          "4-0",
          "5-0",
          "6-0",
          "7-0",
          "17-0",
        ],
        initialValue: [],
      },

      Layout: `
		  <div style='display:flex'>
		  <div style='flex:1'>
		  <div style='padding: 10px; border: 5px dotted rgb(38,130,196); border-radius: 20px; width: 550px; background-color: rgb(229, 239, 230);'>
		  <span style='color: rgb(38,130,196); font-weight: bold;'>Writing Strategy</span> <br> 
		  <div style='margin-top: 10px;'>
		  When you write an informal email or letter:  <br> 
		  <ul>
		  <li>you should avoid formal language.</li>
		  <li>you can use contractions (you’re, it’s, etc.).</li>
		  <li>you sometimes omit words like I, I’m or I’ve at the start of a sentence (Hope you’re well, Got to go now, etc.).
		  </li>
  
		  </ul>
		  </div>
		</div>
		  </div>
		  <div style='flex:1;margin-left:10px'>
		  <div style='margin-top:10px;padding: 10px 10px;  border-radius: 20px; width: 700px; background-color: rgb(229, 239, 230);'>
		  <div style='display:flex'>
		  <div style='margin-top:3px'>	 <i class="fa fa-envelope"></i>&ensp;
		  </div>
		 <div style='margin-left:30px;display:flex'>
		 To:&ensp;<p style='background-color:white;border-radius:10px;padding:3px 3px; width:530px'><i>madison@email.com</i></p></div>
	
		 </div>
		 <div style='background-color:white;margin-top:20px;padding:3px 3px;border-radius:10px;font-style: italic;'>
		 Hi Madison, <br/> 
			 <input id='8'  type='Underline' />
			  Did I tell you <input id='0'  type='Circle' /> moving house soon?
			<input id='9'  type='Underline' />
				 so <input id='1'  type='Circle' /> going to live with my cousins for a few months.<br/>
			  They <input id='2'  type='Circle' /> live far away - in a small village about twenty miles from here.
			 <input id='10'  type='Underline' />
			<input id='3'  type='Circle' /> the same age as me and he’s got dark hair and brown eyes.
			 <input id='11'  type='Underline' />
			  <input id='12'  type='Underline' />
			 <input id='4'  type='Circle' /> great fun.
			 <input id='5'  type='Circle' /> like them. <br/>
			  <input id='13'  type='Underline' />
			 but <input id='14'  type='Underline' />
			 <input id='15'  type='Underline' />
			 <input id='16'  type='Underline' /> <br/>
			  <input id='17'  type='Underline' />
			<input id='18'  type='Underline' />
			<input id='6'  type='Circle' />  got one, but I <input id='7'  type='Circle' />  find it.
			<input id='19'  type='Underline' /> <br/>
			  Bye for now. <br/>
			  Lisa
	
		 </div>
		 </div>
		  </div>
		  </div>
				`,
    },
  },
  3: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P39-E3",
    exerciseKey: "img/FriendsPlus/Page39/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 750,
    stylesTextInput: {
      fontSize: "23px",
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				Rewrite the sentences using <i>would rather</i> with the base form or past simple.  `,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 500 },
    // maxLength:1,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
				<div style='font-size: 23px; line-height: 50px'>
			<div style='display:flex'>
			<b>1</b>
			<div style='margin-left:10px'>
			I want you to help me. <br/>
			<span style='color:rgb(0,103,180)'>I’d rather you helped me. </span>
			</div>
			</div>

			<div style='display:flex'>
			<b>2</b>
			<div style='margin-left:10px'>
			I don’t want my sister to find out. <br/>
			<input/>
			</div>
			</div>

			<div style='display:flex'>
			<b>3</b>
			<div style='margin-left:10px'>
			I want to be outside.   <br/>
			<input/>
			</div>
			</div>

			<div style='display:flex'>
			<b>4</b>
			<div style='margin-left:10px'>
			I don’t want to go to bed yet.  <br/>
			<input/>
			</div>
			</div>

			<div style='display:flex'>
			<b>5</b>
			<div style='margin-left:10px'>
			I want us to spend more time together.  <br/>
			<input/>
			</div>
			</div>

			</div>
        
        `,
        answer: [
          `I'd rather my sister didn't find out.`,
          `I'd rather be outside.`,
          `I'd rather not go to bed yet.`,
          `I'd rather we spent more time together.`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P39-E4",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page39//Key/E4answerKey.png",
    component: T6v2,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
    },
    isHiddenCheck: true,
    inputSize: 270,
    maxLength: 22,
    titleQuestion: [
      {
        num: "4",
        title: `
				Complete the second sentence in each pair with <i>had better (not)</i> and the verb in brackets.
  
				`,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 600 },
    questions: [
      {
        title: `
				<div style='line-height:2'>
				<div style='display:flex'>
				<b>1</b>
				<div style='margin-left:10px'>
				This milk smells bad. We 
				<span style='color:blue'>had better not drink </span> (drink) it. 
				</div>
				</div>
	
				<div style='display:flex'>
				<b>2</b>
				<div style='margin-left:10px'>
				The roads are icy. You 
				<input/> (drive) too fast. 
				</div>
				</div>
	
				<div style='display:flex'>
				<b>3</b>
				<div style='margin-left:10px'>
				This is my dad’s laptop. We 
				<input/> (ask) before we use it. 
				</div>
				</div>
	
				<div style='display:flex'>
				<b>4</b>
				<div style='margin-left:10px'>
				It isn’t safe to walk home at night. You 
				<input/> (get) a taxi. 
				</div>
				</div>
	
				<div style='display:flex'>
				<b>5</b>
				<div style='margin-left:10px'>
				That dog doesn’t look friendly. We 
				<input/> (go) near it. 
				</div>
				</div>
				</div>

				
        `,
        answer: [
          `had better not drive`,
          `had better ask`,
          `had better get`,
          `had better not go`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P39-E5",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E1answerKey.png',
    component: T6v2,
    inputSize: 850,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "left",
    },
    isHiddenCheck: true,
    // maxLength: 1,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title: `
				Read the task. Then make notes under headings 1–4 below. 
				`,
        color: "#000000",
      },
    ],

    questions: [
      {
        title: `
         <div>
         <div style='width:900px;border:none;background-color:rgb(234,238,249);padding:5px 5px'>
         <b>You are moving in with relatives next week. Write an email  to a friend. Include the following: </b> <br/>
         <ul>
         <li>	Describe your new home. </li> 
         <li>	Give information about your plans for moving. </li> 
         <li>	Describe someone you will miss after you move. </li> 
         <li>	Ask permission to leave something at your friend’s house.  </li> 
         </ul>
         </div>
         </div>

		 <div style='display:flex'>
		 <b>1</b>
		 <div style='margin-left:10px'>
		 What is your new home like? <br/> #
		 
		 </div>
		 
		 </div>

		 <div style='display:flex'>
		 <b>2</b>
		 <div style='margin-left:10px'>
		 What are the arrangements for moving?  <br/> #
		 
		 </div>
		 
		 </div>

		 <div style='display:flex'>
		 <b>3</b>
		 <div style='margin-left:10px'>
		 Who will you miss? <br/> #
		 
		 </div>
		 
		 </div>

		 <div style='display:flex'>
		 <b>4</b>
		 <div style='margin-left:10px'>
		 What do you want to leave with your friend? <br/> #
		 
		 </div>
		 
		 </div>
        `,

        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P39-E6",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E1answerKey.png',
    component: T6v2,
    inputSize: 850,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "left",
    },
    isHiddenCheck: true,
    textareaStyle: { width: 800,padding:5 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "6",
        title: `
				Now do the task. Write your email using your notes from exercise 5.

				`,
        color: "#000000",
      },
    ],

    questions: [
      {
        title: `
					<textarea id='0' rows='12'></textarea>
        `,

        answer: [],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "FGF11-U4-P39-E7",
    audio: "",
    video: "",
    component: DesignUnderLine,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 500,
    textAlign: "left",
    // exerciseKey: 'img/FriendsPlus/Page14/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: "",
        title: `
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 23,
      height: 300,
    },
    totalInput: 3,
    // isAllowSubmit: false,
    questionImage: [
      [{ url: "img/FriendsPlus/Page39/E7/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page39/E7/2.jpg" },
        { url: "img/FriendsPlus/Page39/E7/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page39/E7/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page39/E7/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page39/E7/6.jpg" },
        { url: "img/FriendsPlus/Page39/E7/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page39/E7/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page39/E7/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page39/E7/10.jpg" },
        { url: "img/FriendsPlus/Page39/E7/11.jpg", input: 1 },
        { url: "img/FriendsPlus/Page39/E7/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page39/E7/13.jpg" }],
    ],
  },
};

export default json;
