import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 3",
    id: "FGF11-U3-P25-E1",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    checkUppercase: true,
    inputSize: 750,
    maxLength: 150,
    textAlign: "left",
    exerciseKey: "img/FriendsPlus/Page25/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `<span style=''>Look at the table. Then write sentences using the prompts.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 21,
      height: 27,
      // textTransform: 'uppercase',
    },
    questions: [
      {
        title: `
				<img style="width:16cm; height:5cm" src='img/FriendsPlus/Page25/E1/1.jpg' />
        
        <div style="display: flex; line-height: 40px; font-size: 21px">
          
          <div style='margin-right: 20px'>
            <span><b>1 </b></span><br><br>
            <span><b>2 </b></span><br><br>
            <span><b>3 </b></span><br><br>
            <span><b>4 </b></span><br><br>
            <span><b>5 </b></span><br><br>
						<span><b>6 </b></span><br><br>
            <span><b>7 </b></span><br>
          </div>
        
        <div>
          <span>it / rain / tomorrow (70%)</span><br>
          <span style='color: rgb(152,198,204)'>It might rain tomorrow.</span><br>
          <span>I / go to bed late tonight (0%)</span><br>
          <span>#</span><br>
          <span>Tom / pass all his exams (100%)</span><br>
          <span>#</span><br>
          <span>Lisa / go to the doctor’s tomorrow (70%)</span><br>
          <span>#</span><br>
          <span>I / get the answer right (40%)</span><br>
          <span>#</span><br>
					<span>Fred / see his girlfriend this weekend (90%)</span><br>
          <span>#</span><br>
					<span>Jade / play computer games this evening (10%)</span><br>
          <span>#</span><br>
        </div>

        </div>

				`,
        answer: [
          `I definitely won't go to bed late tonight.`,
          `Tom will definitely pass all his exams.`,
          `Lisa could go to the doctor's tomorrow. / Lisa may go to the doctor's tomorrow. / Lisa might go to the doctor's tomorrow.`,
          `I may not get the answer right. / I might not get the answer right.`,
          `Fred will probably see his girlfriend this weekend.`,
          `Jade probably won't play computer games this evening.`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "FGF11-U3-P25-E2",
    audio: "",
    video: "",
    component: T6v2,
    isHiddenCheck: true,
    checkUppercase: true,
    inputSize: 850,
    maxLength: 140,
    textAlign: "left",
    exerciseKey: "img/FriendsPlus/Page25/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `<span style='font-size: 24px'><span style='color: rgb(154,44,151)'>USE OF ENGLISH&ensp;</span>Rewrite the sentence so that it has a similar 
				meaning using the word in brackets.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 21,
      height: 27,
      // textTransform: 'uppercase',
    },
    questions: [
      {
        title: `
        
        <div style="display: flex; line-height: 45px; font-size: 21px">
          
          <div style='margin-right: 20px'>
            <span><b>1 </b></span><br><br>
            <span><b>2 </b></span><br><br>
            <span><b>3 </b></span><br><br>
            <span><b>4 </b></span><br><br>
            <span><b>5 </b></span><br><br>
						<span><b>6 </b></span><br><br>
          </div>
        
        <div>
          <span>I doubt scientists will find a cure for cancer in the near 
					future. (probably)</span><br>
					<span>#</span><br>
          <span>I’m certain that doctors won’t find a cure for the common 
					cold. (definitely)</span><br>
          <span>#</span><br>
          <span>It’s possible that millions of people will get ill from the flu 
					virus this winter. (may)</span><br>
          <span>#</span><br>
          <span>I’m sure bio-printing of organs will become a reality. 
					(definitely)</span><br>
          <span>#</span><br>
          <span>I’m fairly sure people will be healthier in the future. 
					(probably)</span><br>
          <span>#</span><br>
					<span>It’s possible that antibiotics won’t be effective in the 
					future. (might)</span><br>
          <span>#</span><br>
					
        </div>

        </div>

				`,
        answer: [
          `Scientists probably won't find a cure for cancer in the near future.`,
          `Doctors definitely won't find a cure for the common cold.`,
          `Millions of people may get ill from the flu virus this winter.`,
          `Bio-printing of organs will definitely become a reality.`,
          `People will probably be healthier in the future.`,
          `Antibiotics might not be effective in the future.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FGF11-U3-P25-E3",
    // audio: 'Audios/1.06.mp3',
    video: "",
    component: T6,
    inputSize: 40,
    isHiddenCheck: true,
    maxLength: 1,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page25/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				<span style='font-size: 25px'>Match 1–6 with a–f to make predictions.</span>
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 25,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
				
        <div style=" position: relative; padding-top:10px; font-size: 22px; ">
          <div><img style="width: 20cm" src='img/FriendsPlus/Page25/E3/1.jpg';/></div>

					<div style=" position: absolute; top: 30px; left: 327px">#</div>
          <div style=" position: absolute; top: 70px; left: 240px">#</div>
					<div style=" position: absolute; top: 111px; left: 250px">#</div>
          <div style=" position: absolute; top: 152px; left: 242px">#</div>
					<div style=" position: absolute; top: 193px; left: 365px">#</div>
          <div style=" position: absolute; top: 234px; left: 301px">#</div>
        </div>

				`,
        answer: [`d`, `f`, `a`, `b`, `c`, `e`],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "FGF11-U3-P25-E4",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    // checkUppercase: true,
    inputSize: 200,
    maxLength: 20,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page25/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `<span style=''>Complete the first conditional sentences with the verbs 
				below. Use the present simple form and <i>will / won't</i> + base form.</span>
				`,
        color: "black",
      },
    ],
    hintBox: [
      {
        src: [
          "be",
          "be",
          "not be able to",
          "cut down",
          "die out",
          "find",
          "get",
          "get rid of",
          "live",
          "protect",
          "rise",
          "not use",
        ],
        borderColor: "none",
        width: 800,
        fontColor: "#93338e",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 21,
      height: 27,
      // textTransform: 'uppercase',
    },
    questions: [
      {
        title: `
				<hintbox id=0></hintbox>
        <div style="display: flex; line-height: 50px; font-size: 21px; margin-top:20px">
          <div style='margin-right: 20px'>
            <span><b>1 </b></span><br>
            <span><b>2 </b></span><br>
            <span><b>3 </b></span><br>
            <span><b>4 </b></span><br>
            <span><b>5 </b></span><br>
						<span><b>6 </b></span><br>
          </div>
        
        <div style='max-width: 1200px'>
          <span>If scientists # cures for most diseases, people # much longer.</span><br>
          <span>If the climate # warmer, sea levels #.</span><br>
          <span>Many endangered species # if we don’t # them.</span><br>
          <span>We # stop climate change if we # less fossil fuels.</span><br>
          <span>If we # the rainforests, there # more carbon dioxide in the atmosphere.</span><br>
          <span>If we # nuclear weapons, I think the world # a much safer place.</span><br>
        </div>

        </div>

				`,
        answer: [
          `find`,
          `will live`,
          `gets`,
          `will rise`,
          `will die out`,
          `protect`,
          `won't be able to`,
          `don't use`,
          `cut down`,
          `will be`,
          `get rid of`,
          `will be`,
        ],
      },
    ],
  },
};

export default json;
