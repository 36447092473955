import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P41-E1",
    exerciseKey: "img/FriendsPlus/Page41/Key/E1answerKey.png",
    component: T6v2,
    inputSize: 400,
    stylesTextInput: {
      fontSize: "23px",
      textAlign: "center",
    },
    isHiddenCheck: true,
    // maxLength: 1,
    titleQuestion: [
      {
        num: "1",
        title: `
        Complete the rules with the headings below.`,
        color: "#000000",
      },
    ],

    questions: [
      {
        title: `
        <div style="width:800px;font-size:25px;font-weight:bold;color:rgb(142,15,177);">
        <span>plural noun&ensp;&ensp;</span>
        <span> singular countable noun&ensp;&ensp;</span>
        <span>uncountable noun&ensp;&ensp;</span>
        </div>
       <div style='line-height:50px; margin-top:10px; font-size: 23px'>
       <b>1</b>&ensp;every, each, either + <input/> <br/>
       <b>2</b>&ensp;all, most, some, much, little, a little, any, no + <input/> <br/>
       <b>3</b>&ensp;all, most, many, some, a few, few, no, both, any + <input/> <br/>
       </div>

        `,
        answer: [`singular countable noun`, `uncountable noun`, `plural noun`],
      },
    ],
  },

  2: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P41-E2",
    exerciseKey: "img/FriendsPlus/Page41/Key/E2answerKey.png",
    component: Circle_Write,

    titleQuestion: [
      {
        num: "2",
        title: `Circle the correct answers to complete the sentences. 
				`,
        color: "#000000",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70, padding: 20 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 1,
        listWords: [
          `a b c d`,
          `a b c d`,
          `a b c d`,
          `a b c d`,
          `a b c d`,
          `a b c d`,
          `a b c d`,
          `a b c d`,
        ],
        answers: ["0-6", "1-4", "2-2", "3-2", "4-6", "5-2", "6-2", "7-2"],

        initialValue: [],
      },
      Layout: `

			<div style='position:relative'>
						<b>1</b>&ensp; I’ve only used__my pocket money. 
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='0' type='Circle'>
					</div>
						<b>2</b>&ensp; There isn’t__time before the film starts. 
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='1' type='Circle'>
					</div>
						<b>3</b>&ensp;Nearly__this software is out of date. 
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='2' type='Circle'>
					</div>
						<b>4</b>&ensp; __of the information is correct. 
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='3' type='Circle'>
					</div>
						<b>5</b>&ensp; The computer is very heavy. Pick it up with__hands. 
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='4' type='Circle'>
					</div>
						<b>6</b>&ensp;__teenagers use social networking sites.
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='5' type='Circle'>
					</div>
						<b>7</b>&ensp;Have you bought__apps recently? 
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='6' type='Circle'>
					</div>
						<b>8</b>&ensp; Joe spends__his free time playing games online. 
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='7' type='Circle'>
					</div>
						
<div style='position:absolute;top:37px;left:73px'>
						little <br><br>
						many <br><br>
						every <br><br>
						No <br><br>
						each <br><br>
						Much <br><br>
						some <br><br>
						most <br><br>
					</div>
					<div style='position:absolute;top:37px;left:260px'>
					a little <br><br>
					few <br><br>
					all of <br><br>
					None<br><br>
					all<br><br>
					Most<br><br>
					any<br><br>
					most of<br><br>
				</div>
					<div style='position:absolute;top:37px;left:440px'>
						a few<br><br>
						much<br><br>
						each of<br><br>
						Any<br><br>
						either<br><br>
						Many of<br><br>
						no<br><br>
						much<br><br>
					</div>
					<div style='position:absolute;top:37px;left:615px;width:150px'>
					a little of <br><br>
					little <br><br>
					every one of <br><br>
					Either <br><br>
						both<br><br>
						Most of <br><br>
						few <br><br>
						the most <br><br>
					</div>
			</div>

			


        
         `,
    },
  },
  3: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U5-P40-E3",
    exerciseKey: "img/FriendsPlus/Page41/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 700,
    stylesTextInput: {
      fontSize: "22px",
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				Some of the sentences are incorrect. Rewrite them correctly. Tick the correct sentences. 
              `,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 500 },
    // maxLength:1,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    selectStyle: {
      border: "solid 1px black",
      marginLeft: 10,
      height: 34,
      width: 37,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["✓", "✗"],
    questionImage: [],
    questions: [
      {
        title: `
				<div><i style='font-size:20px; color: red'>*If the sentence is correct, rewrite the full sentence.</i></div>
					<div style='display:flex'>
					<b>1</b>
					<div style='display:flex;margin-left:10px'>
					No of my classmates did their homework.
					<div style='width:35px;height:25x;border:1px solid black;'>
					<div style='text-align:center'>
					<b>✗</b>
					</div>
					</div>
					</div> 
					</div>
					<div style='margin-left:25px'>
					<span style='color:rgb(0,103,180)'>None of my classmates did their homework. </span>
					</div>

					<div style='display:flex'>
					<b>2</b>
					<div style='display:flex;margin-left:10px'>
					Some of gadgets are difficult to use.
					<select id=6></select> 
					</div> 
					</div>
					<div style='margin-left:25px;margin-top:-25px'>
					<input/>

					</div>

					<div style='display:flex'>
					<b>3</b>
					<div style='display:flex;margin-left:10px'>
					We had no time to lose. 
					<select id=7></select> 
					</div> 
					</div>
					<div style='margin-left:25px;margin-top:-25px'>
					<input/>

					</div>

					<div style='display:flex'>
					<b>4</b>
					<div style='display:flex;margin-left:10px'>
					Tom can write with every hand. 
					<select id=8></select> 
					</div> 
					</div>
					<div style='margin-left:25px;margin-top:-25px'>
					<input/>

					</div>

					<div style='display:flex'>
					<b>5</b>
					<div style='display:flex;margin-left:10px'>
					Not much games are easy to design. 
					<select id=9></select> 
					</div> 
					</div>
					<div style='margin-left:25px;margin-top:-25px'>
					<input/>

					</div>

					<div style='display:flex'>
					<b>6</b>
					<div style='display:flex;margin-left:10px'>
					There’s a mistake in each sentence. 
					<select id=10></select> 
					</div> 
					</div>
					<div style='margin-left:25px;margin-top:-25px'>
					<input/>

					</div>

					<div style='display:flex'>
					<b>7</b>
					<div style='display:flex;margin-left:10px'>
					Jason spends few money on downloading music. 
					<select id=11></select> 
					</div> 
					</div>
					<div style='margin-left:25px;margin-top:-25px'>
					<input/>

					</div>
		  
		  `,
        answer: [
          "Some of the gadgets are difficult to use.",
          "We had no time to lose.",
          "Tom can write with both hands.",
          "Not many games are easy to design. | Not all games are easy to design.",
          "There's a mistake in each sentence.",
          "Jason spends little money on downloading music.",
          "✗",
          "✓",
          "✗",
          "✗",
          "✓",
          "✗",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P41-E4",
    exerciseKey: "img/FriendsPlus/Page41/Key/E4answerKey.png",
    component: T6v2,
    inputSize: 150,
    stylesTextInput: {
      fontSize: "26px",
      textAlign: "center",
    },
    isHiddenCheck: true,
    maxLength: 8,
    // hideBtnFooter: true,
    textareaStyle: { width: 820 },
    titleQuestion: [
      {
        num: "4",
        title: `
				Complete the sentences with <i>few, a few, little,</i> or <i>a little</i>. 
				`,
        color: "#000000",
      },
    ],
    checkUppercase: true,
    questions: [
      {
        title: `
        <div style='line-height:2'>
        <div style='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        # people went to see the film, so it was only on at the cinema for a couple of weeks.  
        </div>
        </div>

        <div style='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        I’m tired because I got very # sleep last night.        </div>
        </div>

        <div style='display:flex'>
        <b>3</b>
        <div style='margin-left:10px'>
        ‘Is there any milk left?’ ‘Yes, # .’         </div>
        </div>

        <div style='display:flex'>
        <b>4</b>
        <div style='margin-left:10px'>
        I posted that comment # days ago.         </div>
        </div>

        <div style='display:flex'>
        <b>5</b>
        <div style='margin-left:10px'>
        I had # subscribers to my video blog, so I removed it from YouTube.         </div>
        </div>

        <div style='display:flex'>
        <b>6</b>
        <div style='margin-left:10px'>
        Unfortunately they have # money to spend on holidays.         </div>
        </div>
        </div>
        `,

        answer: [`Few`, `little`, `a little`, `a few`, `few`, `little`],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "FGF11-WB-demo-2023-U5-P41-E5",
    exerciseKey: "img/FriendsPlus/Page41/Key/E5answerKey.png",
    component: T6v2,
    inputSize: 950,
    stylesTextInput: {
      fontSize: "22px",
      textAlign: "left",
    },
    isHiddenCheck: true,
    // maxLength: 1,
    // hideBtnFooter: true,
    // textareaStyle: { width: 820 },
    checkUppercase: true,
    titleQuestion: [
      {
        num: "5",
        title: `
				Look at the bar chart. Write a sentence for each activity with the words below. Use the present perfect. 
				`,
        color: "#000000",
      },
    ],

    questions: [
      {
        title: `
        <div style="font-size: 23px; width:800px;font-size:25px;font-weight:bold;color:rgb(142,15,177);">
        <span>all&ensp;&ensp;</span>
        <span>almost all&ensp;&ensp;</span>
        <span>̶a̶ ̶f̶̶e̶̶w̶&ensp;&ensp;</span>
        <span>most&ensp;&ensp;</span>
        <span>none &ensp;&ensp;</span>
        <span>some &ensp;&ensp;</span>
        <span>very few &ensp;&ensp;</span>
        </div>
        <img src='img/FriendsPlus/Page41/E1/1.jpg' width='70%'/>
        <div style='line-height:45px; font-size: 22px; '>
        <b>1</b>&ensp;&ensp;  <span style='color: rgb(0,103,180);font-size:22px'>A few of the students have set up a new email account. </span> <br/>
        <b>2</b>&ensp; # <br/>
        <b>3</b>&ensp; # <br/>
        <b>4</b>&ensp; # <br/>
        <b>5</b>&ensp; # <br/>
        <b>6</b>&ensp; # <br/>
        <b>7</b>&ensp; # <br/>

        </div>
        `,

        answer: [
          `All of the students have played a computer game.`,
          `Almost all of the students have downloaded music.`,
          `None of the students have printed a document from their phone.`,
          `Very few of the students have searched within a specific website.`,
          `Most of the students have installed an app on their phone.`,
          `Some of the students have updated their profile on a social networking site.`,
        ],
      },
    ],
  },
};

export default json;
