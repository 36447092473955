import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "FGF11-U2-P20-E1",
    audio: "",
    video: "",
    component: T6,
    inputSize: 130,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 15,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page20/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the sports venues with the words below. Then use 
        six of them to label the photos.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 21,
      height: 27,
      // textTransform: 'uppercase',
    },
    hintBox: [
      {
        src: [
          "basketball",
          "bowling",
          "boxing",
          "court",
          "football",
          "golf",
          "ice",
          "room",
          "studio",
          "swimming",
          "track",
          "wall",
        ],
        borderColor: "none",
        width: 900,
        fontColor: "#93338e",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
				<div style="display: flex; font-size: 21px; margin-left: 100px">
					<div style="display: flex; margin-top: 10px; margin-right: 150px">
						<div style=''>
							<div><b>1&ensp;&ensp; </b></div>
							<div><b>2&ensp;&ensp; </b></div>
							<div><b>3&ensp;&ensp; </b></div>
							<div><b>4&ensp;&ensp; </b></div>
							<div><b>5&ensp;&ensp; </b></div>
							<div><b>6&ensp;&ensp; </b></div>
						</div>
			
						<div>
							<span><input id='0'>alley</span><br>
							<span><input id='1'>course</span><br>
							<span>athletics<input id='2'></span><br>
							<span><input id='3'>rink</span><br>
							<span>tennis<input id='4'></span><br>
							<span><input id='5'>ring</span><br>
						</div>
					</div>

					<div style="display: flex; margin-top: 10px; ">
						<div style='margin-right 30px'>
							<div><b>7&ensp;&ensp; </b></div>
							<div><b>8&ensp;&ensp; </b></div>
							<div><b>9&ensp;&ensp; </b></div>
							<div><b>10&ensp; </b></div>
							<div><b>11&ensp; </b> </div>
							<div><b>12&ensp; </b></div>
						</div>
			
						<div>
							<span><input id='6'>court</span><br>
							<span>dance<input id='7'></span><br>
							<span>climbing<input id='8'></span><br>
							<span><input id='9'>pitch</span><br>
							<span>weights<input id='10'></span><br>
							<span><input id='11'>pool</span><br>
						</div>
					</div>
				</div>
        <div style='position: relative; font-size: 21px; margin-left: 100px'>
        <img style="width:19cm; height:11cm" src='img/FriendsPlus/Page20/E1/1.jpg' />
        <div style='position:absolute; top:142px ;left:44px'><input border-bottom='none' width='160px' id='12'></div>
        <div style='position:absolute; top:205px ;left:267px'><input border-bottom='none' width='160px' id='13'></div>
        <div style='position:absolute; top:153px ;left:505px'><input border-bottom='none' width='160px' id='14'></div>
         
        <div style='position:absolute; top:331px ;left:35px'><input border-bottom='none' width='160px' id='15'></div>
        <div style='position:absolute; top:363px ;left:306px'><input border-bottom='none' width='160px' id='16'></div>
        <div style='position:absolute; top:305px ;left:506px'><input border-bottom='none' width='160px' id='17'></div>
        </div>
				`,
        answer: [
          `bowling`,
          `golf`,
          `track`,
          `ice`,
          `court`,
          `boxing`,
          `basketball`,
          `studio`,
          `wall`,
          `football`,
          `room`,
          `swimming`,

          `weights room`,
          `bowling alley`,
          `golf course`,
          `climbing wall`,
          `athletics track`,
          `ice rink`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FGF11-U2-P20-E2",
    // audio: 'Audios/1.07.mp3',
    video: "",
    component: T6,
    inputSize: 40,
    isHiddenCheck: true,
    maxLength: 1,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page20/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
				Match the words to make more compound nouns. Use each word only once.
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <div style=" position: relative; padding-top:10px; margin-left: 100px">
          <div><img style="width: 15cm; padding-top:30px;" src='img/FriendsPlus/Page20/E2/1.jpg';/></div>

          <div style=" position: absolute; top: 48px; left: 177px"><input id='0' ></input></div>
          <div style=" position: absolute; top: 81px; left: 177px"><input id='1' ></input></div>
          <div style=" position: absolute; top: 117px; left: 177px"><input id='2' ></input></div>
          <div style=" position: absolute; top: 151px; left: 177px"><input id='3' ></input></div>
          <div style=" position: absolute; top: 183px; left: 177px"><input id='4' ></input></div>
          <div style=" position: absolute; top: 214px; left: 177px"><input id='5' ></input></div>
          <div style=" position: absolute; top: 246px; left: 177px"><input id='6' ></input></div>

        </div>

				`,
        answer: [`d`, `g`, `a`, `f`, `e`, `c`, `b`],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FGF11-U2-P20-E3",
    // audio: 'Audios/1.02.mp3',
    video: "",
    component: T6,
    inputSize: 250,
    isHiddenCheck: true,
    maxLength: 105,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page20/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				<span style='font-size: 24px'>Complete the table with compound nouns from exercises 1 
        and 2. Write one under A, three under B and four under C.</span>
				`,
        color: "black",
      },
    ],

    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 21,
      height: 26,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
				<div style='display: flex'>
        <img style="width: 16cm" src='img/FriendsPlus/Page20/E3/1.png';/>
        <img style="width: 10cm" src='img/FriendsPlus/Page20/E3/2.png';/>
        </div>
       
        <div style=" position: relative; padding-top:10px; font-size: 22px; margin-left: 200px">
          
          <img style="width: 14cm" src='img/FriendsPlus/Page20/E3/3.jpg';/>
          
         

					<div style=" position: absolute; top: 92px; left: 20px">#</div>
          
          <div style=" position: absolute; top: 67px; left: 276px">#</div>
          <div style=" position: absolute; top: 92px; left: 276px">#</div>
          <div style=" position: absolute; top: 118px; left: 276px">#</div>
          
          <div style=" position: absolute; top: 187px; left: 140px">#</div>
          <div style=" position: absolute; top: 212px; left: 140px">#</div>
          <div style=" position: absolute; top: 237px; left: 140px">#</div>
          <div style=" position: absolute; top: 262px; left: 140px">#</div>
          
        </div>
        
        
        </div>
				`,
        answer: [
          `main road`,

          `bowling alley / boxing ring / climbing wall / swimming pool`,
          `bowling alley / boxing ring / climbing wall / swimming pool`,
          `bowling alley / boxing ring / climbing wall / swimming pool`,

          `golf course / athletics track / ice rink / tennis court / basketball court / dance studio / football pitch / weights room / mountain range / floodlights / sea shore / tennis player / safety net / tower block`,
          `golf course / athletics track / ice rink / tennis court / basketball court / dance studio / football pitch / weights room / mountain range / floodlights / sea shore / tennis player / safety net / tower block`,
          `golf course / athletics track / ice rink / tennis court / basketball court / dance studio / football pitch / weights room / mountain range / floodlights / sea shore / tennis player / safety net / tower block`,
          `golf course / athletics track / ice rink / tennis court / basketball court / dance studio / football pitch / weights room / mountain range / floodlights / sea shore / tennis player / safety net / tower block`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FGF11-U2-P20-E4",
    // audio: 'Audios/1.07.mp3',
    video: "",
    component: T6,
    inputSize: 17,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 4,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page20/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `
				Complete the compound adjectives in the sentences with the words below. 
				`,
        color: "black",
      },
    ],
    hintBox: [
      {
        src: ["air", "full", "open", "well"],
        borderColor: "none",
        width: 400,
        fontColor: "#93338e",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      width: 100,
      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style="display: flex; font-size: 23px; margin-left: 100px; line-height: 50px">
						<div style=''>
							<div><b>1&ensp;&ensp; </b></div>
							<div><b>2&ensp;&ensp; </b></div>
							<div><b>3&ensp;&ensp; </b></div>
							<div><b>4&ensp;&ensp; </b></div>
						</div>
			
						<div>
							<span>It’s an amazing house. There’s a #-sized 
              bowling alley in the basement!</span><br>
							<span>There’s also a new #-air swimming pool in the 
              garden.</span><br>
							<span>Is it healthy to spend all day in #-conditioned 
              offices?</span><br>
							<span>The gym has a #-equipped weights room.</span><br>
							
						</div>
					</div>

				</div>
        
				`,
        answer: [`full`, `open`, `air`, `well`],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FGF11-U2-P20-E5",
    // audio: 'Audios/1.07.mp3',
    video: "",
    component: T6,
    inputSize: 350,
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 35,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page20/Key/E5answerKey.png",
    titleQuestion: [
      {
        num: "5",
        title: `
				Read the <i style='color: #93338e'>Vocab boost!</i> box. Then complete the second part 
        of each sentence with an explanation. 
				`,
        color: "black",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,

      // textTransform: 'uppercase',
    },

    questions: [
      {
        title: `
        <img style="width:14cm; height:8cm" src='img/FriendsPlus/Page20/E5/1.jpg' />
        <div style="display: flex; font-size: 22px; margin-left: 50px; line-height: 40px">
						<div style=''>
							<div><b>1&ensp;&ensp; </b></div>
							<div><b>2&ensp;&ensp; </b></div>
							<div><b>3&ensp;&ensp; </b></div>
							<div><b>4&ensp;&ensp; </b></div>
              <div><b>5&ensp;&ensp; </b></div>
						</div>
			
						<div>
              <span>A three-headed monster is a monster <span style='color: rgb(152,198,204)'>with three heads</span>.</span><br>
							<span>A man-eating lion is a lion #.</span><br>
							<span>A million-dollar apartment is an apartment #.</span><br>
							<span>A five-storey house is a house #.</span><br>
							<span>A well-prepared student is a student #.</span><br>
							
						</div>
					</div>

				</div>
        
				`,
        answer: [
          `which eats men`,
          `which costs a million dollars`,
          `with five storeys`,
          `who has prepared well`,
        ],
      },
    ],
  },
};

export default json;
