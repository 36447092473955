import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P92-E1",
    audio: "",
    video: "",
    component: T6v2,
    maxLength: 13,
    inputSize: 170,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page92/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",

        title: `
				Match the words in <span style='color:rgb(12 177 242)'>blue</span> in the fact sheet
				with definitions 1–7. 
				
									`,
        color: "#e22f3b",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display:flex'>
						<div style='margin-right:10px'>
							<b>1.</b>&ensp;changes from liquid into gas #<br>
							<b>2.</b>&ensp;changes from gas into liquid #<br>
							<b>3.</b>&ensp;rainfall #<br>
							<b>4.</b>&ensp;water when it exists in the air #<br>
							<b>5.</b>&ensp;the part of the land or body of water at the top #<br>
							<b>6.</b>&ensp;ice is in this state #<br>
							<b>7.</b>&ensp;water at room temperature is this #<br>
						</div>
						<img src='img/FriendsPlus/Page92/E1/1.jpg'>

					</div>
			
									`,
        answer: [
          "evaporates",
          "condenses",
          "precipitation",
          "vapour",
          "surface",
          "solid",
          "liquid",
        ],
      },
    ],
  },
  2: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P92-E2",
    audio: "",
    video: "",
    component: T6v2,
    // maxLength: 13,
    inputSize: 170,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page92/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",

        title: `
				Read the fact sheet. What happens to water
when it falls to the Earth? 
				
									`,
        color: "#e22f3b",
      },
    ],
    textareaStyle: { width: 500 },
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display:flex'>
						<div style='margin-right:10px'>
							<u style='font-weight:bold;font-style:italic'>Answers:</u><br>
							<textarea id='0' rows='4'></textarea>
						</div>
						<img src='img/FriendsPlus/Page92/E1/1.jpg'>

					</div>
			
									`,
        answer: [
          "Some of the water runs into rivers, lakes and streams and becomes surface water. Some enters the ground and forms underground rivers or lakes.",
        ],
      },
    ],
  },
  3: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P92-E3",
    audio: "",
    video: "",
    // recorder: true,
    component: T6v2,
    // maxLength: 13,
    inputSize: 170,
    textAlign: "center",
    stylesTextInput: {
      fontSize: 19,
      background: "transparent",
      borderBottom: "none",
    },
    exerciseKey: "img/FriendsPlus/Page92/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",

        title: `
				Label the diagram with the words in the box. 
				
									`,
        color: "#e22f3b",
      },
    ],
    textareaStyle: { width: 500 },
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style='position:relative;margin-left:20px'>
      <div style="width:720px;border: 3px solid rgb(220,44,55); display: flex; flex-wrap: wrap;justify-content:center; align-items:center;margin-bottom:20px; padding: 20px 10px 10px 40px; margin-top: 10px; border-radius: 40px;">
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">condensation</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">evaporation</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">precipitation</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">water returns to the sea</p>
			
			</div>
				<img  src="img/FriendsPlus/Page92/E3/1.jpg" />
				<span style='position: absolute; top: 480px; left: 233px'>
					<input id='0' width='240px'></input>
				</span>
				<span style='position: absolute; top: 438px; left: 445px'>
					#
				</span>
				<span style='position: absolute; top: 168px; left: 344px'>
					#
				</span>
				<span style='position: absolute; top: 238px; left: 79px'>
					#
				</span>
			</div>
		
			
									`,
        answer: [
          "water returns to the sea",
          "evaporation",
          "condensation",
          "precipitation",
        ],
      },
    ],
  },
  4: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P92-E4",
    audio: "Audios/3.04.mp3",
    video: "",
    // recorder: true,
    component: T6v2,
    // maxLength: 4,
    inputSize: 670,
    exerciseKey: "img/FriendsPlus/Page92/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",

        title: `
				<headphone name="3.04"></headphone >
				Read and listen to the fact sheet
again. Answer the questions. 
									`,
        color: "#e22f3b",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    // isHiddenCheck: true,
    textareaStyle: {
      width: 500,
    },
    questions: [
      {
        title: `
					<div style='display:flex'>
						<div style='margin-right:10px'>
							<div>
								<b>1.</b>&ensp;What is true about the amount of water on
								Earth?<br>#
							</div>
							<div>
								<b>2.</b>&ensp;Why does water vapour condense?<br>#
							</div>
							<div>
								<b>3.</b>&ensp;What happens to a glass of water on a hot
								day?<br>#
							</div>
							<div>
								<b>4.</b>&ensp;Name two forms of precipitation. Do you
								know any more?<br>#
							</div>
							<div>
								<b>5.</b>&ensp;What are the three states that water can
								be in?<br>#
							</div>
						</div>
						<img src='img/FriendsPlus/Page92/E1/1.jpg'>

					</div>
			
									`,
        answer: [
          "The amount of water on Earth doesn't change over time.",
          "Water vapour condenses when it cools.",
          "Warm, moist air condenses on the cold glass.",
          "rain, snow",
          "solid (ice), liquid (water), gas (steam / vapour)",
        ],
      },
    ],
  },
  5: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P92-E5",
    audio: "",
    video: "",
    component: T6,
    textareaStyle: { width: 740 },
    hideBtnFooter:true,
    titleQuestion: [
      {
        num: "5",
        title: `Work in pairs. Create a poster
        to show what happens in the water cycle.<br>
        Include information from this page.`,
        color: "#e22f3b",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questions: [
      {
        title: `
          
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
