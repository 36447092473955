import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P49-E1",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page49/E1/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 1000,
    maxLength: 200,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "left",
      fontSize: "22px",
      // fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: "1",
        // star:1,
        title: `
		    Finish each of the following sentences emphasising the
        underlined information. 
							`,
        color: "#262324",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			  <div style='line-height: 40px'>
         <div style='font-size: 22px'>
           <span style= "font-weight:bold; margin-right: 15px">1</span>
           <span>Mia isn't coming shopping with us. </span>
           <spans style= "border-bottom: 2px solid gray">Lara</spans>
           <span> is.</span> <br>
           <span style= "margin-left: 30px">It's Lara who's coming with us, not Mia.</span>
         </div>

         <div style='font-size: 22px'>
           <span style= "font-weight:bold; margin-right: 15px">2</span>
           <span>I haven't got a credit card. I've got </span>
           <span style= "border-bottom: 2px solid gray">a debit card</span>
           <span>.</span> <br>
           <span style= "margin-left: 30px">It</span>
           <input id= "0">
         </div>

         <div style='font-size: 22px'>
           <span style= "font-weight:bold; margin-right: 15px">3</span>
           <span>The first supermarket opened in </span>
           <span style= "border-bottom: 2px solid gray">1916</span>
           <span>. It wasn't 1920. </span> <br>
           <span style= "margin-left: 30px">It</span>
           <input id= "1">
         </div>

         <div style='font-size: 22px'>
           <span style= "font-weight:bold; margin-right: 15px">4</span>
           <span>Her mum doesn't work in a store. </span>
           <span style= "border-bottom: 2px solid gray">Her aunt</span> 
           <span> does. </span> <br>
           <span style= "margin-left: 30px">It</span>
           <input id= "2">
         </div>

         <div style='font-size: 22px'>
           <span style= "font-weight:bold; margin-right: 15px">5</span>
           <span>Many people don't want quality. They want </span>
           <span style= "border-bottom: 2px solid gray">low prices</span>
           <span>.</span> <br>
           <span style= "margin-left: 30px">It</span>
           <input id= "3">
         </div>

         <div style='font-size: 22px'>
           <span style= "font-weight:bold; margin-right: 15px">6</span>
           <span>The sales start </span>
           <span style= "border-bottom: 2px solid gray">on Friday</span>
           <span>. They don't start today.</span> <br>
           <span style= "margin-left: 30px">It</span>
           <input id= "4">
         </div>

         <div style='font-size: 22px'>
           <span style= "font-weight:bold; margin-right: 15px">7</span>
           <span>I blame </span>
           <span style= "border-bottom: 2px solid gray">teenagers</span>
           <span> for the litter. I don't blame the restaurants.</span> <br>
           <span style= "margin-left: 30px">It</span>
           <input id= "5">
         </div>

         <div style='font-size: 22px'>
           <span style= "font-weight:bold; margin-right: 15px">8</span>
           <span>Amoruso resigned from the job in the shoe shop </span>
           <span style= "border-bottom: 2px solid gray">because she found it boring</span>
           <span>.</span> <br>
           <span style= "margin-left: 30px">It</span>
           <input id= "6">
         </div>
        </div>
							`,
        answer: [
          "'s a debit card that I've got, not a credit card.",
          "was in 1916 that the first supermarket opened, not 1920.",
          "'s her aunt that works in a store, not her mum.",
          "'s low prices that many people want, not quality.",
          "'s on Friday that the sales start, not today.",
          "'s teenagers that I blame for the litter, not the restaurants.",
          "was because Amoruso found it boring that she resigned from the job in the shoe shop.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P49-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page49/E2/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 800,
    maxLength: 200,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "left",
      fontSize: "22px",
      // fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: "2",
        // star:1,
        title: `
		    Complete the second sentence so that it means the same as the first. 
							`,
        color: "#262324",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			  <div style='font-size: 22px; line-height: 45px'>
         <div>
           <span style= "font-weight:bold; margin-right: 15px">1</span>
           <span>Chris lost his phone yesterday. </span> <br>
           <span style= "margin-left: 30px">It was Chris </span>
           <input id= "0" width= "680px">.
         </div>

         <div>
           <span style= "font-weight:bold; margin-right: 15px">2</span>
           <span>Ella is getting her hair cut tomorrow.</span> <br>
           <span style= "margin-left: 30px">It's tomorrow</span>
           <input id= "1" width= "677px">.
         </div>

         <div>
           <span style= "font-weight:bold; margin-right: 15px">3</span>
           <span>His grandparents moved to New Zealand last year.</span> <br>
           <span style= "margin-left: 30px">It's New Zealand</span>
           <input id= "2" width= "637px">.
         </div>

         <div>
           <span style= "font-weight:bold; margin-right: 15px">4</span>
           <span>My brother wasn't born until 2010.</span> <br>
           <span style= "margin-left: 30px">It wasn't until</span>
           <input id= "3" width= "677px">.
         </div>

         <div>
           <span style= "font-weight:bold; margin-right: 15px">5</span>
           <span>Nick has been learning to ride a motorbike.</span> <br>
           <span style= "margin-left: 30px">It's a</span>
           <input id= "4" width= "766px">.
         </div>

         <div>
           <span style= "font-weight:bold; margin-right: 15px">6</span>
           <span>I have guitar lessons on Saturday mornings.</span> <br>
           <span style= "margin-left: 30px">It's on</span>
           <input id= "5" width= "753px">.
         </div>

         <div>
           <span style= "font-weight:bold; margin-right: 15px">7</span>
           <span>7 We saw Roberto at the club last night.</span> <br>
           <span style= "margin-left: 30px">It was Roberto</span>
           <input id= "6" width= "662px">.
         </div>

         <div>
           <span style= "font-weight:bold; margin-right: 15px">8</span>
           <span>They don't enjoy watching sci-fi films.</span> <br>
           <span style= "margin-left: 30px">It's sci-fi films</span>
           <input id= "7" width= "676px">.
         </div>
        </div>
							`,
        answer: [
          "who lost his phone yesterday | that lost his phone yesterday",
          "that Ella is getting her hair cut",
          "that his grandparents moved to last year",
          "2010 that my brother was born",
          "motorbike that Nick has been learning to ride",
          "Saturday mornings that I have guitar lessons",
          "who we saw at the club last night | that we saw at the club last night",
          "that they don't enjoy watching",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P49-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page49/E3/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 50,
    maxLength: 1,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
      // fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: "3",
        // star:1,
        title: `
        Match 1–7 with a–g to make meaningful sentences.
							`,
        color: "#262324",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="display:flex">
          <div style="margin-right: 180px">
           <div>
             <span style="font-weight: bold; margin-right: 15px">1</span>
             <span>It's leather</span>
           </div>
           <div>
             <span style="font-weight: bold; margin-right: 15px">2</span>
             <span>It's at 9 p.m</span>
           </div>
           <div>
             <span style="font-weight: bold; margin-right: 15px">3</span>
             <span>It was the Egyptians</span>
           </div>
           <div>
             <span style="font-weight: bold; margin-right: 15px">4</span>
             <span>It's the blue dress</span>
           </div>
           <div>
             <span style="font-weight: bold; margin-right: 15px">5</span>
             <span>It's a Porsche</span>
           </div>
           <div>
             <span style="font-weight: bold; margin-right: 15px">6</span>
             <span>It's Dan</span>
           </div>
           <div>
             <span style="font-weight: bold; margin-right: 15px">7</span>
             <span> It's in June</span>
           </div>
          </div>

          <div>
           <div>
             <span style="font-weight: bold; margin-right: 15px">a</span>
             <span> that schools finish</span>
           </div>
           <div>
             <span style="font-weight: bold; margin-right: 15px">b</span>
             <span>that this bag is made of</span>
           </div>
           <div>
             <span style="font-weight: bold; margin-right: 15px">c</span>
             <span>that is going on holiday to Turkey</span>
           </div>
           <div>
             <span style="font-weight: bold; margin-right: 15px">d</span>
             <span>that the film finishes</span>
           </div>
           <div>
             <span style="font-weight: bold; margin-right: 15px">e</span>
             <span>that I prefer</span>
           </div>
           <div>
             <span style="font-weight: bold; margin-right: 15px">f</span>
             <span>that built the Pyramids</span>
           </div>
           <div>
             <span style="font-weight: bold; margin-right: 15px">g</span>
             <span>that Tom drives</span>
           </div>
          </div>
        </div>

        <div style="margin-top: 30px">
            <span style="font-weight: bold">1</span> <input id="0">
            <span style="font-weight: bold; margin-left: 35px">2</span> <input id="1">
            <span style="font-weight: bold; margin-left: 35px">3</span> <input id="2">
            <span style="font-weight: bold; margin-left: 35px">4</span> <input id="3">
            <span style="font-weight: bold; margin-left: 35px">5</span> <input id="4">
            <span style="font-weight: bold; margin-left: 35px">6</span> <input id="5">
            <span style="font-weight: bold; margin-left: 35px">7</span> <input id="6">
          </div>
							`,
        answer: ["b", "d", "f", "e", "g", "c", "a"],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P49-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page49/E4/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 750,
    maxLength: 200,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "left",
      fontSize: "22px",
      // fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: "4",
        // star:1,
        title: `
		    Make cleft sentences from the following cues.
							`,
        color: "#262324",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 48px'>

        <span style= "font-weight: bold; margin-right: 15px">1</span>
        <span>It / be / Eva / own / that dog</span> <br>
        <input id="0">. <br>

        <span style= "font-weight: bold; margin-right: 15px">2</span>
        <span>It/ be / spiders / I / can't stand</span> <br>
        <input id="1">. <br>

        <span style= "font-weight: bold; margin-right: 15px">3</span>
        <span>It / be / Japan / they / go to / two years ago</span> <br>
        <input id="2">. <br>

        <span style= "font-weight: bold; margin-right: 15px">4</span>
        <span>It / be / the singer Adele / she / love / most</span> <br>
        <input id="3">. <br>

        <span style= "font-weight: bold; margin-right: 15px">5</span>
        <span>It / be / the colour green / Laila / not like</span> <br>
        <input id="4">. <br>

        <span style= "font-weight: bold; margin-right: 15px">6</span>
        <span>It / be / octopus / I'll / never try</span> <br>
        <input id="5">. <br>

        <span style= "font-weight: bold; margin-right: 15px">7</span>
        <span>It / be / last winter / he / go snowboarding</span> <br>
        <input id="6">. <br>

        </div>
							`,
        answer: [
          "It is Eva that owns that dog | It is Eva who owns that dog",
          "It is spiders that I can't stand",
          "It was Japan that they went to two years ago",
          "It is the singer Adele that she loves most | It is the singer Adele who she loves most",
          "It is the colour green that Laila doesn't like",
          "It is octopus that I'll never try",
          "It was last winter that he went snowboarding",
        ],
      },
    ],
  },
};

export default json;
