import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P61-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page61/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 130,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "23px",
      fontWeight: "bold",
      // borderBottom: 'dotted'
    },
    titleQuestion: [
      {
        num: "1",

        title: `
        Complete the definitions of things you can find in the street.
	
									`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div>
          <div style='display:flex'>
          <b>1</b>
          <div style='margin-left:20px'>
          <span>a place where you park your bike:</span> <br>
          <span>b#r#</span>
          </div>
          </div>

          <div style='display:flex'>
          <b>2</b>
          <div style='margin-left:20px'>
          <span>a piece of furniture on which a number of people can sit:</span> <br>
          <span>b#</span>
          </div>
          </div>

          <div style='display:flex'>
          <b>3</b>
          <div style='margin-left:20px'>
          <span>the area at the side of the road where pedestrians walk:</span> <br>
          <span>p#</span>
          </div>
          </div>

          <div style='display:flex'>
          <b>4</b>
          <div style='margin-left:20px'>
          <span>a tall post with a strong light on the top:</span> <br>
          <span>l#p#</span>
          </div>
          </div>

          <div style='display:flex'>
          <b>5</b>
          <div style='margin-left:20px'>
          <span>a place where you can ring somebody:</span> <br>
          <span>p#b#</span>
          </div>
          </div>

          <div style='display:flex'>
          <b>6</b>
          <div style='margin-left:20px'>
          <span>a model of somebody, usually made of stone:</span> <br>
          <span>s#</span>
          </div>
          </div>

          <div style='display:flex'>
          <b>7</b>
          <div style='margin-left:20px'>
          <span>a machine at the side of the road where you pay to park your car:</span> <br>
          <span>p#m#</span>
          </div>
          </div>

          <div style='display:flex'>
          <b>8</b>
          <div style='margin-left:20px'>
          <span>a place where you catch a form of public transport:</span> <br>
          <span>b#s#</span>
          </div>
          </div>

          <div style='display:flex'>
          <b>9</b>
          <div style='margin-left:20px'>
          <span>something which tells drivers not to go:</span> <br>
          <span>s#s#</span>
          </div>
          </div>

          <div style='display:flex'>
          <b>10</b>
          <div style='margin-left:20px'>
          <span>a jet or stream of water usually coming from a pool:</span> <br>
          <span>f#</span>
          </div>
          </div>
      </div>
									`,
        answer: [
          `icycle`,
          `ack`,
          `ench`,
          `avement`,
          `amp`,
          `ost`,
          `hone`,
          `ox`,
          `culpture`,
          `arking`,
          `etre`,
          `us`,
          `top`,
          `top`,
          `ign`,
          `ountain`,
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P9-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page61/Key/E2answerKey.png",
    inputSize: 540,
    component: T6,
    // totalInput: 2,
    titleQuestion: [
      {
        num: "2",
        title: `Read the text. Are the sentences true (T) or false (F)?`,
        color: "black",
      },
    ],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    stylesTextInput: {
      // borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
      textTransform: "uppercase",
    },
    questions: [
      {
        title: `
            <img src='img/FriendsPlus/Page61/E2/1.jpg' width='100%'>
          <div style='line-height:2.5'>
            <div><b>The speaker:</b></div> 
    
            <div><b>1</b>&ensp; Łódź was famous before the work of the Urban Forms Foundation. # </div>
    
            <div><b>2</b>&ensp; The Foundation’s art project didn’t cost anything.  # </div>
    
            <div><b>3</b>&ensp; The project is now completed. # </div>
    
          </div>
            `,
        answer: [`F`, `F`, `F`],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "FGF11-SB-demo-2023-U7-P61-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page61/key/E3answerKey.png",
    component: Circle_Write,
    isHiddenCheck: true,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "22px",
      // fontWeight: 'bold',
      borderBottom: "dotted",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Read the <span style='color:rgb(83,80,153)'>Reading Strategy</span>. Circle the correct answers.
					`,
        color: "black",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 200, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px 10px",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "5px 10px",

          border: "2px solid black",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c <br> d`, //1
          `a <br> b <br> c <br> d`, //1
          `a <br> b <br> c <br> d`, //1
          `a <br> b <br> c <br> d`, //1
        ],
        answers: ["0-12", "1-4", "2-8", "3-0"],
        initialValue: [],
      },
      Layout: `
      <img src="/img/FriendsPlus/Page61/E3/3.jpg" style='width: 15cm; height: 9cm' >
      <div style='display:flex; line-height: 50px; font-size: 22px'>
        <div style ='flex:1;width:100%; margin-top:20px'>
           <div style='display:flex;'>
           <b>1</b>&ensp; Two people started an organisation in Łódź to
           </div>
            <div style="display:flex;margin-left:40px">
		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px;'>
              provide more industry in the city. <br>
              rebuild the city centre. <br>
              hold more art exhibitions.<br>
              improve the look of the city.
            </div>
            </div>

             <div style='display:flex;'>
                <b>2</b>&ensp; The Urban Forms Foundation
            </div>
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      asked the mayor to help.<br>
              needed the city council to agree to the project.<br>
              asked the council for money for their idea.<br>
              told the city council what to do.
		          </div>
            </div>

            <div style='display:flex;'>
            <b>3</b>&ensp; Famous street artists
            </div>
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
              weren’t interested at first in the project. <br>
              from abroad only were invited. <br>
              were asked to take part in the project.<br>
              asked the Foundation for work.
		          </div>
            </div>

            <div style='display:flex;'>
            <b>4</b>&ensp; Because of the Foundation’s work,
            </div>
             <div style="display: flex;">
            <div style="display: flex;margin-left:40px">
            	<div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      the city has become famous in the art world.<br>
              the city now has more street art than New York.<br>
              tourists are allowed to paint on the city walls.<br>
              there is no need for more artwork in the city.
		          </div>
            </div>
           </div>

          
    </div>


        
      `,
    },
  },
  4: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P61-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page61/key/E4answerKey.png",
    component: T6v2,
    maxLength: 13,
    inputSize: 150,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      // borderBottom: '1px solid red',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
      borderBottom: "dotted",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
        Complete the dialogue using the past simple or past continuous form of the verbs in brackets.
         
								`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
    <div style='line-height:2'>
        <div style='display:flex'> <b>Hazel</b> <span style='margin-left:20px'>I <b><u>rang</u></b> (ring) you last night, but you <sup>1</sup> # (not answer). <sup>2</sup> # (you / be) out?</span></div>

        <div style='display:flex'> <b>Leah</b> <span style='margin-left:20px'>Oh, I’m sorry. I think I <sup>3</sup> # (listen) to music when you <sup>4</sup> # (call). I <sup>5</sup> # (not know) you <sup>6</sup> # (want) to talk.</span></div>

        <div style='display:flex'> <b>Hazel</b> <span style='margin-left:20px'>It’s OK. I <sup>7</sup> # (have) a question about our homework, but I <sup>8</sup> # (phone) Alisha instead.</span></div>

        <div style='display:flex'> <b>Leah</b> <span style='margin-left:20px'>I <sup>9</sup> # (think) she <sup>10</sup> # (see) a film with her friends last night?</span></div>

        <div style='display:flex'> <b>Hazel</b> <span style='margin-left:20px'>No, she <sup>11</sup> # (not be). She and her sister <sup>12</sup> # (relax) at home.</span></div>

        <div style='display:flex'> <b>Leah</b> <span style='margin-left:20px'>Oh. Next time, send me a text message and then I’ll listen out for your call!</span></div>

        <div style='display:flex'> <b>Hazel</b> <span style='margin-left:20px'>Thanks – I will!</span></div>
    </div>
		
	
			
								`,
        answer: [
          `didn't answer`,
          `Were you`,
          `was listening`,
          `called`,
          `didn't know`,
          `wanted`,
          `had`,
          `phoned`,
          `thought`,
          `was seeing`,
          `wasn't`,
          `were relaxing`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P61-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Work in pairs. Which of these school issues are the same now as in the
        past? Which will be different in the future? Use can, can’t, could, couldn’t or will / won’t
        be able to
        `,
        color: "black",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="display: flex; flex-wrap: wrap; gap: 40px;margin-top:30px">

          <div>
            <span style="font-size: 25px;font-weight:bold;margin-right:10px">1</span>
            <span style="font-size: 25px">choice of subjects</span>
          </div>

          <div>
            <span style="font-size: 25px;font-weight:bold;margin-right:10px">2</span>
            <span style="font-size: 25px">ways of learning</span>
          </div>

          <div>
            <span style="font-size: 25px;font-weight:bold;margin-right:10px">3</span>
            <span style="font-size: 25px">school uniforms</span>
          </div>

          <div>
            <span style="font-size: 25px;font-weight:bold;margin-right:10px">4</span>
            <span style="font-size: 25px">class size</span>
          </div>

          <div>
            <span style="font-size: 25px;font-weight:bold;margin-right:10px">5</span>
            <span style="font-size: 25px">rules</span>
          </div>

        </div>

        <div style="margin-left: 100px;margin-top: 50px"><img src="img/FriendsPlus/Page61/11.jpg"/></div>


        `,
        answer: [],
      },
    ],
  },
};

export default json;
