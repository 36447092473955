import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P29-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page29/key/E1answerkey.png",
    component: T6v2,
    inputSize: 135,
    stylesTextInput: {
      // borderStyle: 'dotted',
      fontSize: "25px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the sentences with the words below.
Use the same word in each pair of sentences.
				`,
        color: "rgb(0,0,0)",
      },
    ],
    // textareaStyle: {
    // 	width: 800
    // },
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<p style="font-size:25px;font-weight:bold;color:rgb(142,15,177);word-spacing:15px">exercise record rest show work</p>
				<div style='line-height:1.75'>
				<div style='display:flex'>
    			<b>1</b>
    			<div style='display:flex'>
      			 <div style='margin-left:10px'> <b>a</b> Usain Bolt set a new world <input/> at the Olympic Games.<br/>
				 <b>b</b> You can <input/> a message for someone to listen to later. </div> 
        
    			</div>
				</div>

				<div style='display:flex'>
    			<b>2</b>
    			<div style='display:flex'>
      			 <div style='margin-left:10px'> <b>a</b> Look at <input/> 3 on page 78. <br/>
				 <b>b</b> You need to <input/> to stay fit.  </div> 
        
    			</div>
				</div>

				<div style='display:flex'>
    			<b>3</b>
    			<div style='display:flex'>
      			 <div style='margin-left:10px'> <b>a</b> This MP3 player doesn’t <input/>. It’s broken. <br/>
				 <b>b</b>  Does your mum <input/> at the hospital?  </div> 
        
    			</div>
				</div>

				<div style='display:flex'>
    			<b>4</b>
    			<div style='display:flex'>
      			 <div style='margin-left:10px'> <b>a</b> There’s a great quiz <input/> on TV tonight. <br/>
				 <b>b</b>  Can you <input/> me the way to the library?  </div> 
        
    			</div>
				</div>

				<div style='display:flex'>
    			<b>5</b>
    			<div style='display:flex'>
      			 <div style='margin-left:10px'> <b>a</b> I’m exhausted. I need a <input/>!<br/>
				 <b>b</b> How will you spend the <input/> of the day?
				 </div> 
        
    			</div>
				</div>
				</div>


				
				
				
        `,
        answer: [
          `record`,
          `record`,
          `exercise`,
          `exercise`,
          `work`,
          `work`,
          `show`,
          `show`,
          `rest`,
          `rest`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P29-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page29/key/E2answerkey.png",
    component: T6v2,
    inputSize: 500,
    stylesTextInput: {
      // borderStyle: 'dotted',
      fontSize: "24px",
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
				Read the text. Does the research support the theory that students perform better if school starts later? 
				`,
        color: "rgb(0,0,0)",
      },
    ],
    textareaStyle: {
      width: 800,
    },
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div>Does the research support the theory that students perform better if school starts later? <br> <input/> </div><br/>
				
				<img src='img/FriendsPlus/Page29/E2/1.jpg'width='100%'>

        `,
        answer: [`Yes.`],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P29-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page29/key/E3answerkey.png",
    component: T6v2,
    inputSize: 50,
    stylesTextInput: {
      // borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				Match paragraphs A–C with questions 1–4 below. One paragraph matches two questions. 
				`,
        color: "rgb(0,0,0)",
      },
    ],
    maxLength: 1,
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<b>In which paragraph does the author … 	</b> <br>
				<div style='line-height:2.25'>
				<b>1</b>&ensp; suggest that students might be safer if the school start  time were changed? <input/> <br>
				<b>2</b>&ensp; refer to evidence that shows that adults’ body  clocks are different from those of teenagers? <input/> <br> 
				<b>3</b>&ensp; quote the results of a study that confirms the  conclusions of British scientists? <input/> <br>
				<b>4</b>&ensp; mention some practical problems that might result  from a change in the start time? <input/> <br>

				</div>
        `,
        answer: ["C", "A", "B", "C"],
      },
    ],
  },
};

export default json;
