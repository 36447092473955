import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P65-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page65/key/E1answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    maxLength: 40,
    inputSize: 300,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: "1",

        title: `
					
        Complete the third conditional sentences with the verbs in brackets.
											`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					
				<div style='line-height:50px; font-size: 22px'>

        <div style='display:flex'><b>1.</b>&ensp; <span>If I # (be) hungry, I # (buy) a sandwich in the buffet car.</span> </div>

        <div style='display:flex'><b>2.</b>&ensp; <span>We # (book) a cabin if they # (not be) so expensive.</span> </div>

        <div style='display:flex'><b>3.</b>&ensp; <span>If we # (stop) at the filling station, we # (not run out) of petrol.</span> </div>

        <div style='display:flex'><b>4.</b>&ensp; <span>If you # (look) at the departures board, you # (know) which gate to go to.</span> </div>

        <div style='display:flex'><b>5.</b>&ensp; <span>What # you # (do) if the airline # (lose) your luggage?</span> </div>

        <div style='display:flex'><b>6.</b>&ensp; <span>We # (not go) out on deck if the weather # (not be) so lovely.</span> </div>

        <div style='display:flex'><b>7.</b>&ensp; <span>If you # (find) a bag on a train, # you # (take) it to the lost property office?</span> </div>
        
					
				</div>
						
											`,
        answer: [
          `had been`,
          `would have bought`,
          `would have booked`,
          `hadn't been`,
          `had stopped`,
          ` wouldn't have run out`,
          `had looked,`,
          ` would have known`,
          `would`,
          `have done`,
          `had lost`,
          `wouldn't have gone`,
          `hadn't been`,
          `had found`,
          `would`,
          `have taken`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P65-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page65/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 8,
    inputSize: 150,
    textareaStyle: { width: 1100, padding: 0 },
    stylesTextInput: {
      // borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },
    maxLength: 1,

    titleQuestion: [
      {
        num: "2",

        title: `
        Match the sentence halves. Then rewrite them as the third conditional sentences.
											`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
   <div style='display:flex;'>
        <div style='line-height:2.5'>
          <div style='display:flex'> <b>1</b> <span style='margin-left:20px'> We missed the ferry because </span> </div>
        <div style='display:flex'> <b>2</b> <span style='margin-left:20px'> We got lost because </span></div>

        <div style='display:flex'> <b>3</b> <span style='margin-left:20px'> I couldn’t get on the plane because </span></div>

        <div style='display:flex'> <b>4</b> <span style='margin-left:20px'> The train was late because </span></div>

        <div style='display:flex'> <b>5</b> <span style='margin-left:20px'> The car crashed because </span></div>

        <div style='display:flex'> <b>6</b> <span style='margin-left:20px'> We didn’t take the cable car because </span></div>

          </div>
        <div style='line-height:2;margin-top:5px'>
        <div style='margin-left:5px;width: 40px;height: 40px;
        text-align:center;justify-content:center;
        border:1px solid black;align-items:center;color:blue'>e</div>
      <div style='line-height:2.5;margin-top:15px' >
        #<br>
        #<br>
        #<br>
        #<br>
        #<br>
      </div>
        </div>


        <div style='line-height:2.5;margin-left:100px'>
          <div><b>a</b>&ensp; I didn’t have my passport with me.</div>
          <div><b>b</b>&ensp; we wanted to walk up the mountain.</div>
          <div><b>c</b>&ensp; you forgot the satnav.</div>
          <div><b>d</b>&ensp; the driver didn’t see the red light.</div>
          <div><b>e</b>&ensp; ̶w̶̶e̶ ̶w̶̶e̶̶r̶̶e̶ ̶l̶̶a̶̶t̶̶e̶ ̶s̶̶e̶̶t̶̶t̶̶i̶̶n̶̶g̶ ̶o̶̶f̶̶f̶̶.̶</div>
          <div><b>f</b>&ensp; there was snow on the line.</div>
   
        </div>
   
   </div>

      <div style='margin-top:25px;line-height:2'>
        <div><b>1</b>&ensp; <span style='color:rgb(69,112,138)'>We wouldn’t have missed the ferry if we hadn't been late setting off.</span></div>

        <div style='display:flex'><b>2</b>&ensp; <textarea id='5' rows='1'></textarea></div>

        <div style='display:flex'><b>3</b>&ensp; <textarea id='6' rows='1'></textarea></div>

        <div style='display:flex'><b>4</b>&ensp; <textarea id='7' rows='1'></textarea></div>

        <div style='display:flex'><b>5</b>&ensp; <textarea id='8' rows='1'></textarea></div>

        <div style='display:flex'><b>6</b>&ensp; <textarea id='9' rows='1'></textarea></div>
   
      </div>
		
	
			
								`,
        answer: [
          `c`,
          `a`,
          `f`,
          `d`,
          `b`,
          `We wouldn't have got lost if you hadn't forgotten the satnav.`,
          `I could have got on the plane if I had had my passport with me.`,
          `The train wouldn't have been late if there hadn't been snow on the line.`,
          `The car wouldn't have crashed if the driver had seen the red light.`,
          `we wouldn't have taken the cable car if we hadn't wanted to walk up the mountain.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P65-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page65/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 6,
    // inputSize: 150,
    textareaStyle: { width: 800, padding: 0 },
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
					
        Write questions using the prompts.
											`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					
				<div style='line-height:2.5'>

        <div style='display:flex'><b>1.</b>&ensp; <span>where / you / go / on holiday / last summer / if / you / have / the choice?</span> </div>
        <textarea id='0' rows='2'>Where would you have gone</textarea>

        <div style='display:flex'><b>2.</b>&ensp; <span>what / you / do / if / you / feel ill / this morning?</span> </div>
        <textarea id='1' rows='2'></textarea>
        <div style='display:flex'><b>3.</b>&ensp; <span>what / you / buy / last weekend / if / you / have / lots of money?</span> </div>
        <textarea id='2' rows='2'></textarea>
        <div style='display:flex'><b>4.</b>&ensp; <span>what film / you / see / if / go / to the cinema / last night?</span> </div>
        <textarea id='3' rows='2'></textarea>
        <div style='display:flex'><b>5.</b>&ensp; <span>what / you / eat / yesterday evening / if / you / have / the choice??</span> </div>
				<textarea id='4' rows='2'></textarea>
        </div>
						
											`,
        answer: [
          `Where would you have gone on holiday last summer if you had had the choice?`,
          `What would you have done if you had felt ill this morning?`,
          `What would you have bought last weekend if you had had lots of money?`,
          `What film would you have seen if you had gone to the cinema last night?`,
          `What would you have eaten yesterday evening if you had had the choice?`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P65-E4",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page65/key/E4answerKey.png',
    component: T6v2,
    maxLength: 16,
    textareaStyle: { width: 800, padding: 0 },
    inputSize: 250,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "22px",
      fontWeight: "bold",
      borderBottom: "dotted",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
					
        Write answers to the questions in exercise 3.

											`,
        color: "black",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="line-height:2.5">
        <div style='display:flex'><b>1.</b>&ensp; <textarea id='0' rows='2'></textarea></div>

        <div style='display:flex'><b>2.</b>&ensp; <textarea id='1' rows='2'></textarea></div>

        <div style='display:flex'><b>3.</b>&ensp; <textarea id='2' rows='2'></textarea></div>

        <div style='display:flex'><b>4.</b>&ensp; <textarea id='3' rows='2'></textarea></div>

        <div style='display:flex'><b>5.</b>&ensp; <textarea id='4' rows='2'></textarea></div>
    
    </div>				
											`,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P65-E5",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page65/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 900,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: "22px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
					
        Combine two sentences using to.
											`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:2'>
          <div>
          <b>1.</b>&ensp; Jimmy studied really hard this term. He wanted to get better marks <br>
          #
          </div>      
        
          <div>
          <b>2.</b>&ensp; Most young people leave their hometown. They want to seek for opportunities in big cities. <br>
          #
          </div>

          <div>
          <b>3.</b>&ensp; You must take your raincoat this season. You can avoid getting a cold. <br>
          #
          </div>
        
          <div>
          <b>4.</b>&ensp; I try all my best to study Japanese. I hope I can find a better job. <br>
          #
          </div>

          <div>
          <b>5.</b>&ensp; Mrs Jenny turned off the radio. She wanted to take a nap.<br>
          #
          </div>
        </div>
								`,
        answer: [
          `Jimmy studied really hard this term to get better marks`,
          `Most young people leave their hometown to seek for opportunities in big cities.`,
          `You must take your raincoat this season to avoid getting a cold.`,
          `I try all my best to study Japanese to find a better job.`,
          `Mrs Jenny turned off the radio to take a nap.`,
        ],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P65-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        Follow the steps in the Writing Guide.

        `,
        color: "black",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
        <div style="margin-left: 100px"><img src="img/FriendsPlus/Page65/14.jpg" /></div>
        

        `,
        answer: [],
      },
    ],
  },
};

export default json;
