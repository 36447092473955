import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 3",
    id: "FGF11-U3-P36-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page55/Key/E1answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `Read the task and the model essay. Does the writer agree or disagree with the statement in the task?

              `,
        color: "#000000",
      },
    ],
    // textareaStyle: { width: 500 },
    // maxLength:1,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: {
          width: 149,
          fontSize: 23,
          borderBottom: "none",
          textAlign: "center",
        },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {},
        selectWordStyle: {
          // padding: 5,
          color: "black",
          fontSize: 24,
        },
        limitSelect: 1,
        listWords: [`✓  ✓ `],
        answers: ["0-0"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;font-size:19px'>
       <img src='img/FriendsPlus/Page55/E1/7.jpg' style='height:20cm;margin-top:-20px ' />
       
        <div style='position:absolute;top:5px;left:650px; display:flex;flex-direction:column  '>
            <b>Agrees</b> <br/>
            <b>Disagrees</b>
            
          </div>
        <div style='position:absolute;top:5px;left:800px; display:flex; flex-direction: column;line-height:50px  '>
              <div style='width:40px;height:40px;border: 2px solid black; border-radius:10px;padding:5px 20xp'></div>
              <div style='width:40px;height:40px; margin:10px 0px; border: 2px solid black; border-radius:10px;padding:5px 20xp'></div>

             
          </div>
        <div style='position:absolute;top:-1px;left:813px;color: white; display:flex; flex-direction: column;line-height:50px  '>
          <input id='0' type='Circle'>
          
        </div>
       
      </div>
       
           
        
      `,
    },
  },
  2: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P55-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page55/key/E2answerKey.png",
    component: T6v2,
    isHiddenCheck: true,
    maxLength: 1,
    titleQuestion: [
      {
        num: "2",
        title: `
        Study the model essay and answer the questions. In which paragraph(1–4) does the writer …
					`,
        color: "black",
      },
    ],
    stylesTextInput: {
      // borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },
    questions: [
      {
        title: `
      <div style='display:flex;'>
        <img src="img/FriendsPlus/Page55/E2/1.jpg" width="52%" />
        <div style='line-height:2.7; margin-left: 10px'>
          <div><b>1.</b>
          <span style='margin-left:10px'>describe the arguments for?  #</span>
          <div style='margin-left:30px'>How many does she / he describe?  #</div>
          </div>

          <div><b>2.</b>
          <span style='margin-left:10px'>describe the arguments against?  #</span>
          <div style='margin-left:30px'>How many does she / he describe?  #</div>
          </div>

          <div><b>3.</b>
          <span style='margin-left:10px'>give his / her opinion?  #</span>
          </div>



         
        </div>
      </div>
    
		
							`,
        answer: [`2`, `3`, `3`, `2`, `4`],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P55-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page55/key/E3answerKey.png",
    component: Circle_Write,
    maxLength: 10,
    inputSize: 130,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
      borderStyle: "dotted",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Read the <span style='color:rgb(44,135,194)'>Writing Strategy</span>. Find and underline a rhetorical question in the model essay. Choose the expected answer
							`,
        color: "black",
      },
    ],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: {
          width: 149,
          fontSize: 23,
          borderBottom: "none",
          textAlign: "center",
        },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {},
        selectWordStyle: {
          // padding: 5,
          color: "black",
        },
        limitSelect: 1,
        listWords: [
          ` Nowadays,_the_number_of_students_who_do_a_second_degree_is_increasing. In_order_to_decide_if_this_is_a_good_thing, we_must_examine_the_advantages_and_disadvantages_of_taking_a_postgraduate_course. <br>
          <div> It_is_certainly_true_that_there_is_fierce_competition_for_employment,_and_candidates_need_something_extra_in_order_to_get_a_good_job. We_should_also_remember_that_people_with_a_second_qualification_can_expect_to_earn_more_when_they_start_work.
 Moreover,_postgraduate_courses_offer_the_chance_to_focus_on_a_topic_which_you_find_particularly_interesting. 
 What_could_be_better_than_spending_an_extra_two_or_three_years_studying_something_you_find_fascinating?</div><br>
<div> However,_there_are_disadvantages_too. Firstly,_it_is_an_expensive_option. You_often_need_to_pay_for_your_place_at_university_and_also_support_yourself_financially_during_the_course. What_is_more,_many_young_people_are_understandably_impatient_to_leave_education_by_the_time_they_finish_their_first_degree.</div><br>
On_balance,_I_believe_it_is_a_good_idea_to_continue_your_studies_beyond_a_first_degree,_if_possible. Although_it_may_be_expensive,_an_extra_qualification_allows_you_to_find_a_better_job_and_earn_more_money`,
          `✓  ✓ ✓`,
        ],
        answers: ["0-44", "1-6"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;font-size:19px'>
       <img src='img/FriendsPlus/Page55/E3/2.jpg' style='height:20cm; ' />
        <img src='img/FriendsPlus/Page55/E3/1.jpg' style='margin-top:-430px' />
        <div style='position:absolute;top:20px;left:85px;width:13cm'>
          <input id='0' type='Underline'>
          
        </div>
        <div style='position:absolute;top:296px;left:700px; display:flex; flex-direction: column;line-height:50px  '>
              <div><b>a</b>&ensp; everything</div>

              <div><b>b</b>&ensp; most things</div>

              <div><b>c</b>&ensp; nothing</div>
          </div>
        <div style='position:absolute;top:301px;left:835px; display:flex; flex-direction: column;line-height:50px  '>
              <div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
              <div style='width:40px;height:40px; margin:10px 0px; border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>
              <div style='width:40px;height:40px;border: 2px solid black; border-radius:15px;padding:5px 20xp'></div>

             
          </div>
        <div style='position:absolute;top:296px;left:850px;color: white; display:flex; flex-direction: column;line-height:50px  '>
          <input id='1' type='Circle'>
          
        </div>
       
      </div>
       
           
        
      `,
    },
  },
  4: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P55-E4",
    audio: "",
    video: "",
    // exerciseKey: 'img/FriendsPlus/Page55/key/E4answerKey.png',
    component: T6v2,
    textareaStyle: { width: "950px", padding: 0 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title: `
        Read the task above. Then plan your essay following the paragraph plan below.<br> Use the questions to help you.
					`,
        color: "Black",
      },
    ],
    questions: [
      {
        title: `
         <div style='line-height:2'>
            <div>
            <b>Paragraph 1: Rephrase the statement in the task.</b>
            <textarea id='0' rows='4'></textarea>
            </div>

            <div>
            <b>Paragraph 2: What are the arguments for doing a degree abroad? Think of two or three.</b>
            <textarea id='1' rows='4'></textarea>
            </div>


            <div>
            <b>Paragraph 3:What are the arguments against doing a degree abroad? Think of two or three.</b>
            <textarea id='2' rows='4'></textarea>
            </div>


            <div>
            <b>Paragraph 4: Give your opinion</b>
            <textarea id='3' rows='4'></textarea>
            </div>
        </div>
										`,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "FGF11-WB-demo-2023-U6-P55-E5",
    audio: "",

    video: "",
    // recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page55/key/E5answerKey.png',
    component: T6v2,
    // maxLength: 5,
    inputSize: 650,
    textareaStyle: { width: "800px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      //   textAlign: 'center',
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        Write your essay using your plan from exercise 4.
										`,
        color: "black",
      },
    ],
    hideBtnFooter: true,
    isHiddenCheck: true,
    selectStyle: {
      border: "solid 1px black",
      marginLeft: 10,
      height: 34,
      width: 37,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["✓", " "],
    questions: [
      {
        title: `
    <div style='line-height:2'>

      <div style='margin-top:10px'>
      <div><b><i><u>ANSWER:</u></i></b></div>
      <textarea id='3' rows='3'></textarea>
      </div>
    </div>
		
	
			
								`,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "FGF11-U6-P55-E6",
    audio: "",
    video: "",
    component: DesignUnderLine,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 500,
    textAlign: "left",
    // exerciseKey: 'img/FriendsPlus/Page14/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: "",
        title: `
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 23,
      height: 300,
    },
    totalInput: 3,
    // isAllowSubmit: false,
    questionImage: [
      [{ url: "img/FriendsPlus/Page55/E6/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page55/E6/2.jpg" },
        { url: "img/FriendsPlus/Page55/E6/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page55/E6/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page55/E6/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page55/E6/6.jpg" },
        { url: "img/FriendsPlus/Page55/E6/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page55/E6/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page55/E6/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page55/E6/10.jpg" },
        { url: "img/FriendsPlus/Page55/E6/11.jpg", input: 1 },
        { url: "img/FriendsPlus/Page55/E6/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page55/E6/13.jpg" }],
    ],
  },
};

export default json;
