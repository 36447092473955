import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P66-E1",
    audio: "",
    video: "",
    // recorder: true,
    maxLength: 11,
    exerciseKey: "img/FriendsPlus/Page66/key/E1answerKey.png",
    component: T6v2,
    inputSize: 180,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "25px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
						
        Complete the collocations with the verbs below.
												`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    checkUppercase: true,
    questions: [
      {
        title: `
        <div>
         <span style='color:rgb(148,37,148);font-weight:bold;line-height:2;'>board&ensp;&ensp;&ensp; 
         book&ensp;&ensp;&ensp; check in&ensp;&ensp;&ensp;   
         check into&ensp;&ensp;&ensp; hail&ensp;&ensp;&ensp; miss&ensp;&ensp;&ensp; reach</span>
       </div>
      <div style='line-height:2.5'>
          
      <div><b>1.</b>&ensp; # your bags / your luggage</div>

      <div><b>2.</b>&ensp; # a room / a flight / a holiday</div>

      <div><b>3.</b>&ensp; # your destination</div>

      <div><b>4.</b>&ensp; # a hote</div>

      <div><b>5.</b>&ensp; #  a taxi</div>

      <div><b>6.</b>&ensp; # a train / a ship / a flight</div>

      <div><b>7.</b>&ensp; # a car</div>

      <div><b>8.</b>&ensp; # your flight / your train</div>
          
      </div>
												`,
        answer: [
          `check in`,
          `book`,
          `reach`,
          `check into`,
          `hail`,
          `board`,
          `hire`,
          `miss`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P66-E2",
    audio: "Audios/2.06 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page66/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 200,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "23px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
					
        <headphone name='2.06' src='Audios/2.06 tieu de.mp3'></headphone>&ensp; Read <span style='color:rgb(198,27,80)'>Listening Strategy 1</span>. Then listen to three short dialogues and <br> answer the questions. 
											`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <img style='margin-left:100px; width: 15cm' src="img/FriendsPlus/Page66/E2/1.jpg" />
        <div style='margin-top:20px;line-height:2.5'>
          <div><b>1.</b>&ensp; In dialogue 1, how are they planning to travel to their hotel?</div>
          #
          <div><b>2.</b>&ensp; In dialogue 2, what is the Aurora?</div>
          #
          <div><b>3.</b>&ensp; In dialogue 3, what does the man think he’s lost?</div>
          #
        </div>
				
											`,
        answer: [`taxi`, `hotel`, `luggage / bag | bag | luggage`],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P66-E3",
    audio: "Audios/2.06 audio.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page66/key/E3answerKey.png",
    component: T6v2,
    maxLength: 15,
    inputSize: 240,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "25px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
					
        <headphone name='2.06' src='Audios/2.06 tieu de.mp3'></headphone>&ensp;Listen again. Which verbs from exercise 1 helped you answer <br>the questions in exercise 2?

											`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='line-height:2.5'>
          <b>Dialogue 1: </b># <br>
          <b>Dialogue 2: </b># <br>
          <b>Dialogue 3: </b># <br>
          </div>
											`,
        answer: [`hail`, `check into`, `check in`],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P66-E4",

    video: "",
    exerciseKey: "img/FriendsPlus/Page66/key/E4answerKey.png",
    component: T6v2,
    maxLength: 30,
    inputSize: 200,
    isHiddenCheck: true,
    // textAlign:'center',
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Read&ensp;<span style='color:rgb(198,27,80)'>Listening Strategy 2</span> Then complete the sentences with 
        formal words from the Strategy that mean the same as the 
        words in brackets.


					`,
        color: "black",
      },
    ],
    questions: [
      {
        title: `
        <img style='margin-left:100px; width: 16cm' src="img/FriendsPlus/Page66/E4/1.jpg" />
    <div style='line-height:50px; font-size: 22px'>
        <div style='display:flex'><b>1</b>&ensp;&ensp; <span>Will all passengers for Milan please # (go to) gate 7b, which is # (next to) gate 9.</span></div>
        
        <div style='display:flex'><b>2</b>&ensp;&ensp; <span>A wide range of # (food and drink) are available to # (buy) in the departure lounge.</span></div>

        <div style='display:flex'><b>3</b>&ensp;&ensp; <span>Passengers are advised that some flights will # (leave) later than scheduled. This is # (because of) the weather conditions.</span></div>  

        <div style='display:flex'><b>4</b>&ensp;&ensp; <span>This train # (ends) at Manchester. Please note that hot and cold # (drinks).</span></div>

        
    </div>
		
	
			
								`,
        answer: [
          `proceed to`,
          `adjacent to`,
          `refreshments`,
          `purchase`,
          `depart`,
          `due to`,
          `terminates`,
          `beverages`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P66-E5",
    audio: "Audios/2.07 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page66/key/E5answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 150,
    textareaStyle: { width: 700, padding: 0 },
    stylesTextInput: {
      // borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
						
        <headphone name='2.07' src='Audios/2.07 tieu de.mp3'></headphone>&ensp; Listen to three extracts. Which two are formal? 
        Which formal terms from<br>terms from <span style='color:rgb(198,27,80)'>Listening Strategy 2</span> do they include? 
												`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <img style='margin-left:100px' src="img/FriendsPlus/Page66/E4/1.jpg" />
      <div style='margin-top:20px'>
      <div style='line-height:2.3'>
      Extract # is formal. <br>
      It includes these formal terms:
      <textarea id='2' rows='1'></textarea>
      </div>

      <div style='line-height:2.3'>
      Extract # is formal. <br>
      It includes these formal terms:
      <textarea id='3' rows='1'></textarea>
      </div>


          
      </div>
												`,
        answer: [
          `2`,
          `3`,
          `(formal) refreshments, to purchase, beverages`,
          `(formal) prior to, due to`,
        ],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "FGF11-WB-demo-2023-U8-P66-E5",
    audio: "Audios/2.07 audio.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page66/key/E6answerKey.png",
    component: Circle_Write,
    // maxLength: 8,
    inputSize: 150,
    textareaStyle: { width: 700 },
    stylesTextInput: {
      // borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "6",

        title: `
						
        <span style='margin-left:5px'><headphone name='2.07' src='Audios/2.07 tieu de.mp3'></headphone>&ensp;</span>Listen again and circle the correct answers.
												`,
        color: "black",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 200, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px 10px",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "5px 10px",

          border: "2px solid black",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
        ],
        answers: ["0-4", "1-0", "2-0"],
        initialValue: [],
      },
      Layout: `
      <img style='margin-left:200px' src="img/FriendsPlus/Page66/E6/1.jpg" />
      <div style='display:flex; line-height:2'>
        <div style ='flex:1;width:100%; margin-top:20px'>
           <div style='display:flex;'>
           <b>1</b>&ensp; What is the speaker’s main purpose in extract 1?
           </div>
            <div style="display:flex;margin-left:40px">
		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px;'>
              To make sure everyone has a map. <br>
              To make sure nobody is late back to the coach. <br>
              To make sure everyone has time to buy souvenirs.<br>
            
            </div>
            </div>

             <div style='display:flex;'>
                <b>2</b>&ensp; Where is the announcement in extract 2 being made?
            </div>
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      On a train.<br>
              At an underground station.<br>
              In an airport terminal.<br>
            
		          </div>
            </div>

            <div style='display:flex;'>
            <b>3</b>&ensp; At what point in the flight is the announcement in extract 3 made?
            </div>
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
              Just before take-off. <br>
              Shortly after take-off. <br>
              Shortly before landing at the destination.<br>
            
		          </div>
            </div>

      
           </div>

          
    </div>


        
      `,
    },
  },
};

export default json;
