import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P91-E1",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page91/Key/E1answerKey.png",
    component: T6v2,
    maxLength: 6,
    inputSize: 110,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
		Check the meaning of the words in the box. Do you know any other adjectives to describe colours?
									`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
						
			<div style="border: 3px solid rgb(220,44,55); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">complementary</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">harmonious</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">pastel</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">primary</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">secondary</p>
				<p style="font-size: 25px; margin-right: 40px; font-weight:bold">vibrant</p>
			</div>
	
			<div style="display: flex; flex-wrap: wrap; gap: 10px; margin-top:40px">
	
				<div>
					<span style="font-size: 25px;font-weight:bold">Possible adjectives:</span>
					<span style="font-size: 25px">#,</span>
					<span style="font-size: 25px">#,</span>
					<span style="font-size: 25px">#,</span>
					<span style="font-size: 25px">#</span>
				</div>
			</div>
									`,
        answer: [
          "light | dark | bright | dull",
          "light | dark | bright | dull",
          "light | dark | bright | dull",
          "light | dark | bright | dull",
        ],
      },
    ],
  },
  2: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P91-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page91/Key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 160,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
		Read the text. Write the names of colours 1–6 on the colour wheel. Which colours are
		primary and which are secondary?
									`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left: 200px;margin-top:30px"><img src="img/FriendsPlus/Page91/11.jpg" /></div>
		<h1 style="margin-top: 40px">Answers</h1>
			<div style="display: flex; flex-wrap: wrap; gap: 10px; margin-top:40px">
				<span style="font-size: 25px">1#</span>
				<span style="font-size: 25px">2#</span>
				<span style="font-size: 25px">3#</span>
				<span style="font-size: 25px">4#</span>
				<span style="font-size: 25px">5#</span>
				<span style="font-size: 25px">6#</span>
			</div>

			<div style="display: flex; flex-wrap: wrap; gap: 10px; margin-top:40px">
				<span style="font-size: 25px;font-weight:bold">primary colours:</span>
				<span style="font-size: 25px">#,</span>
				<span style="font-size: 25px">#,</span>
				<span style="font-size: 25px">#</span>
			</div>

			<div style="display: flex; flex-wrap: wrap; gap: 10px; margin-top:40px">
				<span style="font-size: 25px;font-weight:bold">secondary colours:</span>
				<span style="font-size: 25px">#,</span>
				<span style="font-size: 25px">#,</span>
				<span style="font-size: 25px">#</span>
			</div>
									`,
        answer: [
          "yellow",
          "orange",
          "red",
          "purple",
          "blue",
          "green",
          "yellow",
          "red",
          "blue",
          "orange",
          "purple",
          "green",
        ],
      },
    ],
  },
  3: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P91-E3",
    audio: "Audios/3.03.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page91/Key/E3answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 230,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        <headphone name="3.03"></headphone >
        Read and listen to the text again. Complete the sentences using the words <br/> in exercise 1
        
									`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style="margin-left: 200px;margin-top:30px"><img src="img/FriendsPlus/Page91/11.jpg" /></div>
      <div style="border: 3px solid rgb(220,44,55); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">complementary</p>
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">harmonious</p>
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">pastel</p>
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">primary</p>
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">secondary</p>
          <p style="font-size: 25px; margin-right: 40px; font-weight:bold">vibrant</p>
      </div>

      <p style="font-size: 25px;margin-top: 40px;">
        <span style="font-weight: bold;margin-right: 40px;">1</span>
        <span>Yellow and purple are # colours.</span>
      </p>

      <p style="font-size: 25px;">
        <span style="font-weight: bold;margin-right: 40px;">2</span>
        <span>Blue and red are # colours</span>
      </p>

      <p style="font-size: 25px;">
        <span style="font-weight: bold;margin-right: 40px;">3</span>
        <span>A # colour is bright and strong.</span>
      </p>

      <p style="font-size: 25px;">
        <span style="font-weight: bold;margin-right: 40px;">4</span>
        <span>Green and yellow are # colours.</span>
      </p>

      <p style="font-size: 25px;">
        <span style="font-weight: bold;margin-right: 40px;">5</span>
        <span>Orange and purple are # colours</span>
      </p>

      <p style="font-size: 25px;">
        <span style="font-weight: bold;margin-right: 40px;">6</span>
        <span>A # colour is pale and soft.</span>
      </p>



									`,
        answer: [
          "complementary",
          "primary",
          "vibrant",
          "harmonious",
          "secondary",
          "pastel",
        ],
      },
    ],
  },
  4: {
    unit: "CULTURE",
    id: "FP8-SB-demo-2023-CUL-P91-E4",
    audio: "",
    video: "",
    // recorder: true,
    component: UI,
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Work in groups. Look at Wassily
        Kandinsky’s painting Squares with Concentric
        Circles. Choose two squares in the painting
        and say how the colours in each square are
        related. Which square do you like best and
        why? `,
        color: "rgb(220,44,55)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page91/12.jpg",
        },
      ],
    ],
  },
};

export default json;
