import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P34-E1",
    audio: "Audios/1.17 audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page34/Key/E1answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
        <headphone name='1.17' src='Audios/1.17 tieu de.mp3'></headphone> Read <span style='color:rgb(192,33,84)'>Listening Strategy 1</span>.  Then listen to four people and circle the correct summaries. 
			  `,
        color: "#000000",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 22, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
          padding: "0px 4px",
        },
        selectWordStyle: {
          padding: "0px 4px",
          border: "2px solid black",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b`, //1
          `a <br> b`, //1
          `a <br> b`, //1
          `a <br> b`, //1
        ],
        answers: ["0-4", "1-4", "2-4", "3-0"],
        initialValue: [],
      },
      Layout: `
     



            <b>1</b>&ensp;
            For the past two weeks, speaker 1 has been ____  .  
            <div style="display: flex;margin-left:40px">

		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
              sleeping well <br> sleeping badly
                    </div>
                       </div>

            <b>2</b>&ensp;
            He's got a new neighbour, but speaker 2 hasn’t ____  . 
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      seen him <br> spoken to him 
		          </div>
            </div>

              <b>3</b>&ensp;
              Speaker 3’s husband is in the mountains and nobody can ____ .  
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
              find him <br> phone him 
		          </div>
            </div>
             <b>4</b>&ensp;
             Speaker 4 couldn’t take part in the race, although he really ____ to be fit for it. 
             <div style="display: flex;">
            <div style="display: flex;margin-left:40px">
            	<div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      tried <br> expected


		          </div>
            </div>
           </div>



        
      `,
    },
  },

  2: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P34-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page34/key/E2answerkey.png",
    component: T6v2,
    inputSize: 50,
    stylesTextInput: {
      borderBottom: "bold",
      marginRight: 10,
      borderWidth: 1,
      borderColor: "black",
      width: 35,
      height: 35,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Read <span style='color:rgb(192,33,84)'>Listening Strategy 2</span>.  Match the phrases (1–6) with similar meanings (a–f). Then circle the formal phrase in each 
				`,
        color: "#000000",
      },
    ],
    maxLength: 1,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:2'>
        <div>
        <b>1</b>&ensp;increase sharply <input/> <br/>
        <b>2</b>&ensp;need help  <input/> <br/>
        <b>3</b>&ensp;find a job  <input/> <br/>
        <b>4</b>&ensp;currently  <input/> <br/>
        <b>5</b>&ensp;very important  <input/> <br/>
        <b>6</b>&ensp;make a proposal <input/> <br/>

        </div>
        <div style='margin-left:60px'>
        <b>a</b>&ensp; gain employment <br/>
        <b>b</b>&ensp; at the moment  <br/>
        <b>c</b>&ensp; a high priority <br/>
        <b>d</b>&ensp; go up a lot <br/>
        <b>e</b>&ensp; suggest something  <br/>
        <b>f</b>&ensp; require assistance


        </div>
        </div>

   
    
      `,
        answer: [`d`, `f`, `a`, `b`, `c`, `e`],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P34-E3",
    component: T6v2,
    audio: "Audios/1.18 audio.mp3",
    exerciseKey: "img/FriendsPlus/Page34/Key/E3answerKey.png",
    // hideBtnFooter: true,
    inputSize: 350,
    stylesTextInput: {
      // background: "transparent",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        <headphone name='1.18' src='Audios/1.18 tieu de.mp3'></headphone> Listen to three recordings. Which two are formal? Which formal phrases from exercise 2 did they include? 
          
				`,
        color: "#000000",
      },
    ],
    maxLength: 29,

    isHiddenCheck: true,
    questions: [
      {
        title: `
        <img style="width:17cm; height:7cm" src='img/FriendsPlus/Page34/E3/1.png' />
       <div style='font-size: 22px; line-height: 40px'>
       Recording number  __is formal and includes the formal : <br>
       phrases: <br>
       <b>1</b>&ensp;<input/><br>
       <b>2</b>&ensp;<input/><br>
       <b>3</b>&ensp;<input/><br>
       </div>
       <div>
       Recording number  __is formal and includes the formal : <br>
       phrases: <br>
       <b>4</b>&ensp;<input/><br>
       <b>5</b>&ensp;<input/><br>
       <b>6</b>&ensp;<input/><br>
       </div>


        `,
        answer: [
          `require assistance`,
          `currently`,
          `a high priority `,
          `increase sharply`,
          `gain employment`,
          `make a proposal`,
        ],
      },
    ],
  },

  4: {
    unit: "Unit 4",
    id: "FGF11-WB-demo-2023-U4-P34-E4",
    audio: "Audios/1.18 audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page34/Key/E4answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "4",
        title: `
        <headphone name='1.18' src='Audios/1.18 tieu de.mp3'></headphone> Listen again and circle the correct answers.  
			  `,
        color: "#000000",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 22, textAlign: "center" },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "3px solid ",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
        ],
        answers: ["0-0", "1-0", "2-8"],
        initialValue: [],
      },
      Layout: `
     



            <b>1</b>&ensp;
            In recording 1, we hear a man 
              <div style="display: flex;margin-left:40px">
		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
              asking for a form to complete.
               <br/> asking for help in completing a form.
                <br/> returning a form that he has completed. 
                    </div>
                       </div>

            <b>2</b>&ensp;
            In recording 2, what is the main intention of the speaker?             <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
              To persuade people to make donations to a charity. <br/> To advise people against giving money to homeless people on the street. <br/> To suggest ways of helping homeless people which do not cost money. 
		          </div>
            </div>

              <b>3</b>&ensp;
              In recording 3, when the speaker took part in the Big Sleep Out, she             <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
              felt much colder than she had expected. <br/> did not feel as cold as her friends. <br/> did not feel as cold as she had expected.
		          </div>
            </div>
             



        
      `,
    },
  },
};

export default json;
