import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P63-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page63/key/E1answerKey.png",
    component: Circle_Write,
    // maxLength: 6,
    inputSize: 250,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: "center",
      fontSize: "23px",
      // fontWeight: "bold",
      // borderBottom: 'dotted',
    },
    titleQuestion: [
      {
        num: "1",

        title: `
        Read the task above. Then read <span style='color:rgb(66,147,195)'>Writing Strategy 1</span> and the review below. <br>Answer the questions.
	      `,
        color: "black",
      },
    ],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    selectStyle: {
      border: "solid 1px black",
      marginLeft: 10,
      height: 34,
      width: 37,
      textAlign: "center",
    },
    selectOptionRandom: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: {
          width: 149,
          fontSize: 23,
          borderBottom: "none",
          textAlign: "center",
        },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {},
        selectWordStyle: {
          // padding: 5,
          color: "black",
          fontSize: 24,
        },
        limitSelect: 1,
        listWords: [
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ <br/>`,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ <br/>`,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ <br/> <br/>`,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
        ],
        answers: ["0-4", "1-0", "2-4", "3-0"],

        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
      <div style='width:18cm'>
      <img src='img/FriendsPlus/Page63/E1/1.jpg'/>
      <div style='position:relative;'>
      <div style='display:flex'>
      <b>a</b>
      <div style='margin-left:10px;'>
      Where does each paragraph end? <br>
     <div style='line-height:2'>
     <span style='font-weight:100px;'>Paragraph 1: A <span style='margin-left:50px'>or B </span> <br></span>
     <span style='font-weight:100px;'>Paragraph 2: C <span style='margin-left:50px'>or D </span> <br></span>
     <span style='font-weight:100px;'>Paragraph 3: E <span style='margin-left:50px'>or F </span> <br></span>
     </div>
      </div>
      </div>
      <div style='display:flex;margin-top:15px'>
      <b>b</b>
      <div style='margin-left:10px'>
      Has the writer followed the second piece of advice in the Strategy? 
      <span style='margin-left:20px'>Yes <span style='margin-left:55px'>No</span></span>
      
      </div>
      </div>
         
					<div style='position:absolute;top:34px;left:196px;display:flex'>
            <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
            <div style='margin-left:62px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div>
				  </div>
          <div style='position:absolute;top:84px;left:196px;display:flex'>
           <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
           <div style='margin-left:62px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div>
         </div>
         <div style='position:absolute;top:134px;left:196px;display:flex'>
           <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
           <div style='margin-left:62px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div>
         </div>
         <div style='position:absolute;top:230px;left:196px;display:flex'>
           <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
           <div style='margin-left:62px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div>
         </div>
          <div style='position:absolute;color: white;top:34px;left:209px;line-height:2'>
          <input id='0' type='Circle'> 
          <input id='1' type='Circle'>
          <input id='2' type='Circle'>
          <input id='3' type='Circle'>


				</div>
        
					
			</div>
      </div>
      
      <div>
      <img src='img/FriendsPlus/Page63/E1/2.jpg'/>
      </div>
      </div>

			

      

			


        
         `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P63-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page63/key/E2answerKey.png",
    component: Circle_Write,
    // maxLength: 6,
    inputSize: 250,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: "center",
      fontSize: "23px",
      // fontWeight: "bold",
      // borderBottom: 'dotted',
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        Read <span style='color:rgb(66,147,195)'>Writing Strategy 2</span> and answer questions 1–3 below.
	      `,
        color: "black",
      },
    ],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    selectStyle: {
      border: "solid 1px black",
      marginLeft: 10,
      height: 34,
      width: 37,
      textAlign: "center",
    },
    selectOptionRandom: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 200,
        inputStyle: {
          width: 900,
          fontSize: 23,
          borderBottom: "none",
          textAlign: "left",
        },
        answers: [
          `If you're looking for a film that has romance and drama, this one is for you!`,
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {},
        selectWordStyle: {
          // padding: 5,
          color: "black",
          fontSize: 24,
        },
        limitSelect: 1,
        listWords: [
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; ✓ <br/> <br/>`,
          `✓ &ensp;&ensp;&ensp;&ensp;&ensp; ✓ `,
        ],
        answers: ["0-4", "1-0"],

        initialValue: [],
      },
      Layout: `
      <div style='position: relative'>
      <img style='margin-left:150px' src="img/FriendsPlus/Page63/E2/1.jpg"/> 
      <div style='position:relative; display: inline-block;'>
      <div style='display:flex;'>
      <div>
      <b>1</b>
      </div>
      <div style='margin-left:20px'>
      Underline the sentence that attracts the reader’s attention 
in the first paragraph. <br>
      <input id='0'/>
      </div>
      </div>

      <div style='display:flex'>
      <div>
      <b>2</b>
      </div>
      <div style='margin-left:20px'>
      Is the overall style formal <span style='margin-left:50px'>or informal</span> <span style='margin-left:50px'>?</span> <br>
      </div>
      </div>

      <div style='display:flex'>
      <div>
      <b>3</b>
      </div>
      <div style='margin-left:20px'>
      Has the writer addressed both elements of the task? <br>
      Yes <span style='margin-left:50px'>No</span> <br>
      </div>
      </div>
         <div style='position:absolute;top:74px;left:322px;display:flex'>
           <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
           <div style='margin-left:146px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div>
         </div>
         <div style='position:absolute;top:151px;left:80px;display:flex'>
           <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
           <div style='margin-left:50px;width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div>
         </div>
          <div style='position:absolute;color: white;top:70px;left:334px;line-height:2'>
          <input id='0' type='Circle'> 


				</div>
        <div style='margin-left:12px;position:absolute;color: white;top:146px;left:80px;line-height:2'>
          <input id='1' type='Circle'>


				</div>
        
			</div>
      </div>
         `,
    },
  },
  3: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P63-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page63/key/E3answerKey.png",
    component: Circle_Write,
    // maxLength: 6,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: "center",
      fontSize: "23px",
      // fontWeight: "bold",
      // borderBottom: 'dotted',
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Tick the phrases for describing stories that the writer uses in the article. <br>Which phrase cannot be used to describe a film?
	      `,
        color: "black",
      },
    ],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    selectStyle: {
      // border: "solid 1px black",
      // marginLeft: 10,
      // height: 34,
      // width: 37,
      // textAlign: "center",
    },

    selectOptionRandom: true,
    question: {
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          width: 40,
          fontSize: 23,
          borderBottom: "none",
          textAlign: "center",
          maxLength: 1,
          fontWeight: "bold",
        },
        answers: [`5`],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {},
        selectWordStyle: {
          // padding: 5,
          color: "black",
          fontSize: 24,
        },
        limitSelect: 5,
        listWords: [`✓ ✓ ✓ ✓ ✓ `],
        answers: ["0-0", "0-4", "0-6"],

        initialValue: [],
      },
      Layout: `
      <div style='position:relative; width: 980px;'>
      <div style='line-height:3.15;display:flex; position:relative'>
      <div style='margin-left:-10px'><img  src="img/FriendsPlus/Page63/E1/2.jpg" style='width: 15cm'/></div>
       <div>
       <div><b>1.</b>&ensp; It's set in (place and / or time) </div>
 
       <div><b>2.</b>&ensp; There are lots of twists and turns.</div>
 
       <div><b>3.</b>&ensp; It tells the story of (character). </div>
 
       <div><b>4.</b>&ensp; I would definitely recommend it. </div>
 
       <div><b>5.</b>&ensp; It's a real page-turner </div>
 
       <div>
       Phrase <input id='0'/> can't be used for films.</div> 
       </div>
     
      </div>
         <div style='position:absolute;top:10px;left:1000px;'>
           <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
           <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
           <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
           <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>
           <div style='width:40px;height:40px;border: 1px solid black; border-radius:5px;padding:5px 20xp'></div> <br/>

         </div>
      
          <div style='position:absolute;color: white;top:-5px;left:1010px;line-height:3.17'>
          <input id='0' type='Circle'> 


				</div>
       
       
        
			</div>
         `,
    },
  },
  4: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P63-E4",
    // audio: "Audios/2.16.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page63/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 200,
    textareaStyle: { width: 700, padding: 0 },
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: "25px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
        You are going to do the task in exercise 1. Make notes about a film of your choice..
										`,
        color: "black",
      },
    ],

    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `  
     <div style='position: relative;line-height:2.3;'>
        <div>
       <textarea id='0' rows='3'>What I liked:</textarea>
       <textarea id='1' rows='3'>What I didn't like:</textarea>
       <textarea id='2' rows='3'>Overall opinion:</textarea>
        </div>
     </div>
     
     `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "FGF11-WB-demo-2023-U7-P63-E5",
    audio: "",

    video: "",
    // recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page55/key/E5answerKey.png',
    component: T6v2,
    // maxLength: 5,
    inputSize: 650,
    textareaStyle: { width: "800px", height: 300 },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      //   textAlign: 'center',
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        Write your review. Follow the structure of the model review 
in exercise 1, and use your notes from exercise 4. Use some 
phrases from exercise 3
										`,
        color: "black",
      },
    ],
    hideBtnFooter: true,
    isHiddenCheck: true,
    selectStyle: {
      border: "solid 1px black",
      marginLeft: 10,
      height: 34,
      width: 37,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["✓", " "],
    questions: [
      {
        title: `
      </div>
      <div style='margin-top:10px'>
      <div><b><i><u>ANSWER:</u></i></b></div>
      <textarea id='5' rows='3'></textarea>
      </div>
    </div>
		
	
			
								`,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "FGF11-U7-P63-E6",
    audio: "",
    video: "",
    component: DesignUnderLine,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 500,
    textAlign: "left",
    // exerciseKey: 'img/FriendsPlus/Page14/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: "",
        title: `
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 23,
      height: 300,
    },
    totalInput: 4,
    // isAllowSubmit: false,
    questionImage: [
      [{ url: "img/FriendsPlus/Page63/E6/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page63/E6/2.jpg" },
        { url: "img/FriendsPlus/Page63/E6/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page63/E6/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page63/E6/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page63/E6/6.jpg" },
        { url: "img/FriendsPlus/Page63/E6/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page63/E6/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page63/E6/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page63/E6/10.jpg" },
        { url: "img/FriendsPlus/Page63/E6/11.jpg", input: 1 },
        { url: "img/FriendsPlus/Page63/E6/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page63/E6/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page63/E6/14.jpg" },
        { url: "img/FriendsPlus/Page63/E6/15.jpg", input: 1 },
        { url: "img/FriendsPlus/Page63/E6/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page63/E6/17.jpg" }],
      [
        { url: "img/FriendsPlus/Page63/E6/18.jpg" },
        { url: "img/FriendsPlus/Page63/E6/19.jpg", input: 1 },
        { url: "img/FriendsPlus/Page63/E6/20.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page63/E6/21.jpg" }],
    ],
  },
};

export default json;
