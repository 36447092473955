import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P31-E1",
    // audio: "Audios/1.24.mp3",
    exerciseKey: "img/FriendsPlus/Page31/key/E1answerkey.png",
    component: T6v2,
    // hideBtnFooter: true,
    inputSize: 200,
    stylesTextInput: {
      borderWidth: 1,
      borderColor: "black",
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        Read the task and the essay. In which paragraph (A–D) does the writer … 
				`,
        // color: "rgb(46,180,106)",
        color: "rgb(0,0,0)",
      },
    ],
    isHiddenCheck: true,
    maxLength: 1,
    questions: [
      {
        title: `
       <div style='display:flex'>
       <div style='line-height:3'>
       <b>1</b>&ensp;give his opinion? <input/><br>
       <b>2</b>&ensp;introduce the topic?<input/><br>
       <b>3</b>&ensp;make his proposals?<input/><br>
       <b>4</b>&ensp;sum up the essay? <input/> <br>

       </div>
       <div style='margin-left:20px'>
       <div style='margin:auto;width:700px;border:none;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;padding:20px 20px;'>
       <span style='width:50px;height:20px;border:1px solid black;padding:5px 5px'><b>A</b></span> If we can believe recent reports in the press, many teenagers are overweight and unfit.
        Some people argue that teaching children at school about a healthy lifestyle would help to solve this problem. <br>
        <span style='width:50px;height:20px;border:1px solid black;padding:5px 5px'><b>B</b></span> In my view, it would be very sensible to teach students how to lead a healthy lifestyle.
          Health problems later in life often start because people get into bad habits during their teenage years. 
          It is sometimes said that this is the responsibility of the parents and not the school
          . But as I see it, everyone in society would benefit from this. <br>
          <span style='width:50px;height:20px;border:1px solid black;padding:5px 5px'><b>C</b></span> In order to tackle this problem, time should be set aside in the school timetable to teach children
            about the benefits of eating healthily and getting lots of exercise.
             Moreover, doctors could also play a part by coming into school and talking to students. 
             I suggest that one or two hours a week should be spent on this topic. <br>
             <span style='width:50px;height:20px;border:1px solid black;padding:5px 5px'><b>D</b></span> To sum up, I agree with those people who believe that healthy living should be taught in schools.
               It seems to me that the students themselves and society in general would benefit from this proposal.
       </div>
       </div>
       
       </div>
				`,
        answer: [`B`, `A`, `C`, `D`],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P31-E2",
    // audio: "Audios/1.24.mp3",
    exerciseKey: "img/FriendsPlus/Page31/key/E2answerkey.png",
    component: T6v2,
    // hideBtnFooter: true,
    inputSize: 350,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Complete the gaps with the underlined phrases from the essay. 
				`,
        // color: "rgb(46,180,106)",
        color: "rgb(0,0,0)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        <b>Introducing your opinions </b> <br>
        In my opinion, <sup>1</sup> <input/> <sup>2</sup> <input/> <sup>3</sup> <input/>
        </div>
        
        </div>

        <div style='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        <b>Introducing other people’s opinions </b> <br>
        It is a widely held view that Most people agree that <br>  <sup>4</sup> <input/> <sup>5</sup> <input/> 
        </div>
        
        </div>

        <div style='display:flex'>
        <b>3</b>
        <div style='margin-left:10px'>
        <b>Making an additional point  </b> <br>
        What is more, Not only that, but Furthermore, <br>  <sup>6</sup> <input/> 
        </div>
        
        </div>

        <div style='display:flex'>
        <b>4</b>
        <div style='margin-left:10px'>
        <b>Introducing proposals and solutions </b> <br>
        One solution might be to What I propose is that I would strongly recommend that It is vital that <br> <sup>7</sup> <input/> <sup>8</sup> <input/>
        </div>
        
        </div>

        <div style='display:flex'>
        <b>5</b>
        <div style='margin-left:10px'>
        <b>Introducing your opinions </b> <br>
        In conclusion, To conclude,  <sup>9</sup> <input/> 
        </div>
        
        </div>
				`,
        answer: [
          `In my view`,
          `As I see it`,
          `It seems to me that`,
          `Some people argue that`,
          `It is sometimes said that`,
          `Moreover`,
          `In order to tackle this problem`,
          `I suggest that`,
          `To sum up`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P31-E3",
    // audio: "Audios/1.24.mp3",
    // exerciseKey: 'img/FriendsPlus/Page31/key/E2answerkey.png',
    component: T6v2,
    // hideBtnFooter: true,
    inputSize: 350,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Make notes about the headings below. 
				`,
        // color: "rgb(46,180,106)",
        color: "rgb(0,0,0)",
      },
    ],
    hideBtnFooter: true,
    textareaStyle: { width: 800, padding: 5 },
    isHiddenCheck: true,
    questions: [
      {
        title: `
       <b>My view of the problem and its causes: </b> <br>
       <textarea id='0' rows='5'></textarea>			<br>
       <b>Solutions to the problem: </b> <br>
       <textarea id='1' rows='5'></textarea>				`,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "FGF11-WB-demo-2023-U3-P31-E4",
    // audio: "Audios/1.24.mp3",
    // exerciseKey: 'img/FriendsPlus/Page31/key/E2answerkey.png',
    component: T6v2,
    // hideBtnFooter: true,
    inputSize: 350,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Write your essay and use your notes from exercise 3. Include some phrases from exercise 2.

				`,
        // color: "rgb(46,180,106)",
        color: "rgb(0,0,0)",
      },
    ],
    hideBtnFooter: true,
    textareaStyle: { width: 1000, padding: 5 },
    isHiddenCheck: true,
    questions: [
      {
        title: `
     
       <textarea id='0' rows='10'></textarea>	
     
    			`,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "FGF11-U3-P31-E5",
    audio: "",
    video: "",
    component: DesignUnderLine,
    isHiddenCheck: true,
    hideBtnFooter: true,
    maxLength: 500,
    textAlign: "left",
    // exerciseKey: 'img/FriendsPlus/Page14/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: "",
        title: `
				`,
        color: "black",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 23,
      height: 300,
    },
    totalInput: 3,
    // isAllowSubmit: false,
    questionImage: [
      [{ url: "img/FriendsPlus/Page31/E5/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page31/E5/2.jpg" },
        { url: "img/FriendsPlus/Page31/E5/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page31/E5/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page31/E5/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page31/E5/6.jpg" },
        { url: "img/FriendsPlus/Page31/E5/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page31/E5/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page31/E5/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page31/E5/10.jpg" },
        { url: "img/FriendsPlus/Page31/E5/11.jpg", input: 1 },
        { url: "img/FriendsPlus/Page31/E5/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page31/E5/13.jpg" }],
    ],
  },
};

export default json;
