import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  12: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P89-E12",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page89/key/E12answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 1000,
    stylesTextInput: {
      //   background: "transparent",
      //   border: "none",
      fontSize: "22px",
      fontWeight: "bold",
      //   textAlign: "center",
    },
    titleQuestion: [
      {
        num: "12",
        title: `
		Write passive questions about the information in bold.
												`,
        color: "rgb(121,106,175)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div>
				<span style="font-weight: bold; margin-right: 10px">1</span>
				<span style="font-size: 25px">They showed this film <span style="font-weight: bold; margin-right: 10px">at the Lincoln Cinema</span>.</span>
				<p style="font-size: 25px">#</p>
			</div>
	
			<div style="margin-top: 10px">
				<span style="font-weight: bold; margin-right: 10px">2</span>
				<span style="font-size: 25px"> <span style="font-weight: bold; margin-right: 10px">A French company</span> made these film
				cameras.</span>
				<p style="font-size: 25px">#</p>
			</div>
	
			<div style="margin-top: 10px">
				<span style="font-weight: bold; margin-right: 10px">3</span>
				<span style="font-size: 25px">They teach film direction <span style="font-weight: bold; margin-right: 10px">at this school</span>.</span>
				<p style="font-size: 25px">#</p>
			</div>
	
			<div style="margin-top: 10px">
				<span style="font-weight: bold; margin-right: 10px">4</span>
				<span style="font-size: 25px"><span style="font-weight: bold; margin-right: 10px">Yes,</span> this film scares audiences</span>
				<p style="font-size: 25px">#</p>
			</div>

			<div style="margin-top: 10px">
				<span style="font-weight: bold; margin-right: 10px">5</span>
				<span style="font-size: 25px">She wrote this book <span style="font-weight: bold; margin-right: 10px">last year</span></span>
				<p style="font-size: 25px">#</p>
			</div>

			<div style="margin-top: 10px">
				<span style="font-weight: bold; margin-right: 10px">6</span>
				<span style="font-size: 25px">They sell about <span style="font-weight: bold; margin-right: 10px">10,000</span> copies a year</span>
				<p style="font-size: 25px">#</p>
			</div>
		
												`,
        answer: [
          "Where was this film shown?",
          "Who were these film cameras made by? / Which company were these film cameras made by?",
          "Where is film direction taught?",
          "Are audiences scared by this film?",
          "When was this book written?",
          "How many copies are sold a year?",
        ],
      },
    ],
  },
  13: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P89-E13",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page89/key/E13answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 130,
    stylesTextInput: {
      //   background: "transparent",
      //   border: "none",
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "13",
        title: `
		Complete the dialogue with the words. Then practise with your partner. There are two
		extra words.
												`,
        color: "rgb(121,106,175)",
      },
    ],
    hintBox: [
      {
        src: [
          "about",
          "fan",
          "into",
          "on",
          "prefer",
          "rather",
          "something",
          "stars",
        ],
        width: 500,
        borderColor: "rgb(121,106,175)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style='margin-left:250px'>
      <hintbox id='0' ></hintbox>
    </div>

		<div class="container">

          <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:10px">
            <div class="col-1">
              <span style="font-weight: bold">Blake</span>
            </div>
            <div class="col-10">
              <span >What do you fancy doing?
              </span>
            </div>
          </div>

          <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
            <div class="col-1">
              <span style="font-weight: bold">Cheryl</span>
            </div>
            <div class="col-10">
              <span >How <sup>1</sup> # downloading a
			  film?</span>
            </div>
          </div>

          <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
            <div class="col-1">
              <span style="font-weight: bold">Blake</span>
            </div>
            <div class="col-10">
              <span >All right. What about Love, Rosie?
			  </span>
            </div>
          </div>

          <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
            <div class="col-1">
              <span style="font-weight: bold">Cheryl</span>
            </div>
            <div class="col-10">
            <span>That sounds like a romantic comedy.
			I’m not really <sup>2</sup> # that kind
			of thing.</span>
            </div>
          </div>

          <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
            <div class="col-1">
              <span style="font-weight: bold">Blake</span>
            </div>
            <div class="col-10">
            <span >Well, you might <sup>3</sup> # Rogue
			One: A Star Wars Story.</span>
            </div>
          </div>

          <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
            <div class="col-1">
              <span style="font-weight: bold">Cheryl</span>
            </div>
            <div class="col-10">
            <span >It’s popular, but I’d prefer to
			see <sup>4</sup> # more believable.</span>
            </div>
          </div>

          <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
            <div class="col-1">
              <span style="font-weight: bold">Blake</span>
            </div>
            <div class="col-10">
            <span >I fancy Race. It’s a drama and it <sup>5</sup> # Stephan James.</span>
            </div>
          </div>

          <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
            <div class="col-1">
              <span style="font-weight: bold">Cheryl</span>
            </div>
            <div class="col-10">
            <span> Yes, I’d <sup>6</sup> # watch that, too.</span>
            </div>
          </div>

          <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
            <div class="col-1">
              <span style="font-weight: bold">Blake</span>
            </div>
            <div class="col-10">
            <span >Great!</span>
            </div>
          </div>

         </div>
		
												`,
        answer: ["about", "into", "prefer", "something", "stars", "rather"],
      },
    ],
  },
  14: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P89-E14",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page89/key/E14answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 160,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "14",
        title: `
		Read the book review and complete the text with the words.
												`,
        color: "rgb(121,106,175)",
      },
    ],
    hintBox: [
      {
        src: [
          "about",
          "because",
          "directed",
          "in",
          "liked",
          "mixture",
          "recommend",
          "talk",
          "were",
        ],
        width: 500,
        borderColor: "rgb(121,106,175)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
		
		<div style='margin:0px 0px 20px  140px '>
        <hintbox id='0'></hintbox>
    </div>

		<div style="margin-left:100px;position: relative;">
			<img src="img/FriendsPlus/Page89/8.jpg" width="600px"/>

			<div style="position: absolute; top: 233px; left: 110px" >
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute; top: 297px; left: 17px" >
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute; top: 361px; left: 17px" >
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute; top: 398px; left: 228px" >
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute; top: 432px; left: 311px" >
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute; top: 564px; left: 132px" >
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute; top: 661px; left: 389px" >
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute; top: 763px; left: 57px" >
				<p style="font-size: 25px">#</p>
			</div>

			<div style="position: absolute; top: 763px; left: 263px" >
				<p style="font-size: 25px">#</p>
			</div>


		</div>
		
												`,
        answer: [
          "talk",
          "directed",
          "were",
          "mixture",
          "about",
          "liked",
          "because",
          "in",
          "recommend",
        ],
      },
    ],
  },
};

export default json;
